import React, { useState, useEffect } from 'react'

import { FiPhone, FiMapPin, FiChevronDown } from 'react-icons/fi'
import { FaEnvelope } from 'react-icons/fa'
import flat1 from '../../assets/images/flag.jpg'
import flatfr from '../../assets/images/flag3.jpg'
import flates from '../../assets/images/flag4.jpg'
import flatde from '../../assets/images/flag5.jpg'

import flatpo from '../../assets/images/flagpo.jpg'
import flatit from '../../assets/images/flagit.jpg'
import flatne from '../../assets/images/flagne.jpg'
import flatfi from '../../assets/images/flagfi.jpg'



import sectiondata from "../../store/store";
import { Link } from "react-router-dom";

import { useTranslation } from "react-i18next";
//import { BehaviorSubject } from 'rxjs';

// recupperer le language dans le local stockage
const currentUserLanguage = JSON.parse(localStorage.getItem('languageValue'))

//console.log("currentUserLanguage",currentUserLanguage)

export default function HeaderBar() { 

    const [languageValue, setLanguageValue] = useState(currentUserLanguage)

    useEffect(() => {
        const currentUserLanguage = JSON.parse(localStorage.getItem('languageValue'));
        if (currentUserLanguage=== null || currentUserLanguage=== undefined || currentUserLanguage==='') {
            localStorage.setItem('languageValue', JSON.stringify("de"))
        }
    }
    );

    //Calling t and i18n method from useTranslation hook 
    const { t, i18n } = useTranslation();

    //Creating a method to change the language onChnage from select box
    const changeLanguageHandler = (key) => {
        console.log("KEY HH", key)
        const languageValue = key
        setLanguageValue(key)
        i18n.changeLanguage(languageValue);
        setTimeout(() => {
            i18n.changeLanguage(languageValue)
            localStorage.setItem('languageValue', JSON.stringify(languageValue))
            ////
            setLanguageValue(key)
            window.location.reload()

        }, 100);
     


    }
    //console.log("KEY conso", currentUserLanguage)


    return (
        <div className="header-bar-wrapper">
            <div className="container">
                <div className="header-bar-grid">
                    <div className="header-bar-left">
                        <ul className="d-flex">
                        <li className="d-flex"><FiPhone className="icon" /> <a href={'tel:' + t('phone1')}>{t('phone1')}</a></li>
                        <li className="d-flex"><FaEnvelope className="icon" /> <a href={"mailto:" + t('email1')}>{t('email1')}</a></li>
                        <li className="d-flex"><FiMapPin className="icon" /> {t('adresse')}</li>

                            {/*
                                //sectiondata.HeaderData.HeaderBar.phoneNumber ?
                                    <li className="d-flex"><FiPhone className="icon" /> <a href={'tel:' + t('phone1')}>{t('phone1')}</a></li>
                                  //  : ''
                            }
                            {
                                //sectiondata.HeaderData.HeaderBar.email ?
                                    <li className="d-flex"><FaEnvelope className="icon" /> <a href={"mailto:" + t('email1')}>{t('email1')}</a></li>
                                 //   : ''
                            }
                            {
                               // sectiondata.HeaderData.HeaderBar.location ?
                                    <li className="d-flex"><FiMapPin className="icon" /> {t('adresse')}</li>
                                  //  : ''
                            */}
                        </ul>
                    </div>
                    <div className="header-bar-right text-right">
                        <div className="header-bar-right-flex d-flex">
                            {
                                sectiondata.HeaderData.HeaderBar.socialLists ?
                                    (
                                        <ul className="header-bar-social d-flex">
                                            {sectiondata.HeaderData.HeaderBar.socialLists.map((social, i) => {
                                                return (
                                                    <li key={i}>
                                                        <a href={social.url}>{social.icon}</a>
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                    )
                                    : ''
                            }
                            {
                                sectiondata.HeaderData.HeaderBar.languages ?
                                    (
                                        /*<select className="header-bar-language" style={{ width: 200 }} onChange={changeLanguageHandler}>
                                                {sectiondata.HeaderData.HeaderBar.languages.map((item, index) => {
                                                        return (
                                                            
                                                                <option value={item.url} className="d-flex">
                                                                    <img src={item.flag} alt="flag" /> {item.name}
                                                                </option>
                                                           
                                                        )
                                                    })}
                                                    
                                                </select>*/
                                        <ul className="header-bar-language">
                                            <li>

                                                <a href="/" className="d-flex"><img src={languageValue === "en" ? flat1 : languageValue === "fr" ? flatfr : languageValue === "es" ? flates : languageValue === "de" ? flatde : languageValue === "po" ? flatpo : languageValue === "fi" ? flatfi : languageValue === "ne" ? flatne : languageValue === "it" ? flatit : flatde} alt="flag" /> {languageValue === "en" ? "English" : languageValue === "fr" ? "Français" : languageValue === "es" ? "Spain" : languageValue === "de" ? "Deutsch" : languageValue === "po" ? "Português" : languageValue === "fi" ? "Suomalainen" : languageValue === "ne" ? "Nederlands" : languageValue === "it" ? "Italiano" : "Deutsch"} <FiChevronDown className="icon" /></a>
                                                <ul>
                                                    {sectiondata.HeaderData.HeaderBar.languages.map((item, index) => {
                                                        return (
                                                            <li key={index}>
                                                                <Link onClick={() => changeLanguageHandler(item.url)} /*to={item.url}*/ className="d-flex">
                                                                    <img src={item.flag} alt="flag" /> {item.name}
                                                                </Link>
                                                            </li>
                                                        )
                                                    })}
                                                </ul>
                                            </li>
                                        </ul>
                                    )
                                    : ''
                            }
                            {
                                sectiondata.HeaderData.HeaderBar.btntext ?
                                    (
                                        <div className="header-bar-cta-btn">
                                            <Link to="/demande" className="theme-button">{t('tabSectionNavButton')} {sectiondata.HeaderData.HeaderBar.btnicon}</Link>
                                        </div>
                                    ) : ''
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
