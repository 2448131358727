import React  from 'react'
import { FiChevronRight } from 'react-icons/fi'

import { useTranslation } from "react-i18next";


export default function SubscribeForm() {

    const { t, i18n } = useTranslation()

    return (
        <div className="contact-form-subscribe-content">
            <form>
                <div className="d-flex">
                    <input type="email" name="email" placeholder={t('subscribeFormMail')} />
                    <button type="submit" className="theme-button">{t('subscribeFormButton')} <FiChevronRight className="icon" /></button>
                </div>
            </form>
        </div>
    )
}
