import React, {useState, useEffect}  from 'react'
import { FaGoogle, FaFacebookF, FaTwitter } from 'react-icons/fa'
import { FiChevronRight } from 'react-icons/fi'
import { Link, Redirect } from 'react-router-dom'

import { Form, Button } from 'reactstrap'


import { useTranslation } from "react-i18next";
 
import { authenticationService } from "../../services/authentication-service";

import SweetAlert from 'sweetalert2'

//import { services } from '../../services/services';


const currentUser = authenticationService.currentUserValue;

export default function SignUpForm(props) {



    const { t, i18n } = useTranslation()

    const [password,setPassword] = useState("")
    const [username, setUsername] = useState("")
    const [loading, setLoading] = useState(false)


    const handleChangePassword = (e) => {
    setPassword(e.target.value)
  }

  const handleChangeUsername = (e) => {
    setUsername(e.target.value)
  }

  const handleSubmit = (e) => {

    setLoading(true)

    //const {username, password} = this.state

    authenticationService.login(username, password)
      .then(user => {

        //console.log('user result', user)
        setTimeout(() => {
        window.location.href = `/user`

            //props.history.push(`/shop/`);
            
          //this.props.history.push(`/shop`);
        }, 200);

      })
      .catch(err => {
            setLoading(false)
        //toast.error("Oppss.. Le mot de passe est invalide ou l'utilisateur n'existe pas.");
        SweetAlert.fire(
          'Oppss.. !',
          t('errorLogin'),
          'error'
        )
       
      })


  };

    
    return (
        <section className="signup-form-wrapper">
            <div className="container">
                <div className="signup-form-box">
                    <div className="signup-form-top text-center">
                        <h1 className="signup-form-title">{t('loginAccountText')}</h1>
                        {/*<p>with your social network.</p>*/}
                    </div>
                    {/*<div className="signup-form-signin d-flex">
                        <button className="theme-button google" type="submit">
                            Google <FaGoogle className="icon" />
                        </button>
                        <button className="theme-button facebook" type="submit">
                            Facebook <FaFacebookF className="icon" />
                        </button>
                        <button className="theme-button twitter" type="submit">
                            Twitter <FaTwitter className="icon" />
                        </button>
                    </div>*/}
                    <div className="connector text-center">
                        <span></span>
                    </div>
                    <Form className="signup-form">
                        <input className="form-control" value={username} onChange={(e) => handleChangeUsername(e)} type="text" placeholder={t('formUserOrMail')} />
                        <input className="form-control" value={password} onChange={(e) => handleChangePassword(e)} type="password" placeholder={t('formPassword')} />
                        <div className="checkboxes d-flex">
                            <label className="privacy-policy"> {t('formRemenber')}
                                <input type="checkbox" />
                                <span className="checkmark"></span>
                            </label>
                            {/*<Link to="/forgot-password">Forgot my password?</Link>*/}
                        </div>
                        <Button onClick={handleSubmit}  className="theme-button" disabled={loading ? loading : loading}> {loading ? t('loadingLogin') : t('formLogin')} <FiChevronRight className="icon" /></Button>
                    </Form>
                    <p className="already-account">
                        {t('formNotMember')} <Link to="/sign-up">{t('createAcountText')}</Link>
                    </p>
                </div>
            </div>
        </section>
    )
}
