import {Link} from "react-router-dom";
import {FiChevronRight} from "react-icons/fi";
import React from "react";

import { useTranslation } from "react-i18next";

import {
    FaDonate,
    FaFacebookF,
    FaGooglePlusG,
    FaHandshake,
    FaInstagram,
    FaLinkedinIn,
    FaTwitter,
    FaYoutube
} from "react-icons/fa";
import img1 from '../../assets/images/team-2.jpg'; // 360x370
import img2 from '../../assets/images/team-3.jpg'; // 360x370
import img3 from '../../assets/images/team-4.jpg'; // 360x370
import img4 from '../../assets/images/team04.jpg'; // 360x370
import img5 from '../../assets/images/team02.jpg'; // 360x370
import img6 from '../../assets/images/team03.jpg'; // 360x370


export default function Teammembers({teammembers}) {

    const { t, i18n } = useTranslation()
    
    const Teammembers= [
                {
                    name: 'Paul Carter',
                    designation: 'FOUNDER',
                    img: img1,
                    socials: [
                        {
                            icon: <FaFacebookF />,
                            url: 'https://facebook.com'
                        },
                        {
                            icon: <FaTwitter />,
                            url: 'https://twitter.com'
                        },
                        {
                            icon: <FaLinkedinIn />,
                            url: 'https://linkedin.com'
                        },
                        {
                            icon: <FaYoutube />,
                            url: 'https://youtube.com'
                        }
                    ],
                    buttontext: 'Read His Story',
                    buttonurl: '/team-details'
                },

{
                    name: 'Emma Pop',
                    designation: 'MANAGER',
                    img: img4,
                    socials: [
                        {
                            icon: <FaFacebookF />,
                            url: 'https://facebook.com'
                        },
                        {
                            icon: <FaTwitter />,
                            url: 'https://twitter.com'
                        },
                        {
                            icon: <FaLinkedinIn />,
                            url: 'https://linkedin.com'
                        },
                        {
                            icon: <FaYoutube />,
                            url: 'https://youtube.com'
                        }
                    ],
                    buttontext: 'Read His Story',
                    buttonurl: '/team-details'
                },
                {
                    name: 'John Smic',
                    designation: 'MARKETING',
                    img: img2,
                    socials: [
                        {
                            icon: <FaFacebookF />,
                            url: 'https://facebook.com'
                        },
                        {
                            icon: <FaTwitter />,
                            url: 'https://twitter.com'
                        },
                        {
                            icon: <FaLinkedinIn />,
                            url: 'https://linkedin.com'
                        },
                        {
                            icon: <FaYoutube />,
                            url: 'https://youtube.com'
                        }
                    ],
                    buttontext: 'Read His Story',
                    buttonurl: '/team-details'
                },

                {
                    name: 'Clark Ken',
                    designation: 'CONSULTANT',
                    img: img3,
                    socials: [
                        {
                            icon: <FaFacebookF />,
                            url: 'https://facebook.com'
                        },
                        {
                            icon: <FaTwitter />,
                            url: 'https://twitter.com'
                        },
                        {
                            icon: <FaLinkedinIn />,
                            url: 'https://linkedin.com'
                        },
                        {
                            icon: <FaYoutube />,
                            url: 'https://youtube.com'
                        }
                    ],
                    buttontext: 'Read His Story',
                    buttonurl: '/team-details'
                },
                {
                    name: 'Brad Leon',
                    designation: 'ASSISTANT MANAGER',
                    img: img5,
                    socials: [
                        {
                            icon: <FaFacebookF />,
                            url: 'https://facebook.com'
                        },
                        {
                            icon: <FaTwitter />,
                            url: 'https://twitter.com'
                        },
                        {
                            icon: <FaLinkedinIn />,
                            url: 'https://linkedin.com'
                        },
                        {
                            icon: <FaYoutube />,
                            url: 'https://youtube.com'
                        }
                    ],
                    buttontext: 'Read His Story',
                    buttonurl: '/team-details'
                },
                {
                    name: 'Jane Eyre',
                    designation: 'CONSULTANT',
                    img: img6,
                    socials: [
                        {
                            icon: <FaFacebookF />,
                            url: 'https://facebook.com'
                        },
                        {
                            icon: <FaTwitter />,
                            url: 'https://twitter.com'
                        },
                        {
                            icon: <FaLinkedinIn />,
                            url: 'https://linkedin.com'
                        },
                        {
                            icon: <FaYoutube />,
                            url: 'https://youtube.com'
                        }
                    ],
                    buttontext: 'Read His Story',
                    buttonurl: '/team-details'
                }
            ]

    return (
        <>
            {Teammembers.map((item, index) => {
                return (
                    <div className="team" key={index}>
                        <div className="team-img">
                            {item.img?
                                <img src={item.img} alt="Team" />
                                : ''
                            }
                            {item.socials?
                                <ul className="socials" >
                                    {item.socials.map((social, i) => {
                                        return (
                                            <a key={i} href={social.url}>{social.icon}</a>
                                        )
                                    })}
                                </ul> : ''
                            }
                        </div>
                        <div className="team-content">
                            {item.name?
                                <h2 className="name">{item.name}</h2>
                                : ''
                            }
                            {item.designation?
                                <p className="designation">{item.designation}</p>
                                : ''
                            }
                            {/*item.buttontext?
                                <Link to={item.buttonurl} className="theme-button">{item.buttontext} <FiChevronRight className="icon" /></Link>
                                : ''
                            */}
                        </div>
                    </div>
                )
            })}
        </>
    )
}