import {Link} from "react-router-dom";
import {FiPhone} from "react-icons/fi";
import {FaEnvelope, FaMapMarkerAlt} from "react-icons/fa";
import React from "react";
import SocialProfile from "./SocialProfile";

import { useTranslation } from "react-i18next";

export default function FooterLeft({ftlogo, content, phonenumber, email, address, scltitle, scllists}) {

    const { t, i18n } = useTranslation()

    

    return (
        <div className="footer-widget footer-left">
            <Link to="/">
                <img src={ftlogo} alt="Footer Logo" />
            </Link>
            <p className="desc">
                {t('content')}
            </p>
            <ul className="footer-address">
                <li>
                    <a href={"tel:"+t('phonenumber')}><span className="icon"><FiPhone /></span> {t('phonenumber')}</a>
                </li>
                <li>
                    <a href={"mailto:"+t('email')}><span className="icon"><FaEnvelope /></span> {t('email')}</a>
                </li>
                <li>
                    <a href="https://maps.google.com"><span className="icon"><FaMapMarkerAlt /></span> {t('adresse')}</a>
                </li>
            </ul>

            {/* Social Profile */}
            <div className="social">
                <SocialProfile title={scltitle} lists={scllists} />
            </div>
        </div>
    )
}
