import React  from 'react'
import Navbar from '../components/common/Navbar'
import Breadcrumb from '../components/common/Breadcrumb'
import SectionsTitle from '../components/common/SectionsTitle'
import Service from '../components/services/Services'
import ServiceSliderSection from "../sections/ServiceSliderSection";
import ServiceVideo from '../components/services/ServiceVideo';
import Divider from '../components/other/Divider'
import CtaForm from '../components/other/CtaForm'
import Footer from '../components/other/Footer'
import sectiondata from "../store/store";

import SimpleTab from '../components/tabs/SimpleTab'

import { useTranslation } from "react-i18next";

export default function ServicePage() {

    const { t, i18n } = useTranslation()

    return (
        <div className="service-main">
            <header className="header-area">
                <Navbar />
            </header>
            <Breadcrumb title={t('servicesBreadcrumbTitle')} />
            <div className="h-service">
                <div className="container">
                    <div className="row text-center">
                        <div className="sec-heading">
                <p className="sec__meta">{t('secServiceSubtitle')}</p>
                <h2 className="sec__title">
                    {t('secServiceTitle')}
                </h2>
            </div>
                    </div>
                </div>
                <div className="container">
                    <div className="services-items-grid">
                        <Service sLists={sectiondata.Services.Service1.serviceLists} />
                    </div>
                </div>
            </div>

            <SimpleTab/> 
            {/* Services Slider
            <ServiceSliderSection
                serviceSlideritem={sectiondata.Services.Service2.lists}
                sectitle={sectiondata.Services.Service2.sectitle}
                secsubtitle={sectiondata.Services.Service2.secsubtitle}
                content={sectiondata.Services.Service2.content}
                buttontext={sectiondata.Services.Service2.buttontext}
                buttonurl={sectiondata.Services.Service2.buttonurl} />
                */}
            {/* Services Video */}
            <ServiceVideo />
            <Divider />

            {/* CTA Form */}
            <div className="form-white-bg">
                <CtaForm />
            </div>

            {/* Footer */}
            <Footer />
        </div>
    )
}
