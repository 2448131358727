import React from 'react'
import { Link } from 'react-router-dom'

import { useTranslation } from "react-i18next";


export default function Breadcrumb({ title }) {
    const { t, i18n } = useTranslation()

    return (
        <section className="breadcrumb-wrapper text-center">
            <div className="container">
                <div className="breadcrumb-content">
                    <h2>{title}</h2>
                    <ul className="breadcrumb">
                        <li><Link to="/">{t('menuHome')}</Link></li>
                        <li>{title}</li>
                    </ul>
                </div>
            </div>
        </section>
    )
}

