import React from 'react'

import { useTranslation } from "react-i18next";

export default function SectionsTitle({ title, subtitle }) {

    const { t, i18n } = useTranslation()


    return (
        <>
            <div className="sec-heading">
                <p className="sec__meta">{t('testimonialSectSub')}</p>
                <h2 className="sec__title">
                    {t('testimonialSectTitle')}
                </h2>
            </div>
        </>
    )
}
