import React, { Component, lazy, Suspense } from 'react';
//import { CardImg, Modal, ModalBody, ModalHeader, Input, Button, Label, Form, Alert } from 'reactstrap';
import { services } from '../../services/services';


//import { useTranslation } from "react-i18next";
//import { FaDollarSign, FaHandHoldingUsd } from "react-icons/fa";
import SweetAlert from 'sweetalert2';


import './Contain.css';
import Dash from './Dash';
import Transfert from './Transfert';
import Profil from './Profil'

import { withTranslation } from 'react-i18next';
import { Row, Col } from 'reactstrap';

import { authenticationService } from "../../services/authentication-service";


const currentUserLanguage = JSON.parse(localStorage.getItem('languageValue'))


//const currentUser = authenticationService.currentUserValue;

class Contain extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            languageValue: currentUserLanguage,
            userInfo: '',
            page: 'DASH',
            password: '',
            newPassword: '',



        };
    }


     disconnect = () => {
    
        authenticationService.logout()
      }


    handleChange = (e) => {
        //console.log('Mise a jour state', this.state)
        this.setState({ [e.target.name]: e.target.value })
    }

    updatePassword = () => {
        const { password, newPassword } = this.state
        let req = {
            password,
            newPassword
        }
        services.updatePassword(req)
            .then(pass => {
                SweetAlert.fire(
                    // {title: t('userSuccessPasswordTilte'), text: t('userSuccessPassword'), icon: "success"}
                )
                //setPassword('')
                //setNewPassword('')
            })
            .catch(err => {
                SweetAlert.fire(
                    'Oppss.. !',
                    // t('errorPasswordUpdate'),
                    'error'
                )

            })
    }

    componentDidMount() {

        //const currentUser = JSON.parse(localStorage.getItem('currentUser'));
        //console.log('currentUser', currentUser);
        // setUserInfo(currentUser);

        //if (currentUser === null || currentUser === undefined || currentUser === '' || currentUser === {}) {
            services.getCurrentUser()
                .then(userInfo => {
                    //console.log('CONTAIN Information complete utilisateur', userInfo)
                    this.setState({ userInfo: userInfo, soldeTotal: userInfo.solde })
                     if (userInfo.role === 'ADMIN'){
                         this.setState({page : "TRANSFERT"})
                     }
                })
       /* } else {
            this.setState({ userInfo: currentUser })
        }*/
    }

    render() {
        const {
            page,
            userInfo,
            password,
            newPassword,

        } = this.state

        const { t } = this.props;



        return (

            <section className="shop-sidebar-wrapper text-center">
                <div className="container body-contain">
                    <div >

                        <div className="main-container-contain">
                            <Row>
                                <Col>
                                    {/* HEADER */}
                                    <div className="header-contain block">
                                        <ul className=" ul-contain header-menu horizontal-list">
                                            <li style={{ cursor: 'pointer' }} className="li-contain">
                                                <div className="a-contain" style={{ color: '#fff' }} className="header-menu-tab" href="#1" onClick={() => this.setState({ page: 'DASH' })}><span className="span-contain" className="icon entypo-cog scnd-font-color" />{t('userDashboard')}</div>
                                            </li>
                                            <li style={{ cursor: 'pointer' }} className="li-contain">
                                                <div style={{ color: '#fff' }} className="a-contain" className="header-menu-tab" href="#5" onClick={() => this.setState({ page: 'TRANSFERT' })}><span className="span-contain" className="icon entypo-paper-plane scnd-font-color" />{t('userTransfert')}</div>
                                            </li>
                                            <li style={{ cursor: 'pointer' }} className="li-contain">
                                                <div style={{ color: '#fff' }} className="a-contain" className="header-menu-tab" href="#2"  onClick={() => this.setState({ page: 'PROFIL' })}><span className="span-contain" className="icon fontawesome-user scnd-font-color" />{t('userProfil')}</div>
                                            </li>
                                            <li style={{ cursor: 'pointer' }} className="li-contain">
                                                <div style={{ color: '#fff' }} className="a-contain" className="header-menu-tab" onClick={this.disconnect} ><span className="icon fontawesome-home scnd-font-color" />{t('btnDeconnect')}</div>
                                               
                                            </li>
                                            {/*<li style={{ cursor: 'pointer' }} className="li-contain">
                                                <div style={{ color: '#fff' }} className="a-contain" className="header-menu-tab" href="#3"><span className="icon fontawesome-envelope scnd-font-color" />{t('userContact')}</div>
                                                <div className="a-contain" className="header-menu-number" href="#4">5</div>
                                            </li>*/}
                                           
                                        </ul>
                                        { userInfo.role !== 'ADMIN' &&
                                        <div className="profile-menu">
                                            <p className="p-contain">{userInfo.firstName}: {userInfo.solde} {userInfo.devise} <a className="a-contain" href="#26"><span className="span-contain" className="entypo-down-open scnd-font-color" /></a></p>

                                            <div className="profile-picture small-profile-picture">
                                                <img width="40px" alt="Anne Hathaway picture" src="https://bootdey.com/img/Content/avatar/avatar7.png" />
                                            </div>
                                        </div>
                                        }
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    {page === "DASH" &&
                                        <Dash />
                                    }
                                    {page === "TRANSFERT" &&
                                        <Transfert />
                                    }
                                    {page === "PROFIL" &&
                                        <Profil />
                                    }
                                </Col>
                            </Row>

                        </div> {/* end main-container */}

                    </div>
                </div>
             
            </section>


        );
    }
}

export default withTranslation()(Contain);
