import React, { Component, lazy, Suspense, useMemo } from 'react';
//import { CardImg, Modal, ModalBody, ModalHeader, Input, Button, Label, Form, Alert } from 'reactstrap';
import { services } from '../../services/services';


//import { useTranslation } from "react-i18next";
//import { FaDollarSign, FaHandHoldingUsd } from "react-icons/fa";
//import SweetAlert from 'sweetalert2';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { FaEye, FaCheck } from 'react-icons/fa'

import { withTranslation } from 'react-i18next';



import { message, Popconfirm } from "antd";

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { Alert, Button, Modal, Card, Row, Col, CardText, CardTitle, CardSubtitle, Form, FormGroup, Label, Input, ModalHeader, ModalBody, ModalFooter, CardBody } from 'reactstrap';
import Media from 'react-media';

import Snackbar from '@material-ui/core/Snackbar';

//import Select from 'react-select'
import { CountryDropdown,/* RegionDropdown, CountryRegionData*/ } from 'react-country-region-selector';

import TextField from '@material-ui/core/TextField';

import ProgressBar from './ProgressBar';
import ProgressBar2 from './ProgressBar2';
import ProgressBar3 from './ProgressBar3';

import SweetAlert from 'sweetalert2'


import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

//import DoneAllIcon from '@material-ui/icons/DoneAll';

//const currentUser = authenticationService.currentUserValue;

const currentUserLanguage = JSON.parse(localStorage.getItem('languageValue'))

//const options = useMemo(() => countryList().getData(), []) 


class Transfert extends Component {
  constructor(props) {
    super(props);

    this.state = {
      languageValue: currentUserLanguage,
      userInfo: '',
      page: 'DASH',
      password: '',
      newPassword: '',
      transferts: [],
      isAddModalVisible: false,
      pays: '',
      Banque: '',
      intitule: '',
      compte: '',
      montant: '',
      iban: '',

      successMsg: '',
      warningMsg: '',
      infoMsg: '',
      alertWarningMsg: false,
      alertWarningMsg: false,
      alertSuccessMsg: false,

      alertErrMsg: false,
      errMsg: '',
      alertInfoMsg: false,
      infoMsg: '',


      open: false,
      openOperation: false,
      ProgressVisible: false,
      ProgressVisible2: false,
      ProgressVisible3: false,
      echecMessage: false,
      echecMessage2: false,
      echecMessage3: false,
      statutCode: false,
      errorCodeTrasfertInput: false,
      codeTransfert1: '',
      codeTransfert2: '',
      codeTransfert3: '',
      soldeTotal: 0,
      openDetailModal: false,



    };

  }

  handleCloseDialog = () => {
    this.setState({ 
      open: false,
      pays: '',
      Banque: '',
      intitule: '',
      compte: '',
      montant: '',
      iban: '',

      successMsg: '',
      warningMsg: '',
      infoMsg: '',
      alertWarningMsg: false,
      alertWarningMsg: false,
      alertSuccessMsg: false,

      alertErrMsg: false,
      errMsg: '',
      alertInfoMsg: false,
      infoMsg: '',


      open: false,
      openOperation: false,
      ProgressVisible: false,
      ProgressVisible2: false,
      ProgressVisible3: false,
      echecMessage: false,
      echecMessage2: false,
      echecMessage3: false,
      statutCode: false,
      errorCodeTrasfertInput: false,
      codeTransfert1: '',
      codeTransfert2: '',
      codeTransfert3: '',
      openDetailModal: false,
     })
  }

  selectCountry(val) {
    //console.log('VAL', val)
    this.setState({ pays: val });
  }

  handleChange = (e) => {
    //console.log('Mise a jour state', this.state)
    this.setState({ [e.target.name]: e.target.value })
  }

  changeHandler = value => {
    this.setState({ pays: value })
  }


  /* handleChange = (e) => {
   }*/

  handleAddOpen = () => {
    this.setState({ isAddModalVisible: !this.state.isAddModalVisible })
  };

  handleValidateOpen = (row) => {

    SweetAlert.fire({
      title: this.props.t('titleTransfertValider'),
      text: `${this.props.t('descriptTransfertValider')} ${row.intitule}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.props.t('table.boutton.enregistrer'),
      cancelButtonText: this.props.t('modalCloseButton'),
      reverseButtons: true
    })
      .then((result) => {

        if (result.value) {

          services.validerTransfert(row.id, row.user)
            .then(result => {
              ////console.log(result)
              //this.setState({ commande: '', open: false })
              SweetAlert.fire(
                this.props.t('successTransfertTitle'),
                this.props.t('successTransfertDescript'),
                'success'
              )
              services.getTransfert()
                .then(transferts => {
                  //console.log('transferts', transferts)
                  this.setState({ transferts })
                })
            })
            .catch(() => {
              SweetAlert.fire({ title: this.props.t('errorTransfertTitle'), text: this.props.t('errorTransfertDescript'), icon: "error" });
            });

        }
        else {
          SweetAlert.fire(
            'Validation Annulée!'
          ).then((result) => {
            if (result.value) {
              this.setState({ open: false })
            }

          })
        }
      })
  }


  handleCancelAdd = () => {

  }


  handleDelete = (row) => {


  }

  handleDeleteFournisseur = () => {

    const { codFour } = this.state

    services.deleteFournisseur(codFour)
      .then(del => {
        ////console.log('delete user', del)
        message.success('Fournisseur supprimer avec succès', 10)
        services.getFournisseurs()
          .then(fournisseurs => {

          })

      })
      .catch((err) => {
        message.error(err, 10)
      });

  }

  handleUpdateOpen = (row) => {

  }

  handleCancelUpdate = () => {

  }


  handleView = (row) => {
    this.setState({
      row,
      montant: row.montant,
      compte: row.compte,
      intitule: row.intitule,
      iban: row.iban,
      Banque: row.Banque,
      pays: row.pays,
      statut: row.statut,
      openDetailModal: true,

    })

  };

  handleDetailClose = () => {
    this.setState({
      montant: '',
      compte: '',
      intitule: '',
      iban: '',
      Banque: '',
      pays: '',
      statut: '',
      openDetailModal: false,
    })
  }

  cancelDelete = () => {

  }

  handleCancel = () => {

  }





  componentDidMount() {

    //const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    ////console.log('currentUser', currentUser);
    // setUserInfo(currentUser);

    //if (currentUser === null || currentUser === undefined || currentUser === '' || currentUser === {}) {
    services.getCurrentUser()
      .then(userInfo => {
        //console.log('CONTAIN Information complete utilisateur', userInfo)
        this.setState({ userInfo: userInfo, soldeTotal: userInfo.solde })

      })
    /*} else {
      this.setState({ userInfo: currentUser, soldeTotal: currentUser.solde })
    }*/

    services.getTransfert()
      .then(transferts => {
        //console.log('transferts', transferts)
        this.setState({ transferts })
      })
  }

  addTransfert = () => {
    const {
      montant,
      compte,
      intitule,
      iban,
      Banque,
      pays,
      soldeTotal


    } = this.state



    /*if (compte === '') {
      this.setState({ alertWarningMsg: true, warningMsg: this.props.t('alertAccountNumber'), })
      setTimeout(() => this.setState({ warningMsg: '', alertWarningMsg: false }), 6000)
      return;
    }*/

    if (pays === '') {
      this.setState({ alertWarningMsg: true, warningMsg: this.props.t('alertCountrySelect'), })
      setTimeout(() => this.setState({ warningMsg: '', alertWarningMsg: false }), 6000)
      return;
    }
    if (montant === '') {
      this.setState({ alertWarningMsg: true, warningMsg: this.props.t('alertMontantInfo'), })
      setTimeout(() => this.setState({ warningMsg: '', alertWarningMsg: false }), 6000)
      return;
    }

    if (parseInt(montant) > parseInt(soldeTotal)) {
      this.setState({ alertWarningMsg: true, warningMsg: this.props.t('alertErrMontant') })
      setTimeout(() => this.setState({ warningMsg: '', alertWarningMsg: false }), 6000)
      return;
    }

    let req = {
      montant,
      compte,
      intitule,
      iban,
      Banque,
      pays: pays.toUpperCase(),


    }
    services.addTransfert(req)
      .then(result => {
        //console.log(result)
        this.setState({
          montant: '',
          compte: '',
          intitule: '',
          iban: '',
          Banque: '',
          pays: '',
          rib: '',
          bic: '',
          city: '',
          phone: '',
          gender: '',
          banque: '',
          profession: '',
          address: '',
          intitule: '',
          pays: '',
          country: '',
          intitule: '',
          statutClient: '',
          commune: '',
          // role: '',
          roleSlug: '',
          //modalAddClient: !this.state.modalAddClient,
          open: !this.state.open,
          ProgressVisible: !this.state.ProgressVisible,
          //alertSuccessMsg: true,
          //successMsg: <IntlMessages id="successMsg.transfert" />

        })

        setTimeout(() => {
          this.setState({ echecMessage: true, ProgressVisible: !this.state.ProgressVisible, successMsg: '', alertSuccessMsg: false });
        }, 10000)
        services.getTransfert()
          .then(transferts => {
            this.setState({ transferts })
          })

      })

      .catch(() => {
        this.setState({ errMsg: this.props.t('errMsg.transfert'), alertErrMsg: true })
        setTimeout(() => {
          this.setState({ errMsg: '', alertErrMsg: false });
        }, 6000)
      });

  }

  verify1 = () => {
    const {
      codeTransfert1,
    } = this.state



    if (codeTransfert1 === '') {
      this.setState({ alertWarningMsg: true, warningMsg: this.props.t('warning.TranfertCode1'), })
      setTimeout(() => this.setState({ warningMsg: '', alertWarningMsg: false }), 6000)
      return;
    }

    let req = {
      codeTransfert1,

    }
    services.verify1(req)
      .then(result => {
        //console.log(result)
        this.setState({
          codeTransfert1: '',

          //alertSuccessMsg: true,
          echecMessage: false,
          ProgressVisible2: true,
          //successMsg: this.props.t('successMsg.transfert')

        })

        setTimeout(() => {
          this.setState({ echecMessage2: true, successMsg: '', alertSuccessMsg: false, ProgressVisible2: false });
        }, 10000)
        /*services.getTransfert()
            .then(transferts => {
                this.setState({ transferts })
            })*/

      })

      .catch(() => {
        this.setState({ errorCodeTrasfertInput: true, errMsg: this.props.t('errMsg.transfert'), echecMessage: true })
        setTimeout(() => {
          this.setState({ errorCodeTrasfertInput: false, errMsg: '' });
        }, 6000)
      });

  }

  verify2 = () => {
    const {
      codeTransfert2,
    } = this.state



    if (codeTransfert2 === '') {
      this.setState({ alertWarningMsg: true, warningMsg: this.props.t('warning.TranfertCode2'), })
      setTimeout(() => this.setState({ warningMsg: '', alertWarningMsg: false }), 6000)
      return;
    }

    let req = {
      codeTransfert2,

    }
    services.verify2(req)
      .then(result => {
        //console.log(result)
        this.setState({
          codeTransfert2: '',
          ProgressVisible2: false,
          //alertSuccessMsg: true,
          echecMessage: false,
          echecMessage2: false,
          ProgressVisible3: true,
          //successMsg: <IntlMessages id="successMsg.transfert" />

        })

        setTimeout(() => {
          this.setState({ echecMessage3: true, successMsg: '', alertSuccessMsg: false, ProgressVisible3: false });
        }, 11000)
        /*services.getTransfert()
            .then(transferts => {
                this.setState({ transferts })
            })*/

      })

      .catch(() => {
        this.setState({ errorCodeTrasfertInput: true, errMsg: this.props.t('errMsg.transfert'), echecMessage2: true })
        setTimeout(() => {
          this.setState({ errorCodeTrasfertInput: false, errMsg: '' });
        }, 6000)
      });

  }

  verify3 = () => {
    const {
      codeTransfert3,
    } = this.state


    if (codeTransfert3 === '') {
      this.setState({ alertWarningMsg: true, warningMsg: this.props.t('warning.TranfertCode3'), })
      setTimeout(() => this.setState({ warningMsg: '', alertWarningMsg: false }), 6000)
      return;
    }

    let req = {
      codeTransfert3,

    }

    services.verify3(req)
      .then(result => {
        //console.log(result)
        this.setState({

          echecMessage: false,
          echecMessage2: false,

          echecMessage3: false,
          codeTransfert3: '',
          ProgressVisible3: true,

        })
        services.getTransfert()
          .then(transferts => {
            this.setState({ transferts })
          })
        setTimeout(() => {
          this.setState({
            codeTransfert3: '',

            alertSuccessMsg: true,
            echecMessage3: false,
            ProgressVisible3: true,
            successMsg: this.props.t('successMsg.transfert'),
            open: false,
            isAddModalVisible: false,
            ProgressVisible3: false,
          });

        }, 10000)

        setTimeout(() => {
          this.setState({ echecMessage3: false, successMsg: '', alertSuccessMsg: false, });
        }, 15000)
        /*services.getTransfert()
            .then(transferts => {
                this.setState({ transferts })
            })*/

      })

      .catch(() => {
        this.setState({ errorCodeTrasfertInput: true, errMsg: this.props.t('errMsg.transfert'), echecMessage3: true })
        setTimeout(() => {
          this.setState({ errorCodeTrasfertInput: false, errMsg: '' });
        }, 6000)
      });

  }

  render() {
    const {
      page,
      userInfo,
      password,
      newPassword,
      montant,
      compte,
      intitule,
      iban,
      Banque,
      pays,
      transferts,
      isAddModalVisible,

      successMsg,
      warningMsg,
      infoMsg,
      alertWarningMsg,

      alertSuccessMsg,
      alertErrMsg,
      errMsg,
      alertInfoMsg,

      open,
      openOperation,
      ProgressVisible,
      ProgressVisible2,
      ProgressVisible3,
      echecMessage,
      echecMessage2,
      echecMessage3,
      statutCode,
      errorCodeTrasfertInput,
      codeTransfert1,
      codeTransfert2,
      codeTransfert3,
      openDetailModal,

      statut,


    } = this.state

    const { t } = this.props;


    const { SearchBar } = Search;

    const defaultSorted = [{
      dataField: 'id',
      order: 'desc',
      text: 'N°',

    }
    ];

    const columnsTransferts = [{
      dataField: 'id',
      text: t('transfTableNumber')
    },
    {
      dataField: 'pays',
      text: t('formCountry')
    },
    {
      dataField: 'Banque',
      text: t('formBanque')
    },
    {
      dataField: 'iban',
      text: t('formIban')
    },
    {
      dataField: 'intitule',
      text: t('formAccountTitulair')
    },

    /*{
      dataField: 'compte',
      text: t('formAccountNumber')
    },*/

    {
      dataField: 'montant',
      text: t('formMontant')
    },

    {
      dataField: 'statut',
      text: t('formStatut'),
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          < div
            style={{ textAlign: "center" }} className="d-flex">
              {row.statut ==="ECHEC"? t('txtEchc') : row.statut ==="EN COURS"? t('txtEnCou') : row.statut ==="VALIDER"? t('txtVlder') : t('txtEchc') }

          </div>
        );
      },
    },


    {
      dataField: '',
      text: t('formAction'),
      headerAlign: 'center',
      sort: true,
      headerAttrs: { width: 50 },
      align: 'center',
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          < div
            style={{ textAlign: "center" }} className="d-flex">


            <Button
              type="primary"
              //fullWidth
              variant="contained"
              color="primary"
              size="sm"
              className="mr-2 btn btn-primary btn-sm"
              onClick={() => this.handleView(row)}
            >
              <FaEye className="icon" />
            </Button>

            {userInfo.role === 'ADMIN' && row.statut !== 'VALIDER' &&
              <Button
                type="primary"
                //fullWidth
                variant="contained"
                color="info"
                size="sm"
                //className="btn btn-success btn-sm"
                onClick={() => this.handleValidateOpen(row)}
              >
                <FaCheck className="icon" />

              </Button>
            }
            {/*<Popconfirm placement="left" title="Êtes vous sûr de vouloir supprimer ce Fournisseur?" onConfirm={this.handleDeleteFournisseur} onCancel={this.cancelDelete} okText="Oui"
              cancelText="Non">
              <Button
                type="danger"
                //fullWidth
                variant="contained"
                color="info"
                size="sm"
                //className="btn btn-success btn-sm"
                onClick={() => this.handleDelete(row)}
              >
                <i className="icon icon-alert-new" />
              </Button>

        </Popconfirm>*/}

          </div>
        );
      },

    }

    ];


    const columnsTransfertsPhone = [
      {
        dataField: 'id',
        text: 'N°',
        order: 'desc',
        hidden: true
      },

      {
        dataField: 'intitule',
        text: t('formAccountTitulair')
      },

      {
        dataField: 'montant',
        text: t('formMontant')
      },

      {
        dataField: '',
        text: 'Action',
        headerAlign: 'center',
        sort: true,
        headerAttrs: { width: 50 },
        align: 'center',
        formatter: (cell, row, rowIndex, formatExtraData) => {
          return (
            < div
              style={{ textAlign: "center" }} className="d-flex">


              <Button
                type="primary"
                //fullWidth
                variant="contained"
                color="primary"
                size="sm"
                className="mr-2 btn btn-primary btn-sm"
                onClick={() => this.handleView(row)}
              >
                <FaEye className="icon" />
              </Button>

              {userInfo.role === 'ADMIN' &&
                <Button
                  type="primary"
                  //fullWidth
                  variant="contained"
                  color="info"
                  size="sm"
                  //className="btn btn-success btn-sm"
                  onClick={() => this.handleValidateOpen(row)}
                >
                  <FaCheck className="icon" />

                </Button>
              }
              {/*<Popconfirm placement="left" title="Êtes vous sûr de vouloir supprimer ce Fournisseur?" onConfirm={this.handleDeleteFournisseur} onCancel={this.cancelDelete} okText="Oui"
              cancelText="Non">
              <Button
                type="danger"
                //fullWidth
                variant="contained"
                color="info"
                size="sm"
                //className="btn btn-success btn-sm"
                onClick={() => this.handleDelete(row)}
              >
                <i className="icon icon-alert-new" />
              </Button>

        </Popconfirm>*/}

            </div>
          );
        },

      }

    ];

    //console.log('FORM', pays, Banque, iban, intitule, montant, compte)


    return (

      <  >


        <Card className="card">
          <CardBody class="card-body">
            <h5 className="card-title">{t('transfTableTitle')}</h5>

            <Media query="(min-width: 600px)" render={() =>
            (
              <ToolkitProvider
                keyField="id"
                data={transferts}
                columns={columnsTransferts}
                //cellEdit={cellEditFactory({ mode: 'dbclick' })}
                search
                //insertRow={true}
                exportCSV

              >
                {
                  props => (
                    <div>
                      <Row >
                        <Col md={6} style={{ display: 'flex', alignItems: 'baseline' }}>
                          {/*<i className="fa fa-search" style={{ margin: 10 }}></i> */}

                          <SearchBar {...props.searchProps}
                            //delay={ 1000 }
                            style={{ maxWidth: 750, width: '180%', margin: 'auto', display: 'flex', borderRadius: 50 }}
                            placeholder={t('searchTitle')}

                          />
                        </Col>
                        {//currentUser && currentUser.slug === 'COMMERCIAL_ROLE' &&
                          <Col md={6} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'baseline', }}>
                            {userInfo.role !== 'ADMIN' &&
                            <button style={{ marginLeft: 3 }}
                              className="btn bg-primary text-light rounded"
                              onClick={this.handleAddOpen}>
                              {t('buttonAddTransfert')} <i className="icon icon-add" />
                            </button>
                        }
                          </Col>

                        }
                      </Row>


                      <BootstrapTable
                        {...props.baseProps}
                        //bootstrap4
                        wrapperClasses="table-responsive"
                        //selectRow={ selectRow }
                        //defaultSorted={defaultSorted}
                        pagination={paginationFactory()}
                        defaultSorted={defaultSorted}
                        striped
                        hover
                        condensed
                        headerWrapperClasses="foo"
                        noDataIndication={t('noDataTableIndication')}
                      //filter={filterFactory()}
                      />

                    </div>

                  )
                }
              </ToolkitProvider>
            )}
            />


            <Media query="(max-width: 599px)" render={() =>
            (

              <ToolkitProvider
                keyField="id"
                data={transferts}
                columns={columnsTransfertsPhone}
                //cellEdit={cellEditFactory({ mode: 'dbclick' })}
                search
                //insertRow={true}
                exportCSV

              >
                {
                  props => (
                    <div>
                      <Row >
                        <Col md={6} style={{ display: 'flex', alignItems: 'baseline' }}>
                          <i className="fa fa-search" style={{ margin: 10 }}></i>

                          <SearchBar {...props.searchProps}
                            //delay={ 1000 }
                            style={{ maxWidth: 450, width: '125%', margin: 'auto', display: 'flex', borderRadius: 50 }}
                            placeholder={t('searchTitle')}

                          />
                        </Col>
                        {//currentUser && currentUser.slug === 'COMMERCIAL_ROLE' &&
                          <Col md={6} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'baseline', }}>

                            <button style={{ marginLeft: 3 }}
                              className="btn bg-primary text-light rounded"
                              onClick={this.handleAddOpen}>
                              {t('buttonAddTransfert')} <i className="icon icon-add" />
                            </button>
                          </Col>
                        }

                      </Row>

                      <BootstrapTable
                        {...props.baseProps}
                        //bootstrap4
                        wrapperClasses="table-responsive"
                        //selectRow={ selectRow }
                        //defaultSorted={defaultSorted}
                        pagination={paginationFactory()}
                        defaultSorted={defaultSorted}
                        striped
                        hover
                        condensed
                        headerWrapperClasses="foo"
                        noDataIndication={t('noDataTableIndication')}
                      //filter={filterFactory()}
                      />

                    </div>

                  )
                }
              </ToolkitProvider>
            )}
            />
          </CardBody>
        </Card>


        <Modal className="modal-dialog modal-lg" centered={true} scrollable={true} isOpen={isAddModalVisible}>

          <ModalHeader toggle={this.handleAddOpen}>

            <Row>
              <Col md={12}>
                <h5 style={{ color: "#1D75BB" }} >{t('modalTransfertTitle')} </h5>
              </Col>
            </Row>
          </ModalHeader>

          <ModalBody /*style={{ maxHeight: '800px', maxWidth:'800px', overflow: 'auto' }}*/ toggle={this.handleAddOpen}>


            <Form>


              <Row form>

                <Col md={12}>
                  <FormGroup>
                    <Label for="codFour">{t('formCountry')}</Label>
                    <CountryDropdown
                      value={pays}
                      onChange={(val) => this.selectCountry(val)}
                      labelType="full"
                      style={{ width: '100%' }}
                      defaultOptionLabel={t('sltCountrPay')}
                      name={t('sltCountrPay')}


                    />
                  </FormGroup>
                </Col>


              </Row>

              <Row form>

                <Col md={6}>
                  <FormGroup>
                    <Label for="Banque">{t('modalBankName')}</Label>
                    <Input type="text" name="Banque" value={Banque} onChange={this.handleChange} class="form-control" placeholder={t('modalBankName')} />
                  </FormGroup>
                </Col>

                <Col md={6}>
                  <FormGroup>
                    <Label for="iban">{t('modalIban')}</Label>
                    <Input type="text" name="iban" value={iban} onChange={this.handleChange} class="form-control" placeholder={t('modalIban')} />
                  </FormGroup>
                </Col>


              </Row>

              <Row form>

                <Col md={6}>
                  <FormGroup>
                    <Label for="intitule">{t('modalAccountTitulair')}</Label>
                    <Input type="text" name="intitule" value={intitule} onChange={this.handleChange} class="form-control" placeholder={t('modalAccountTitulair')} />
                  </FormGroup>
                </Col>

               {/* <Col md={6}>
                  <FormGroup>
                    <Label for="compte">{t('modalAccountNumber')}</Label>
                    <Input type="text" name="compte" value={compte} onChange={this.handleChange} class="form-control" placeholder={t('modalAccountNumber')} />
                  </FormGroup>
                </Col>*/}
                <Col md={6}>
                  <FormGroup>
                    <Label for="montant">{t('modalPrice')}</Label>
                    <Input type="number" name="montant" value={montant} onChange={this.handleChange} class="form-control" placeholder={t('modalPrice')} />
                  </FormGroup>
                </Col>

              </Row>

            </Form>

          </ModalBody>
          <ModalFooter>
            <div className="text-right">
              {//commande && commande.etatCmde === "EN COURS" &&
                <Button color="primary" onClick={this.addTransfert}>{t('modalTransfertButton')}</Button>
              }
              <Button className="ml-2" color="secondary" onClick={this.handleAddOpen}>{t('modalCloseButton')}</Button>
            </div>


          </ModalFooter>
        </Modal>

        <Modal className="modal-dialog modal-lg" centered={true} scrollable={true} isOpen={openDetailModal}>

          <ModalHeader toggle={this.handleDetailClose}>

            <Row>
              <Col md={12}>
                <h5 style={{ color: "#1D75BB" }} >{t('modalTransfertDetailsTitle')} </h5>
                <h6 style={{ color: "red" }} >{t('formStatut')}: {statut ==="ECHEC"? t('txtEchc') : statut ==="EN COURS"? t('txtEnCou') : statut ==="VALIDER"? t('txtVlder') : t('txtEchc') } </h6>

              </Col>
            </Row>
          </ModalHeader>

          <ModalBody /*style={{ maxHeight: '800px', maxWidth:'800px', overflow: 'auto' }}*/ toggle={this.handleDetailClose}>


            <Form>


              <Row form>

                <Col md={12}>
                  <FormGroup>
                    <Label for="codFour">{t('formCountry')}</Label>
                    <Input
                      value={pays}
                      //onChange={(val) => this.selectCountry(val)}
                      labelType="full"
                      style={{ width: '100%' }}
                      disabled

                    />
                  </FormGroup>
                </Col>


              </Row>

              <Row form>

                <Col md={6}>
                  <FormGroup>
                    <Label for="Banque">{t('modalBankName')}</Label>
                    <Input disabled type="text" name="Banque" value={Banque} onChange={this.handleChange} class="form-control" placeholder={t('modalBankName')} />
                  </FormGroup>
                </Col>

                <Col md={6}>
                  <FormGroup>
                    <Label for="iban">{t('modalIban')}</Label>
                    <Input disabled type="text" name="iban" value={iban} onChange={this.handleChange} class="form-control" placeholder={t('modalIban')} />
                  </FormGroup>
                </Col>


              </Row>

              <Row form>

                <Col md={6}>
                  <FormGroup>
                    <Label for="intitule">{t('modalAccountTitulair')}</Label>
                    <Input disabled type="text" name="intitule" value={intitule} onChange={this.handleChange} class="form-control" placeholder={t('modalAccountTitulair')} />
                  </FormGroup>
                </Col>

                {/*<Col md={6}>
                  <FormGroup>
                    <Label for="compte">{t('modalAccountNumber')}</Label>
                    <Input disabled type="text" name="compte" value={compte} onChange={this.handleChange} class="form-control" placeholder={t('modalAccountNumber')} />
                  </FormGroup>
                </Col>*/}
                <Col md={6}>
                  <FormGroup>
                    <Label for="montant">{t('modalPrice')}</Label>
                    <Input disabled type="number" name="montant" value={montant} onChange={this.handleChange} class="form-control" placeholder={t('modalPrice')} />
                  </FormGroup>
                </Col>

              </Row>

            </Form>

          </ModalBody>
          <ModalFooter>
            <div className="text-right">
              {/*commande && commande.etatCmde === "EN COURS" &&
                <Button color="primary" onClick={this.addTransfert}>{t('modalTransfertButton')}</Button>
            */}
              <Button className="ml-2" color="secondary" onClick={this.handleDetailClose}>{t('modalCloseButton')}</Button>
            </div>


          </ModalFooter>
        </Modal>


        <Dialog
          open={open}
          onClose={this.handleCloseDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title" style={{ color: 'blue' }}>{t('dash.modal.validationOperation')}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">

              {/* <p style={{ display: 'flex', color: 'red' }}>
                NB: Veuillez contactez notre compte Whatsapp +33 7 55 82 79 19 pour finaliser votre processus de Retrait.  Réfférence: REF-{userInfo.username}
              </p>
            */}

              <Row>
                <Col>
                  {ProgressVisible === true &&
                    <Row className='mt-2 mb-2'>
                      <Col md={12}>
                        <ProgressBar />

                      </Col>
                    </Row>
                  }
                  {ProgressVisible2 === true &&
                    <Row className='mt-2 mb-2'>
                      <Col md={12}>
                        <ProgressBar2 />

                      </Col>
                    </Row>
                  }
                  {ProgressVisible3 === true &&
                    <Row className='mt-2 mb-2'>
                      <Col md={12}>
                        <ProgressBar3 />

                      </Col>
                    </Row>
                  }
                </Col>
              </Row>

              {echecMessage === true &&
                <form>
                  <Row className='mb-2'>
                    <Col md={12}>

                      <h6 style={{ display: 'flex', color: 'red' }}>{t('dash.modal.errorMsgTransfert')}</h6>

                    </Col>

                    <Col md={12}>
                      {
                        (errorCodeTrasfertInput === true) &&
                        <Alert color="danger">
                          {t('text.errorCodeTrasfertInput')}

                        </Alert>
                      }
                    </Col>

                    <Col md={12}>
                      <TextField
                        label={t('dash.modal.tranfertCode')}
                        id="codeTransfert1"
                        name="codeTransfert1"
                        value={codeTransfert1}
                        onChange={this.handleChange}
                        className="w-100 mt-2 mb-2"

                      />
                    </Col>
                  </Row>

                  <Row className='mb-2'>
                    <Col>
                      {echecMessage === true &&
                        <h6>{t('dash.modal.errorMsgTransfertNb')}</h6>
                      }
                    </Col>

                  </Row>

                  <Row>
                    <Col md={12}>
                      <ModalFooter>
                        <Button color="primary" variant="contained" onClick={this.verify1}>{t('table.boutton.enregistrer')}</Button>
                        <Button color="secondary" variant="contained" onClick={this.handleCloseDialog}>{t('table.boutton.annuler')}</Button>

                      </ModalFooter>
                    </Col>
                  </Row>

                </form>
              }

              {echecMessage2 === true &&
                <form>
                  <Row className='mb-2'>
                    <Col md={12}>

                      <h6 style={{ display: 'flex', color: 'red' }}>{t('dash.modal.errorMsgTransfert')}</h6>

                    </Col>

                    <Col md={12}>
                      {
                        (errorCodeTrasfertInput === true) &&
                        <Alert color="danger">
                          {t('text.errorCodeTrasfertInput')}

                        </Alert>
                      }
                    </Col>

                    <Col md={12}>
                      <TextField
                        label={t('dash.modal.tranfertCode2')}
                        id="codeTransfert2"
                        name="codeTransfert2"
                        value={codeTransfert2}
                        onChange={this.handleChange}
                        className="w-100 mt-2 mb-2"

                      />
                    </Col>

                  </Row>


                  <Row>
                    <Col>
                      {echecMessage2 === true &&
                        <h6>{t('dash.modal.errorMsgTransfertNb')}</h6>
                      }
                    </Col>

                  </Row>

                  <Row>
                    <Col md={12}>
                      <ModalFooter>
                        <Button color="primary" variant="contained" onClick={this.verify2}>{t('table.boutton.enregistrer')}</Button>
                        <Button color="secondary" variant="contained" onClick={this.handleCloseDialog}>{t('table.boutton.annuler')}</Button>

                      </ModalFooter>
                    </Col>
                  </Row>
                </form>
              }

              {echecMessage3 === true &&
                <form>
                  <Row>
                    <Col md={12}>

                      <h6 style={{ display: 'flex', color: 'red' }}>{t('dash.modal.errorMsgTransfert')}</h6>

                    </Col>

                    <Col md={12}>
                      {
                        (errorCodeTrasfertInput === true) &&
                        <Alert color="danger">
                          {t('text.errorCodeTrasfertInput')}

                        </Alert>
                      }
                    </Col>

                    <Col md={12}>
                      <TextField
                        label={t('dash.modal.tranfertCode3')}
                        id="codeTransfert3"
                        name="codeTransfert3"
                        value={codeTransfert3}
                        onChange={this.handleChange}
                        className="w-100 mt-2 mb-2"

                      />
                    </Col>
                  </Row>


                  <Row>
                    <Col>
                      {echecMessage3 === true &&
                        <h6>{t('dash.modal.errorMsgTransfertNb')}</h6>
                      }
                    </Col>

                  </Row>

                  <Row>
                    <Col md={12}>
                      <ModalFooter>
                        <Button color="primary" variant="contained" onClick={this.verify3}>{t('table.boutton.enregistrer')}</Button>
                        <Button color="secondary" variant="contained" onClick={this.handleCloseDialog}>{t('table.boutton.annuler')}</Button>

                      </ModalFooter>
                    </Col>
                  </Row>
                </form>
              }



            </DialogContentText>
          </DialogContent>
          <DialogActions>

          </DialogActions>
        </Dialog>

        <Snackbar
          open={alertSuccessMsg}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}

        >
          <Alert severity="success">
            {successMsg}
          </Alert>
        </Snackbar>

        <Snackbar
          open={alertErrMsg}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}

        >
          <Alert severity="error">
            {errMsg}
          </Alert>
        </Snackbar>

        <Snackbar
          open={alertInfoMsg}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <Alert severity="info">
            {infoMsg}
          </Alert>
        </Snackbar>

        <Snackbar
          open={alertWarningMsg}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <Alert severity="warning">
            {warningMsg}
          </Alert>
        </Snackbar>


      </>


    );
  }
}

export default withTranslation()(Transfert);
