import React  from 'react'
import SectionsTitle from '../common/SectionsTitle'
import Teammembers from "./Teammembers";

import { useTranslation } from "react-i18next";


export default function Team({secTitle, secSubtitle, teammembers}) {

    const { t, i18n } = useTranslation()

    return (
        <section className="team-wrapper">
            <div className="container">
                <div className="text-center">
                    <div className="sec-heading">
                <p className="sec__meta">{t('teamSectSub')}</p>
                <h2 className="sec__title">
                    {t('teamSectTitle')}
                </h2>
            </div>
                </div>
                <div className="team-grid text-center">

                    <Teammembers teammembers={teammembers} />

                </div>
            </div>
        </section>
    )
}
