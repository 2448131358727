
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { FiChevronRight, FiPlay } from 'react-icons/fi'
import about_video_img from '../../assets/images/img1.jpg'
import history_img from '../../assets/images/img2.jpg'
import ModalVideo from 'react-modal-video'

//import { useTranslation } from "react-i18next";

import ContenuAbout1 from './ContenuAbout1'

export default class About1 extends Component {
    
    constructor() {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal2 = this.openModal2.bind(this)
    }

    openModal2() {
        this.setState({ isOpen: true })
    }


 
    render() {
        
        return (

                <ContenuAbout1/>

                    
                )
    }
}
