import React from "react";

import { useTranslation } from "react-i18next";

export default function FooterSchedule({title, offday, schedulelists}) {

    const { t, i18n } = useTranslation()

    const ftSchedule= {
                title: t('widgetTitle3'),
                offday: t('widget3offday'),
                litss: [
                    {
                        day: t('listDay1'),
                        time: '8am-6pm'
                    },
                    {
                        day: t('listDay2'),
                        time: '8am-6pm'
                    },
                    {
                        day: t('listDay3'),
                        time: '8am-6pm'
                    },
                    {
                        day: t('listDay4'),
                        time: '8am-6pm'
                    },
                    {
                        day: t('listDay5'),
                        time: '8am-6pm'
                    }
                ]
            }

    return (
        <div className="footer-widget footer-working-hours">
            <h2>{ftSchedule.title}</h2>
            <ul className="working-hours">
                {ftSchedule.litss.map((item, i) => {
                    return (
                        <li key={i}><strong>{item.day}</strong> {item.time}</li>
                    )
                })}
                {ftSchedule.offday?
                    <li className="off-day">{ftSchedule.offday} <strong>{t('off')}</strong></li>
                : ''}
            </ul>
        </div>
    )
}
