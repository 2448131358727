import React  from 'react'
import { Link } from 'react-router-dom'
import { FiChevronRight } from "react-icons/fi";

import { useTranslation } from "react-i18next";

export default function Cta() {

    const { t, i18n } = useTranslation()

    return (
        <section className="cta-wrapper">
            <div className="container">
                <div className="row">
                    <div className="col-8">
                        <h2 style={{color:'white'}}>{t('ctaText1')} <strong>{t('ctaText2')}</strong> {t('ctaText3')}</h2>
                    </div>
                    <div className="col-4 text-right">
                        <Link to="/contact" className="theme-button">{t('btnContactHome')} <FiChevronRight className="icon" /></Link>
                    </div>
                </div>
            </div>
        </section>
    )
}
