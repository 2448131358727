import React , {useState} from 'react'
import SectionsTitle from '../common/SectionsTitle'
import { FiChevronRight } from 'react-icons/fi'
import ContactSidebar from './ContactSidebar'
import Maps from '../contact/Maps'
import CtaNewsletter from "../../sections/CtaNewsletter";
import sectiondata from "../../store/store";
import SocialProfile from "../widgets/SocialProfile";

import { useTranslation } from "react-i18next";
 
import SweetAlert from 'sweetalert2';
import emailjs from 'emailjs-com';

import {Button} from 'reactstrap'


export default function ContactDetails() {

    const { t, i18n } = useTranslation()

    const [fullName, setFullName] = useState("")
    const [email, setEmail] = useState("")
    const [subject, setSubject] = useState("")
    const [message, setMessage] = useState("")
    const [telephone, setTelephone] = useState("")



    const handleChangeFullname = (e) => {
        setFullName(e.target.value)
      }

      const handleChangeEmail = (e) => {
        setEmail(e.target.value)
      }

      const handleChangeSubject = (e) => {
        setSubject(e.target.value)
      }

      const handleChangeMessage = (e) => {
        setMessage(e.target.value)
      }

      const handleChangeTelephone = (e) => {
        setTelephone(e.target.value)
      }

      const sendMailValidate = () => {
       

        if (fullName === '') {
            SweetAlert.fire(
                'Oppss.. !',
                `${t('errMiisVar')} ${t('ctaName')}`,
                'error'
              )
            
            return;
        }
        if (email === '') {
            SweetAlert.fire(
                'Oppss.. !',
                `${t('errMiisVar')} ${t('ctaMail')}`,
                'error'
              )
            return;
        }
        if (message === '') {
            SweetAlert.fire(
                'Oppss.. !',
                `${t('errMiisVar')} ${t('ctaMessage')}`,
                'error'
              )
            return;
        }
        

        const templateParams  = {
            fullName: fullName,
            email: email,
            subject: subject,
            message: message,
            telephone: telephone,
            
        };

        //console.log('PARAMETRE', templateParams )
        /*this.setState({
            //SpinnetBoutton: true,
            validateButton: false,
        })*/


        emailjs.send('service_px87e1v', 'template_0hqzf2d', templateParams , 'user_UmRev6ISrgsE444vWuWsA')
            .then((result) => {
                console.log(result.text, result)

                SweetAlert.fire(
                    'Oppss.. !',
                    `${t('successMsgSend')}`,
                    'success'
                  )

                  setEmail("")
                  setFullName("")
                  setMessage("")
                  setSubject("")
                
            }, (error) => {
                console.log(error.text);
            }); 
            /*setTimeout(() => {
                this.setState({ currentStep: 2, validateButton: true });
            }, 4000);*/
    }

    return (
        <section className="contact-form-wrapper">
            <div className="container">
                <div className="contact-form-grid d-grid">
                    <div className="left">
                        <div className="sec-heading">
                <p className="sec__meta">{t('contactSectSub')}</p>
                <h2 className="sec__title">
                    {t('contactSectTitle')}
                </h2>
            </div>
                        <p>{t('contactSectContent')}</p>

                        <div className="sidebar-social-profile">
                            <SocialProfile lists={sectiondata.contactdata.socials} />
                        </div>
                    </div>
                    {/*ISOLE CETTE PARTIE DANS UN NOUVEAU COMPOSANT*/}
                    <div className="right">
                        <form>
                            <div className="form-grid d-grid">
                                <div className="left">
                                    <label htmlFor="name">{t('ctaName')} <sup>*</sup></label>
                                    <input value={fullName} onChange={(e) => handleChangeFullname(e)} type="text" id="name" placeholder={t('ctaName')} required />
                                </div>
                                <div className="right">
                                    <label htmlFor="email">{t('ctaMail')} <sup>*</sup></label>
                                    <input  value={email} onChange={(e) => handleChangeEmail(e)} type="email" id="email" placeholder={t('ctaMail')} required />
                                </div>
                                <div className="left">
                                    <label htmlFor="number">{t('ctaNumetoText')} <sup>*</sup></label>
                                    <input value={telephone} onChange={(e) => handleChangeTelephone(e)} type="text" id="number" placeholder={t('ctaNumetoText')} required />
                                </div>
                                <div className="right">
                                    <label htmlFor="subject">{t('ctaSubjetct')} <sup>*</sup></label>
                                    <input value={subject} onChange={(e) => handleChangeSubject(e)} type="text" id="subject" placeholder={t('ctaSubjetct')} required />
                                </div>
                            </div>
                            <div className="form-textarea">
                                <label htmlFor="message">{t('ctaMessage')} <sup>*</sup></label>
                                <textarea value={message} onChange={(e) => handleChangeMessage(e)} id="message" placeholder={t('ctaMessageWritte')}></textarea>
                            </div>
                            <Button onClick={sendMailValidate} className="theme-button">{t('ctaButton')} <FiChevronRight className="icon" /></Button>
                        </form>
                    </div>
                </div>

                <div className="contact-form-map-grid d-grid">
                    <div className="left">
                       {/* <Maps />*/}
                    </div>
                    <div className="right">
                        <ContactSidebar />
                    </div>
                </div>

                <CtaNewsletter title={t('CtaNewsletterTitle')} content={t('CtaNewsletterContent')} />
            </div>
        </section>
    )
}
