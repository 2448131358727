import React from "react";
import SectionsTitle from "../components/common/SectionsTitle";
import Testimonial from "../components/sliders/Testimonial";

import { useTranslation } from "react-i18next";

export default function TestimonialSection({secTitle, secSubtitle, sliders, imgshapes}) {
    const { t, i18n } = useTranslation()

    return (
        <section className="testimonial-wrapper ">
            <div className="text-center">
                <div className="sec-heading">
                <p className="sec__meta">{t('testimonialSectSub')}</p>
                <h2 className="sec__title">
                    {t('testimonialSectTitle')}
                </h2>
            </div>
            </div>
            <Testimonial slideitems={sliders} imgshapes={imgshapes} />
        </section>
    )

}