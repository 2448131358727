import { BehaviorSubject } from 'rxjs';
import { handleResponse } from './handle-response';
import {authHeader} from './auth-header'
import {base_url} from '../env'

const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUser')));


export const authenticationService = {
    login,
    logout,
    updatePassword,
    
    currentUser: currentUserSubject.asObservable(),
    get currentUserValue () { return currentUserSubject.value }
};
function login(username, password) {

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, password })
    };
    return fetch(`${base_url}/api/auth/login`, requestOptions)
        .then(handleResponse)
        .then(user => {
            //console.log("Utilisateur Connecté", user)
            localStorage.setItem('currentUser', JSON.stringify(user));
            currentUserSubject.next(user);
            return user;
        });

}


function updatePassword(req) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(req)
    };
    return fetch(`${base_url}/api/v01/auth/change-password`, requestOptions)
        .then(handleResponse);
}


function logout() {
    localStorage.removeItem('currentUser');
    currentUserSubject.next(null);
    window.location.href = `/`

    //window.location.reload()
}
