import React  from 'react'
import { Link } from "react-router-dom";
//import {FiChevronRight} from "react-icons/fi";

import { useTranslation } from "react-i18next";

import {
    FiBarChart,
    FiBarChart2,
    FiBriefcase, FiCheckCircle,
    FiChevronRight,
    FiLock,
    FiMonitor,
    FiTarget,
    FiUserCheck
} from "react-icons/fi";
import { MdAccountBalance } from "react-icons/md";
import {
    AiOutlineCheckCircle,
    AiOutlineDollar,
    AiOutlineFileSearch,
    AiOutlineNotification, AiOutlineRocket,
    AiOutlineUsergroupAdd
} from "react-icons/ai";
import { IoIosBuild, IoIosHammer, IoIosNotificationsOutline } from "react-icons/io";


export default function Service({sLists}) {

    const { t, i18n } = useTranslation();


    const serviceLists= [
                    {
                        icon: <FiTarget className="service-items-icon" />,
                        title: t('serviceTitle1'),
                        titleurl: '/service-details',
                        description: t('serviceDescription1'),
                        showbtn: false,
                        buttontext: 'Learn More',
                        buttonurl: '/service-details',
                    },
                    {
                        icon: <MdAccountBalance className="service-items-icon" />,
                        title: t('serviceTitle2'),
                        titleurl: '/service-details',
                        description: t('serviceDescription2'),
                        showbtn: false,
                        buttontext: 'Learn More',
                        buttonurl: '/service-details',
                    },
                    {
                        icon: <AiOutlineDollar className="service-items-icon" />,
                        title: t('serviceTitle3'),
                        titleurl: '/service-details',
                        description: t('serviceDescription3'),
                        showbtn: false,
                        buttontext: 'Learn More',
                        buttonurl: '/service-details',
                    },
                    {
                        icon: <AiOutlineFileSearch className="service-items-icon" />,
                        title: t('serviceTitle4'),
                        titleurl: '/service-details',
                        description: t('serviceDescription4'),
                        showbtn: false,
                        buttontext: 'Learn More',
                        buttonurl: '/service-details',
                    },
                    {
                        icon: <IoIosBuild className="service-items-icon" />,
                        title: t('serviceTitle5'),
                        titleurl: '/service-details',
                        description: t('serviceDescription5'),
                        showbtn: false,
                        buttontext: 'Learn More',
                        buttonurl: '/service-details',
                    },
                    {
                        icon: <FiMonitor className="service-items-icon" />,
                        title: t('serviceTitle6'),
                        titleurl: '/service-details',
                        description: t('serviceDescription6'),
                        showbtn: false,
                        buttontext: 'Learn More',
                        buttonurl: '/service-details',
                    }
                ]
        return (
            <>
                {
                    serviceLists.map((item, index) => {
                        return <div className="service-items" key={index}>
                            <span className="service-items-num">{index}</span>
                            <span className="service-items-iconbox">{item.icon}</span>
                            <Link to={item.title}>
                                <h3 className="service-items-title">{item.title}</h3>
                            </Link>
                            <p className="service-items-description">{item.description}</p>
                            {item.showbtn?
                                <Link to={item.buttonurl} className="simple-button">{item.buttontext} <FiChevronRight className="icon" /></Link>
                            : ''}
                        </div>
                    }) 
                }
            </>
        )
}

