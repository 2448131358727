import React from "react";

import { useTranslation } from "react-i18next";
import {Link} from 'react-router-dom'


export default function FooterCommonLinks({title, lists}) {

    const { t, i18n } = useTranslation()

    const ftCommonLinks= {
                title: t('widgetTitle2'),
                lists: [
                    {
                        title: t('widgetListTitle2'),
                        url: '/about'
                    },
                    /*{
                        title: t('widgetListTitle1'),
                        url: '/services'
                    },*/
                    
                    {
                        title: t('widgetListTitle3'),
                        url: '/services'
                    },
                    {
                        title: t('menuTeam'),
                        url: '/team-grid'
                    },
                    {
                        title: t('menuTestimonial'),
                        url: '/testimonial'
                    },
                    {
                        title: t('menuFaq'),
                        url: '/faq'
                    },
                    {
                        title: t('widgetListTitle7'),
                        url: '/contact'
                    },
                    {
                        title: t('menuDemande'),
                        url: '/services'
                    }

                    /*{
                        title: t('widgetListTitle4'),
                        url: '/'
                    },*/
                    /*{
                        title: t('widgetListTitle5'),
                        url: '/contact'
                    },
                    {
                        title: t('widgetListTitle6'),
                        url: '/'
                    },*/
                    
                ]
            }

    return (
        <div className="footer-widget footer-help-links">
            {ftCommonLinks.title?
                <h2>{ftCommonLinks.title}</h2>
            : ''}
            {ftCommonLinks.lists?
                <ul className="help-links">
                    {ftCommonLinks.lists.map((item, i) => {
                        return (
                            <li key={i}>
                                <Link to={item.url}>{item.title}</Link>
                            </li>
                        )
                    })}
                </ul>
            : ''}
        </div>
    )
}
