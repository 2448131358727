import i18n from "i18next";
import { initReactI18next } from "react-i18next";
//import { BehaviorSubject } from 'rxjs';



// Importing translation files

import translationEN from "./locales/en/translation.json";
import translationFR from "./locales/fr/translation.json";
import translationES from "./locales/es/translation.json";
import translationDE from "./locales/de/translation.json"; 

import translationIT from "./locales/it/translation.json";
import translationNE from "./locales/ne/translation.json";
import translationFI from "./locales/fi/translation.json";
import translationPO from "./locales/po/translation.json";



// recupperer le language dans le local stockage
const currentUserLanguage = JSON.parse(localStorage.getItem('languageValue'));

//Creating object with the variables of imported translation files
const resources = {
  en: {
    translation: translationEN,
  },
  fr: {
    translation: translationFR,
  },
  es: {
    translation: translationES,
  },
  de: {
    translation: translationDE,
  },
  it: {
    translation: translationIT,
  },
  ne: {
    translation: translationNE,
  },
  fi: {
    translation: translationFI,
  },
  po: {
    translation: translationPO,
  },
};

//i18N Initialization

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: currentUserLanguage || "de", //default language
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;

/*import i18n from 'i18next'
import Backend from 'i18next-xhr-backend'
import { initReactI18next } from 'react-i18next'

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: 'en',
    backend: {
      /* translation file path /
      loadPath: '/assets/i18n/{{ns}}/{{lng}}.json'
    },
    fallbackLng: 'en',
    debug: true,
    /* can have multiple namespace, in case you want to divide a huge translation into smaller pieces and load them on demand /
    ns: ['translations'],
    defaultNS: 'translations',
    keySeparator: false,
    interpolation: {
      escapeValue: false,
      formatSeparator: ','
    },
    react: {
      wait: true
    }
  })

export default i18n*/