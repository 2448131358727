import React from "react";
import { Switch, Route } from 'react-router-dom'
import Home from './pages/Home'
import HomeV2 from './pages/HomeV2'
import ShopHomePage from './pages/ShopHomePage'
import Shop from './pages/Shop'
import ProductDetailsPage from "./pages/ProductDetailsPage";
import CartPage from "./pages/CartPage";
import CheckoutPage from "./pages/CheckoutPage";
import About_Page from './pages/About_Page'
import TeamGrid from './pages/TeamGrid'
import TeamDetails from './pages/TeamDetails'
import TestimonialPage from './pages/TestimonialPage'
import FAQ from './pages/FAQ'
import OurPricing from './pages/PricingPage'
import SignUp from './pages/SignUpPage'
import Login from './pages/LoginPage'
import ForgotPassword from './pages/ForgotPassword'
import ServicePage from './pages/ServicePage'
import ServiceDetailsPage from './pages/ServiceDetailsPage'
import Portfolio from './pages/PortfolioPage'
import PortfolioDetailsPage from './pages/PortfolioDetailsPage'
import BlogFullPage from './pages/BlogFullPage'
import BlogGridPage from './pages/BlogGridPage'
import BlogDetailsPage from './pages/BlogDetailsPage'
import ContactPage from './pages/ContactPage'
import Dashboard from './pages/Dashboard'
import Demande from './pages/DemandePage'
import Error from './pages/Error'
import "./assets/css/style.css";
import "./App.css";

import PrivateRoute from './PrivateRoute'

function App() {
  return (
    <React.Fragment>
      <Route>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/demande" component={Demande} />

          <Route path="/home-v2" component={HomeV2} />
          <Route path="/shop-home" component={ShopHomePage} />
          <Route path="/shop" component={Shop} />
          <PrivateRoute path="/user" component={Dashboard} />

          <Route path="/product-details" component={ProductDetailsPage} />
          <Route path="/cart" component={CartPage} />
          <Route path="/checkout" component={CheckoutPage} />
          <Route path="/about" component={About_Page} />
          <Route path="/team-grid" component={TeamGrid} />
          <Route path="/team-details" component={TeamDetails} />
          <Route path="/testimonial" component={TestimonialPage} />
          <Route path="/faq" component={FAQ} />
          <Route path="/our-pricing" component={OurPricing} />
          <Route path="/sign-up" component={SignUp} />
          <Route path="/login" component={Login} />
          <Route path="/forgot-password" component={ForgotPassword} />
          <Route path="/services" component={ServicePage} />
          <Route path="/service-details" component={ServiceDetailsPage} />
          <Route path="/portfolio-grid" component={Portfolio} />
          <Route path="/portfolio-details" component={PortfolioDetailsPage} />
          <Route path="/blog-full-width" component={BlogFullPage} />
          <Route path="/blog-grid" component={BlogGridPage} />
          <Route path="/blog-details" component={BlogDetailsPage} />
          <Route path="/contact" component={ContactPage} />
          <Route component={Error} />
        </Switch>
      </Route>
    </React.Fragment>
  );
}

export default App;
