import React  from 'react'
import Navbar from "../components/common/Navbar";
import Breadcrumb from '../components/common/Breadcrumb';
import Contain from '../components/dashboard/Contain';
import Footer from '../components/other/Footer';

//import { useTranslation } from "react-i18next";


export default function LoginPage() {

    //const { t, i18n } = useTranslation()

    return (
        <>
            {/* Header */}
            <header className="header-area">
                <Navbar />
            </header>

            {/* Breadcrumb 
            <Breadcrumb title={t('menuConnexion')} />*/}

            {/* Contenu*/}
            <Contain />

            {/* Footer */}
            <Footer />
        </>
    )

}
