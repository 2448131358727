import React from 'react';
//import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import {Card, CardBody} from 'reactstrap'

//import Box from '@material-ui/core/Box';

/*const useStyles = makeStyles({
  root: {
    width: '100%',
  },
});*/


function LinearProgressWithLabel(props) {
  return (
    <div display="flex" alignItems="center">
      <div width="100%" mr={1}>
        <LinearProgress variant="determinate" {...props} />
      </div>
      <div minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </div>
    </div>
  );
}

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};


export default function LinearBuffer() {
  //const classes = useStyles();
  const [progress, setProgress] = React.useState(0);
  const [buffer, setBuffer] = React.useState(10);

  const progressRef = React.useRef(() => { });
  React.useEffect(() => {
    progressRef.current = () => {
      if (progress > 80) {
        setProgress(75);
        setBuffer(80);
      } else {
        const diff = Math.random() * 10;
        const diff2 = Math.random() * 10;
        setProgress(progress + diff);
        setBuffer(progress + diff + diff2);
      }
    };
  });

  React.useEffect(() => {
    const timer = setInterval(() => {
      progressRef.current();
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    
      <LinearProgressWithLabel variant="buffer" value={progress} valueBuffer={buffer} />

    

  );
}