import React  from 'react'
import OwlCarousel from 'react-owl-carousel';
import '../assets/css/owl.carousel.css';
import '../assets/css/owl.theme.default.min.css';

import img1 from '../assets/images/client6.png'; // 360x370
import img2 from '../assets/images/client7.png'; // 360x370
import img3 from '../assets/images/client8.png'; // 360x370
import img4 from '../assets/images/client9.png'; // 360x370
import img5 from '../assets/images/client10.png'; // 360x370

const responsive = {
    0: {
        items: 1,
    },
    450: {
        items: 2
    },
    600: {
        items: 3
    },
    1000: {
        items: 5
    }
}

const clientlogos= [
    {
        img: img1
    },
    {
        img: img2
    },
    {
        img: img3
    },
    {
        img: img4
    },
    {
        img: img5
    }
]


export default function Clients({sliders}) {
    return (
        <section className="clients-wrapper">
            <div className="container">
                <OwlCarousel
                    className="owl-theme"
                    loop={true}
                    autoplay={true}
                    margin={10}
                    dots={false}
                    nav={false}
                    responsive={responsive}
                >
                    {clientlogos.map((item, index) => {
                        return <div className="items text-center" key={index}>
                            <img src={item.img} alt="Clients" />
                        </div>
                    })}
                </OwlCarousel>
            </div>
        </section>
    )
}