import React, { useState, useEffect } from 'react'
import { FaGoogle, FaFacebookF, FaTwitter } from 'react-icons/fa'
import { FiChevronRight } from 'react-icons/fi'
import { Link } from 'react-router-dom'

import { useTranslation } from "react-i18next";
import { services } from '../../services/services';

import SweetAlert from 'sweetalert2';
import { CountryDropdown,/* RegionDropdown, CountryRegionData*/ } from 'react-country-region-selector';
//import { authenticationService } from "../../services/authentication-service";

import { Button, Form } from 'reactstrap'

export default function SignUpForm() {



  const { t, i18n } = useTranslation()

  const [password, setPassword] = useState("")
  const [username, setUsername] = useState("")

  const [loading, setLoading] = useState(false)

 // const [typeG, setTypG] = useState(t('defaultTypeCompte'))

  const [civilite, setCivilite] = useState('')

  const [typeCompte, setTypeCompte]= useState(`${t('defaultTypeCompte')}`)

  const [firstName, setFirstName] = useState('')

  const [lastName, setLastName] = useState('')

  const [adresse, setAdresse] = useState('')

  const [ville, setVille] = useState('')

  const [pays, setPays] = useState('')

  const [telephone, setTelephone] = useState('')

  const [numeroPiece, setNumeroPiece] = useState('')

  const [devise, setDevise] = useState('€')

  const [email, setEmail] = useState('')


  const selectCountry = (val) => {
    //console.log('VAL', val)
    setPays(val);
  }

  const handleChangeTypeCompte = (e) => {
    setTypeCompte(e.target.value)
  }

  const handleChangeCivilite = (e) => {
    setCivilite(e.target.value)
  }

  const handleChangeFirstName = (e) => {
    setFirstName(e.target.value)
  }

  const handleChangeLastName = (e) => {
    setLastName(e.target.value)
  }

  const handleChangeAdresse = (e) => {
    setAdresse(e.target.value)
  }

  const handleChangeVille = (e) => {
    setVille(e.target.value)
  }

  const handleChangePays = (e) => {
    setPays(e.target.value)
  }

  const handleChangeTelephone = (e) => {
    setTelephone(e.target.value)
  }

  const handleChangeNumeroPiece = (e) => {
    setNumeroPiece(e.target.value)
  }

  const handleChangeDevise = (e) => {
    setDevise(e.target.value)
  }

  const handleChangeEmail = (e) => {
    setEmail(e.target.value)
  }

  const handleChangeUsername = (e) => {
    setUsername(e.target.value)
  }

  const handleChangePassword = (e) => {
    setPassword(e.target.value)
  }



  const handleSubmit = () => {

    if (lastName === '') {
      SweetAlert.fire(
        'Oppss.. !',
        t('alertUpdateLastName'),
        'error'
      )
      return;
    }

    if (firstName === '') {
      SweetAlert.fire(
        'Oppss.. !',
        t('alertUpdateFirstName'),
        'error'
      )
      return;
    }

    if (username === '') {
      SweetAlert.fire(
        'Oppss.. !',
        `${t('errMiisVar')} ${t('menuUser')}`,
        'error'
      )
      return;
    }

    if (password === '') {
      SweetAlert.fire(
        'Oppss.. !',
        `${t('errMiisVar')} ${t('formPassword')}`,
        'error'
      )
      return;
    }

    if (email === '') {
      SweetAlert.fire(
        'Oppss.. !',
        t('alertUpdateEmail'),
        'error'
      )
      return;
    }

    setLoading(true)

    //const {username, password} = this.state
    let req = {
      username,
      password,
      firstName,
      lastName,
      email,
      devise,
      numeroPiece,
      telephone,
      pays,
      ville,
      adresse,
      civilite,
      typeCompte,
    }
    //console.log('USER1', req)
    services.addUser(req)
      .then(user => {
    //console.log('USER2', user)


        //console.log('user result', user)
        SweetAlert.fire(
          { title: t('userSuccessPasswordTilte'), text: t('userSuccessAccount'), icon: "success" }
        )

        setTypeCompte('')
        setCivilite('')
        setAdresse('')

        setVille('')

        setPays('')

        setTelephone('')

        setNumeroPiece('')

        setDevise('')

        setEmail('')

        setLastName('')

        setFirstName('')
        setUsername('')
        setPassword('')


        setTimeout(() => {
          window.location.href = `/`

          //props.history.push(`/shop/`);

          //this.props.history.push(`/shop`);
        }, 6000);

      }) 
      .catch(err => {
        console.log('ERR', err)
        setLoading(false)
        //toast.error("Oppss.. Le mot de passe est invalide ou l'utilisateur n'existe pas.");
        if (err === 'Cet utilisateur existe déjà') {
          SweetAlert.fire(
            'Oppss.. !',
            t('errUtilEx'),
            'error'
          )
          return;
        }

        SweetAlert.fire(
          'Oppss.. !',
          t('errMsg.UpdateUser'),
          'error'
        )
       console.log('USER3', err)


      })


  };



  return (
    <section className="signup-form-wrapper">
      <div className="container">
        <div className="signup-form-box">
          <div className="signup-form-top text-center">
            <h1 className="signup-form-title">{t('createAcountText')}</h1>
            {/*<p>with your social network.</p>*/}
          </div>
          {/* <div className="signup-form-signin d-flex">
                        <button className="theme-button google" type="submit">
                            Google <FaGoogle className="icon" />
                        </button>
                        <button className="theme-button facebook" type="submit">
                            Facebook <FaFacebookF className="icon" />
                        </button>
                        <button className="theme-button twitter" type="submit">
                            Twitter <FaTwitter className="icon" />
                        </button>
                    </div>*/}
          <div className="connector text-center">
            <span><i className="fa fa-lock"></i></span>
          </div>
          <Form className="signup-form">

            <select className="form-select form-select-sm w-100" aria-label=".form-select-sm example" value={typeCompte} onChange={(e) => handleChangeTypeCompte(e)} placeholder={t('formTypeAccount')} >
              <option selected value={typeCompte}>{t('defaultTypeCompte')}</option>
            </select>

            <select className="form-select mt-2 mb-2 form-select-sm w-100" aria-label=".form-select-sm example" value={civilite} onChange={(e) => handleChangeCivilite(e)} >
              <option value="" selected>{t('formGender')}</option>
              <option value="Mr">{t('civiliteMr')}</option>
              <option value="Mme">{t('civiliteMme')}</option>

            </select>
            {/*<input className="form-control" value={civilite} onChange={(e) => handleChangeCivilite(e)} type="text" placeholder={t('formGender')} />
*/}

            <input className="form-control" value={firstName} onChange={(e) => handleChangeFirstName(e)} type="text" placeholder={t('formFirstName')} />
            <input className="form-control" value={lastName} onChange={(e) => handleChangeLastName(e)} type="text" placeholder={t('formLastName')} />

            <input className="form-control" value={adresse} onChange={(e) => handleChangeAdresse(e)} type="text" placeholder={t('formAddress')} />
            <input className="form-control" value={ville} onChange={(e) => handleChangeVille(e)} type="text" placeholder={t('formCity')} />

            <CountryDropdown
              value={pays}
              onChange={(val) => selectCountry(val)}
              labelType="full"
              style={{ width: '100%' }}
              defaultOptionLabel={t('formCountry')}
              className="mb-2"

            />
            {/*<input className="form-control" value={pays} onChange={(e) => handleChangePays(e)} type="text" placeholder={t('formCountry')} />
            */}
            <input className="form-control" value={telephone} onChange={(e) => handleChangeTelephone(e)} type="text" placeholder={t('formPhone')} />
            <input className="form-control" value={numeroPiece} onChange={(e) => handleChangeNumeroPiece(e)} type="text" placeholder={t('formNumberPiec')} />

            <select className="form-select mt-2 mb-2 form-select-sm w-100" aria-label=".form-select-sm example" value={devise} onChange={(e) => handleChangeDevise(e)} >
              <option value="" >{t('formDevi')}</option>
              <option value="€" selected>€</option>
              <option value="$">$</option>

            </select>
            {/*<input className="form-control" value={devise} onChange={(e) => handleChangeDevise(e)} type="text" placeholder={t('formDevi')} />
*/}
            <input className="form-control" value={email} onChange={(e) => handleChangeEmail(e)} type="email" placeholder={t('formEmail')} />
            <input className="form-control" value={username} onChange={(e) => handleChangeUsername(e)} type="text" placeholder={t('menuUser')} />
            <input className="form-control" value={password} onChange={(e) => handleChangePassword(e)} type="password" placeholder={t('formPassword')} />

            {/*<input className="form-control" type="password" placeholder="Password" />
                        <input className="form-control" type="password" placeholder="Confirm Password" />
                        */}
            <div className="checkboxes">
              <label className="privacy-policy"> {t('formAgree')} <a href="#">{t('formPrivacyPolicy')}</a>
                <input type="checkbox" />
                <span className="checkmark"></span>
              </label>
              <label className="privacy-policy"> {t('formAgree')} <a href="#">{t('formTermsOfService')}</a>
                <input type="checkbox" />
                <span className="checkmark"></span>
              </label>
            </div>
            <Button onClick={handleSubmit} className="theme-button" disabled={loading ? loading : loading}>{loading ? t('formBtnRegister') : t('formBtnRegister')}  <FiChevronRight className="icon" /></Button>
          </Form>
          <p className="already-account">
            {t('formHaveAccount')} <Link to="/login">{t('formLogin')}</Link>
          </p>
        </div>
      </div>
    </section>
  )
}
