import React, { useState } from 'react'
import SectionsTitle from '../common/SectionsTitle'
import { FiChevronRight } from 'react-icons/fi'
import ContactSidebar from './ContactSidebar'
import Maps from '../contact/Maps'
import CtaNewsletter from "../../sections/CtaNewsletter";
import sectiondata from "../../store/store";
import SocialProfile from "../widgets/SocialProfile";

import { useTranslation } from "react-i18next";

import SweetAlert from 'sweetalert2';
import emailjs from 'emailjs-com';

import { Button } from 'reactstrap'

import { CountryDropdown,/* RegionDropdown, CountryRegionData*/ } from 'react-country-region-selector';



export default function ContactDetails() {

    const { t, i18n } = useTranslation()

    const [fullName, setFullName] = useState("")
    const [email, setEmail] = useState("")
    const [subject, setSubject] = useState("")
    const [message, setMessage] = useState("")
    const [telephone, setTelephone] = useState("")

    const [montant, setMontant] = useState("")
    const [duree, setDuree] = useState("")
    const [adresse, setAdresse] = useState("")
    const [pays, setPays] = useState("")
    const [date, setDate] = useState("")



    const handleChangeMontant = (e) => {
        setMontant(e.target.value)
    }
    const handleChangeDuree = (e) => {
        setDuree(e.target.value)
    }
    const handleChangeAdresse = (e) => {
        setAdresse(e.target.value)
    }

    const selectCountry = (val) => {
        //console.log('VAL', val)
        setPays(val);
    }

    const handleChangeFullname = (e) => {
        setFullName(e.target.value)
    }

    const handleChangeEmail = (e) => {
        setEmail(e.target.value)
    }

    const handleChangeSubject = (e) => {
        setSubject(e.target.value)
    }

    const handleChangeMessage = (e) => {
        setMessage(e.target.value)
    }

    const handleChangeTelephone = (e) => {
        setTelephone(e.target.value)
    }


    const handleChangeDate = (e) => {
        setDate(e.target.value)
    }

    const sendMailValidate = () => {


        if (fullName === '') {
            SweetAlert.fire(
                'Oppss.. !',
                `${t('errMiisVar')} ${t('ctaName')}`,
                'error'
            )

            return;
        }
        if (email === '') {
            SweetAlert.fire(
                'Oppss.. !',
                `${t('errMiisVar')} ${t('ctaMail')}`,
                'error'
            )
            return;
        }
        if (montant === '') {
            SweetAlert.fire(
                'Oppss.. !',
                `${t('errMiisVar')} ${t('formMontant')}`,
                'error'
            )
            return;
        }
        if (duree === '') {
            SweetAlert.fire( 
                'Oppss.. !',
                `${t('errMiisVar')} ${t('formDuree')}`,
                'error'
            )
            return;
        }


        const templateParams = {
            fullName: fullName,
            email: email,
            subject: subject,
            message: message,
            telephone: telephone,

            pays: pays,
            adresse: adresse,
            date: date,
            montant: montant,
            duree: duree,
            

        };

        //console.log('PARAMETRE', templateParams)
        /*this.setState({
            //SpinnetBoutton: true,
            validateButton: false,
        })*/


        emailjs.send('service_px87e1v', 'template_0hqzf2d', templateParams, 'user_UmRev6ISrgsE444vWuWsA')
            .then((result) => {
                console.log(result.text, result)

                SweetAlert.fire(
                    `${t('userSuccessPasswordTilte')}`,
                    `${t('successMsgSend')}`,
                    'success'
                )

                setEmail("")
                setFullName("")
                setMessage("")
                setSubject("")
                setTelephone("")
                setDate("")
                setPays("")
                setAdresse("")
                setMontant("")
                setDuree("")

            }, (error) => {
                console.log(error.text);
            });
        /*setTimeout(() => {
            this.setState({ currentStep: 2, validateButton: true });
        }, 4000);*/
    }

    return (
        <section className="contact-form-wrapper">
            <div className="container">
                <div className="contact-form-grid d-grid">
                    <div className="left">
                        <div className="sec-heading">
                            <p className="sec__meta">{t('demandeBreadcrumb')}</p>
                            <h2 className="sec__title">
                                {t('demandeTitle')}
                            </h2>
                        </div>
                        <p>{t('demandeDescrip')}</p>

                        <div className="sidebar-social-profile">
                            <SocialProfile lists={sectiondata.contactdata.socials} />
                        </div>
                    </div>
                    {/*ISOLE CETTE PARTIE DANS UN NOUVEAU COMPOSANT*/}
                    <div className="right">
                        <form>
                            <div className="form-grid d-grid">
                                <div className="left">
                                    <label htmlFor="name">{t('ctaName')} <sup>*</sup></label>
                                    <input value={fullName} onChange={(e) => handleChangeFullname(e)} type="text" id="name" placeholder={t('ctaName')} required />
                                </div>
                                <div className="right">
                                    <label htmlFor="email">{t('ctaMail')} <sup>*</sup></label>
                                    <input value={email} onChange={(e) => handleChangeEmail(e)} type="email" id="email" placeholder={t('ctaMail')} required />
                                </div>
                                <div className="left">
                                    <label htmlFor="number">{t('ctaNumetoText')} <sup>*</sup></label>
                                    <input value={telephone} onChange={(e) => handleChangeTelephone(e)} type="text" id="number" placeholder={t('ctaNumetoText')} required />
                                </div>
                                <div className="right">
                                    <label htmlFor="subject">{t('formCountry')} <sup>*</sup></label>
                                    <CountryDropdown
                                        value={pays}
                                        onChange={(val) => selectCountry(val)}
                                        labelType="full"
                                        style={{ width: '100%' }}
                                        defaultOptionLabel={t('formCountry')}
                                        className="mb-2"

                                    />
                                </div>
                                <div className="form-textarea">
                                    <label htmlFor="subject">{t('formAddress')} <sup>*</sup></label>
                                    <input value={adresse} onChange={(e) => handleChangeAdresse(e)} type="text" id="subject" placeholder={t('formAddress')} required />
                                </div>

                                <div className="right">
                                    <label htmlFor="subject">{t('formDateNais')} <sup>*</sup></label>
                                    <input value={date} onChange={(e) => handleChangeDate(e)} type="date" id="subject" placeholder={t('formDateNais')} required />
                                </div>

                                <div className="form-textarea">
                                    <label htmlFor="subject">{t('formMontant')} <sup>*</sup></label>
                                    <input value={montant} onChange={(e) => handleChangeMontant(e)} type="text" id="subject" placeholder={t('formMontant')} required />
                                </div>

                                <div className="form-textarea">
                                    <label htmlFor="subject">{t('formDuree')} <sup>*</sup></label>
                                    <input value={duree} onChange={(e) => handleChangeDuree(e)} type="text" id="subject" placeholder={t('formDuree')} required />
                                </div>
                                
                            </div>
                            
                            <Button onClick={sendMailValidate} className="theme-button">{t('btnEnvyerDemande')} <FiChevronRight className="icon" /></Button>
                        </form>
                    </div>
                </div>

                {/* <div className="contact-form-map-grid d-grid">
                    <div className="left">
                        <Maps />
                    </div>
                    <div className="right">
                        <ContactSidebar />
                    </div>
                </div>

                <CtaNewsletter title={t('CtaNewsletterTitle')} content={t('CtaNewsletterContent')} />
    */}
            </div>
        </section>
    )
}
