import React from "react";

import { useTranslation } from "react-i18next";

export default function SocialProfile({title, lists}) {

    const { t, i18n } = useTranslation()

    return (
        <>
            {title?
                <h2>{t('textFollowSocial')}</h2>
            : ''}
            {lists?
                <ul className="footer-social-connect d-flex">
                    {lists.map((item, i) => {
                        return (
                            <li key={i}>
                                <a href={item.url}>{item.icon}</a>
                            </li>
                        )
                    })}
                </ul>
            : ''}
        </>
    )
}
