import React  from 'react'
import Navbar from "../components/common/Navbar";
import Breadcrumb from '../components/common/Breadcrumb';
import SignUpForm from '../components/other/SignUpForm';
import Footer from '../components/other/Footer';

import { useTranslation } from "react-i18next";

export default function SignUpPage() {

    const { t, i18n } = useTranslation()

    return (
        <>
            {/* Header */}
            <header className="header-area">
                <Navbar />
            </header>

            {/* Breadcrumb */}
            <Breadcrumb title={t('menuRegister')} />

            {/* Signup Form*/}
            <SignUpForm />

            {/* Footer */}
            <Footer />
        </>
    )
}
