import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { FiChevronRight, FiPlay, FiTarget,
    FiUserCheck } from 'react-icons/fi'
import img1 from '../../assets/images/about1.jpg'
import img2 from '../../assets/images/about2.jpg'
import img3 from '../../assets/images/about3.jpg'
import ModalVideo from 'react-modal-video'
//import { Link } from 'react-router-dom'
//import SectionsTitle from '../common/SectionsTitle'
//import { FiChevronRight } from 'react-icons/fi'
//import ModalVideo from 'react-modal-video'
import Progressbar from '../other/Progressbar'

import sectiondata from "../../store/store";
import { TiLightbulb } from "react-icons/ti"; // 750x470


import { useTranslation } from "react-i18next";


const images= [
    img1,
    img2,
    img3
]

export default function ContenuAbout (){
            const { t, i18n } = useTranslation()

            const supports= [
                    {
                        icon: <FiUserCheck />,
                        title: t('aboutSupport1')
                    },
                    {
                        icon: <FiTarget />,
                        title: t('aboutSupport2')
                    },
                    {
                        icon: <TiLightbulb />,
                        title: t('aboutSupport3')
                    }
                ]

            const skills= [
                    {
                        title: t('skillsTitle1'),
                        percent: '90'
                    },
                    {
                        title: t('skillsTitle2'),
                        percent: '85'
                    },
                    {
                        title: t('skillsTitle3'),
                        percent: '80'
                    }
                ]

            return(
                <section className="about-wrapper">
                <div className="container">
                    <div className="about-grid-wrap">
                        <div className="about-left">
                            <div className="sec-heading">
                            <p className="sec__meta">{t('aboutSectSub')}</p>
                            <h2 className="sec__title">
                                {t('aboutSectTitle')}
                            </h2>
                        </div>
                            <p className="about-left-desc">
                                {t('aboutSectDescript')}
                            </p>
                            {t('aboutBtnDemande')?
                                <Link to={'/'}>
                                    {t('aboutBtnDemande')} <FiChevronRight className="icon" />
                                </Link> : ''
                            }

                            <div className="about-iconbox-grid">
                                {supports.map((item, index) => {
                                    return (
                                        <div className="about-iconbox" key={index}>
                                            {item.icon?
                                                <span className="about-iconbox-icon">
                                                    {item.icon}
                                                </span> : ''
                                            }
                                            {item.title?
                                                <h3 className="about-iconbox-title">
                                                    {item.title}
                                                </h3> : ''
                                            }
                                        </div>
                                    )
                                })}
                            </div>
                            <Progressbar items={skills} />
                        </div>
                        <div className="about-right">
                            {images.map((img, index) => {
                                return <img key={index} className={'about_img_' + index} src={img} alt={'About Image ' + index} />
                            })}
                            {sectiondata.Aboutdata.about1.videobtn?
                                (
                                    <>
                                        <ModalVideo channel='youtube' /*isOpen={this.state.isOpen}*/ videoId={sectiondata.Aboutdata.about1.videoId} onClose={() => this.setState({ isOpen: false })} />
                                        <Link to="#" className="video-btn" /*onClick={this.openModal}*/>
                                            {sectiondata.Aboutdata.about1.videobtn}
                                        </Link>
                                    </>
                                ) : ''
                            }
                        </div>
                    </div>
                </div>
            </section>
            )

                    }