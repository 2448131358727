import React, {useState}  from 'react'
import SectionsTitle from '../common/SectionsTitle'
import { FiPhoneCall } from 'react-icons/fi'
import { MdMailOutline } from 'react-icons/md'

import { useTranslation } from "react-i18next";

import SweetAlert from 'sweetalert2';
import emailjs from 'emailjs-com';

import {Button} from 'reactstrap'



export default function CtaForm() {

    const { t, i18n } = useTranslation()


    const [fullName, setFullName] = useState("")
    const [email, setEmail] = useState("")
    const [subject, setSubject] = useState("")
    const [message, setMessage] = useState("")


    const handleChangeFullname = (e) => {
        setFullName(e.target.value)
      }

      const handleChangeEmail = (e) => {
        setEmail(e.target.value)
      }

      const handleChangeSubject = (e) => {
        setSubject(e.target.value)
      }

      const handleChangeMessage = (e) => {
        setMessage(e.target.value)
      }

      const sendMailValidate = () => {
       

        if (fullName === '') {
            SweetAlert.fire(
                'Oppss.. !',
                `${t('errMiisVar')} ${t('ctaName')}`,
                'error'
              )
            
            return;
        }
        if (email === '') {
            SweetAlert.fire(
                'Oppss.. !',
                `${t('errMiisVar')} ${t('ctaMail')}`,
                'error'
              )
            return;
        }
        if (message === '') {
            SweetAlert.fire(
                'Oppss.. !',
                `${t('errMiisVar')} ${t('ctaMessage')}`,
                'error'
              )
            return;
        }
        

        const templateParams  = {
            fullName: fullName,
            email: email,
            subject: subject,
            message: message,
            
        };

        console.log('PARAMETRE', templateParams )
        /*this.setState({
            //SpinnetBoutton: true,
            validateButton: false,
        })*/


        emailjs.send('service_px87e1v', 'template_0hqzf2d', templateParams , 'user_UmRev6ISrgsE444vWuWsA')
            .then((result) => {
                console.log(result.text, result)

                SweetAlert.fire(
                    'Oppss.. !',
                    `${t('successMsgSend')}`,
                    'success'
                  )

                  setEmail("")
                  setFullName("")
                  setMessage("")
                  setSubject("")
                
            }, (error) => {
                console.log(error.text);
            }); 
            /*setTimeout(() => {
                this.setState({ currentStep: 2, validateButton: true });
            }, 4000);*/
    }


    return (
        <section className="ctaform-wrapper">
            <div className="container">
                <div className="ctaform-grid">
                    <div className="ctaform-left">
                        <div className="sec-heading">
                <p className="sec__meta">{t('ctaSubtitle')}</p>
                <h2 className="sec__title">
                    {t('ctaTitle')}
                </h2>
            </div>
                        <div className="ctaform-contact-info-grid text-center">
                            <div className="item">
                                <FiPhoneCall className="icon" />
                                <h2 className="item-title">{t('ctaNumetoText')}</h2>
                                <p>{t('phone1')} / {t('phone2')}</p>
                            </div>
                            <div className="item">
                                <MdMailOutline className="icon" />
                             <h2 className="item-title">{t('ctaMailText')}</h2>
                                <p>{t('email1')} / {t('email2')}</p>
                            </div> 
                        </div>
                    </div>
                    <div className="ctaform-right">
                        <form>
                            <div className="form-grid">
                                <input value={fullName} type="text" name={t('ctaName')} onChange={(e) => handleChangeFullname(e)} placeholder={t('ctaName')} />
                                <input value={email} type="text" name={t('ctaMail')} onChange={(e) => handleChangeEmail(e)} placeholder={t('ctaMail')} />
                            </div>
                            <input value={subject} type="text" name={t('ctaSubjetct')} onChange={(e) => handleChangeSubject(e)} placeholder={t('ctaSubjetct')} />
                            <textarea value={message} id="textarea" onChange={(e) => handleChangeMessage(e)} placeholder={t('ctaMessage')} ></textarea>
                            <Button onClick={sendMailValidate} className="theme-B">{t('ctaButton')}</Button>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    )
}
