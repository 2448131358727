import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { FiChevronRight, FiPlay } from 'react-icons/fi'
import about_video_img from '../../assets/images/team1.jpg'
import history_img from '../../assets/images/img21.jpg'
import ModalVideo from 'react-modal-video'

import { useTranslation } from "react-i18next";


export default function Contenu (){
            const { t, i18n } = useTranslation()

            return(
                <section className="about-st1-wrapper">
                        <div className="container">
                            <div className="about-st1-grid">
                                <div className="about-left">
                                    <h1 className="about-left-title">
                                        {t('aboutAcceuilText1')} <strong>{t('aboutAcceuilText2')}.</strong>
                                    </h1>
                                    <p className="about-left-desc">
                                        {t('descriptionAboutHome')}
                                    </p>
                                    <Link to="/contact" className="theme-button">
                                        {t('btnContactHome')} <FiChevronRight className="icon" />
                                    </Link>
                                </div>
                                <div className="about-right">
                                    <img className="about-right-img" src={about_video_img} alt="About Video" />
                                    <ModalVideo channel='youtube' /*isOpen={this.state.isOpen}*/ videoId='PkN15TtFnc' onClose={() => this.setState({ isOpen: false })} />
                                    <div className="video-button-box">
                                        <Link to="/#" className="video-button" /*onClick={this.openModal2}*/>
                                            <span className="icon"><FiPlay /></span>
                                            Watch Video
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="history-area">
                                <div className="row">
                                    <div className="col-8">
                                        <img className="history_img" src={history_img} alt="History" />
                                    </div>
                                    <div className="history-details-box">
                                        <h6 className="history-details-title">
                                            {t('about2AcceuilText1')} <strong>{t('about2AcceuilText2')} </strong>
                                            {t('about2AcceuilText3')}.
                                        </h6>

                                        <h4><strong>1- {t('about2AcceuilTitle1')}</strong></h4>
                                        <p className="history-details-desc">
                                        {t('about2AcceuilDescription1')}
                                        </p>

                                        <h4><strong>2- {t('about2AcceuilTitle2')}</strong></h4>
                                        <p className="history-details-desc">
                                        {t('about2AcceuilDescription2')}
                                        </p>

                                        {/*<h4><strong>3- {t('about2AcceuilTitle3')}</strong></h4>
                                        <p className="history-details-desc">
                                        {t('about2AcceuilDescription3')}
                                        </p>
                                        <Link to="/#" className="theme-button">
                                            Get Started <FiChevronRight className="icon" />
                                        </Link>*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
            )

                    }