import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FiChevronDown, FiShoppingCart, FiSearch, FiChevronRight } from "react-icons/fi";
import HeaderBar from "../common/HeaderBar";
import { ProductConsumer } from '../../productcontext'
import $ from 'jquery'
import MenuCart from "./menucart/MenuCart";
import { FaTimes } from "react-icons/all";
import sectiondata from "../../store/store";

import { useTranslation } from "react-i18next";

import logo from "../../assets/images/logo.png"; // 33x33

import { authenticationService } from "../../services/authentication-service";

import flat1 from '../../assets/images/flag.jpg'
import flatfr from '../../assets/images/flag3.jpg'
import flates from '../../assets/images/flag4.jpg'
import flatde from '../../assets/images/flag5.jpg'

import flatpo from '../../assets/images/flagpo.jpg'
import flatit from '../../assets/images/flagit.jpg'
import flatne from '../../assets/images/flagne.jpg'
import flatfi from '../../assets/images/flagfi.jpg'


const currentUser = authenticationService.currentUserValue || '';
//console.log('CURRENT', currentUser)

const currentUserLanguage = JSON.parse(localStorage.getItem('languageValue'))


const Dropdown = () => {
    const [active, setActive] = useState('Select');
    const [isOpen, setOpen] = useState(false);
    const items = ["orange", "pear", "apple"];
    return <div className={`dropdown`}>
        <div onClick={() => setOpen(!isOpen)} className={"dropdown__header"} >{active}</div >
        {isOpen &&
            <div className={"dropdown__body"}>
                {items.map((item, index) => {
                    return <div key={index} onClick={(e) => {
                        setActive(item);
                        setOpen(false);
                    }}>{item}</div>
                })}
            </div>
        }
    </div>
}

export default function Navbar() {


    const [languageValue, setLanguageValue] = useState(currentUserLanguage)

    useEffect(() => {
        const currentUserLanguage = JSON.parse(localStorage.getItem('languageValue'));
        if (currentUserLanguage === null || currentUserLanguage === undefined || currentUserLanguage === '') {
            localStorage.setItem('languageValue', JSON.stringify("de"))
        }
    }
    );

    const { t, i18n } = useTranslation()

    const changeLanguageHandler = (key) => {
        console.log("KEY", key)
        const languageValue = key
        setLanguageValue(key)
        i18n.changeLanguage(languageValue);
        setTimeout(() => {
            i18n.changeLanguage(languageValue)
            localStorage.setItem('languageValue', JSON.stringify(languageValue))
            window.location.reload()

        }, 500);


    }

    // FIN LANGUAGE

    const [searchOpen, setsearchOpen] = useState(false)
    const [navOpen, setnavOpen] = useState(false)

    const HeaderMenuBar = {
        logourl: logo,
        menus: [
            {
                title: t('menuHome'),
                path: '/',
                /*dropdown: [
                    {
                        title: 'Home v1',
                        path: '/',
                    },
                    {
                        title: 'Home v2',
                        path: '/home-v2',
                    }
                ],*/
            },
            {
                title: t('menuService'),
                path: '/services',
            },
            {
                title: t('menuAbout'),
                path: '/about',
            },
            {
                title: t('menuContact'),
                path: '/contact',
            },
            {
                title: t('menuDemande'),
                path: '/demande',
            },
            /*currentUser && currentUser !== '' ?
                {
                    title: t('menuDashboard'),
                    path: '/user',
                } :

                /*{
                    title: t('menuConnexion'),
                    path: '/login',

                },
            currentUser && currentUser !== '' ?
            {}:
            {
                title: t('menuRegister'),
                path: '/sign-up',
            },*/

            /*{
                title: 'Pages',
                path: '/about',
                dropdown: [
                    {
                        title: 'About',
                        path: '/about',
                    },
                    {
                        title: 'Team',
                        path: '/team-grid',
                        dep_dropdown: [
                            {
                                title: 'Team Grid',
                                path: '/team-grid',
                            },
                            {
                                title: 'Team Details',
                                path: '/team-details',
                            }
                        ]
                    },
                    {
                        title: 'Testimonial',
                        path: '/testimonial',
                    },
                    {
                        title: 'FAQ',
                        path: '/faq',
                    },
                    {
                        title: 'Our Pricing',
                        path: '/our-pricing',
                    },
                    {
                        title: '404',
                        path: '/error',
                    },
                    {
                        title: 'User',
                        path: '/sign-up',
                        dep_dropdown: [
                            {
                                title: 'Sign Up',
                                path: '/sign-up',
                            },
                            {
                                title: 'Login',
                                path: '/login',
                            },
                            {
                                title: 'Recover Password',
                                path: '/forgot-password',
                            }
                        ]
                    }
                ],
            },
            {
                title: 'Services',
                path: '/services',
                dropdown: [
                    {
                        title: 'Services',
                        path: '/services',
                    },
                    {
                        title: 'Service Details',
                        path: '/service-details',
                    }
                ]
            },
            {
                title: 'Portfolio',
                path: '/portfolio-grid',
                dropdown: [
                    {
                        title: 'Portfolio Grid',
                        path: '/portfolio-grid',
                    },
                    {
                        title: 'Portfolio Details',
                        path: '/portfolio-details',
                    }
                ]
            },
            {
                title: 'Blog',
                path: '/blog-full-width',
                dropdown: [
                    {
                        title: 'Blog Full Width',
                        path: '/blog-full-width',
                    },
                    {
                        title: 'Blog Grid',
                        path: '/blog-grid',
                    },
                    {
                        title: 'Blog Details',
                        path: '/blog-details',
                    }
                ]
            },
            {
                title: 'Shop',
                path: '/shop-home',
                dropdown: [
                    {
                        title: 'Shop Home',
                        path: '/shop-home',
                    },
                    {
                        title: 'Shop Sidebar',
                        path: '/shop',
                    },
                    {
                        title: 'Product Details',
                        path: '/product-details',
                    },
                    {
                        title: 'Cart',
                        path: '/cart',
                    },
                    {
                        title: 'Checkout',
                        path: '/checkout',
                    }
                ]
            },
            {
                title: 'Contact',
                path: '/contact',
            }*/
        ]
    }

    /*====  side-widget-menu  =====*/
    $(document).on('click', '.side-menu-wrap .side-menu-ul .sidenav__item', function () {
        $(".side-menu-wrap .side-menu-ul .sidenav__item").removeClass('active');
        $(this).toggleClass("active")
    })
    $(document).on('click', '.side-menu-wrap .side-menu-ul .sidenav__item.active', function () {
        $(".side-menu-wrap .side-menu-ul .sidenav__item.active").removeClass('active');
    })

    $(window).on('scroll', function () {

        // Header Menu Fixed
        if ($(window).scrollTop() > 30) {
            $('body').addClass('header-menu-fixed');
            $('.scroll-d-block').addClass('d-block')
        } else {
            $('body').removeClass('header-menu-fixed');
            $('.scroll-d-block').removeClass('d-block')
        }
    });

    return (
        <>
            <HeaderBar />

            <div className="header-menu-wrapper scroll-d-block">
                <div className="header-menu-container container">
                    <div className="header-menu-flex">
                        <div className="header-menu-left">
                            <Link to="/">
                                <img src={HeaderMenuBar.logourl} alt="Logo" />
                            </Link>
                        </div>
                        <div className="header-menu-right">
                            <nav>
                                <ul>
                                    {
                                        HeaderMenuBar.menus.map((item, index) => {
                                            return (
                                                <li key={index}>
                                                    <Link to={item.path}>
                                                        {item.title} {item.dropdown ? <FiChevronDown className="menu-icon" /> : ''}
                                                    </Link>
                                                    {
                                                        item.dropdown ?
                                                            (
                                                                <ul className="dropdown-menu-item">
                                                                    {
                                                                        item.dropdown.map((dp_item, i) => {
                                                                            return (
                                                                                <li key={i}>
                                                                                    <Link to={dp_item.path}>{dp_item.title} {dp_item.dep_dropdown ? <FiChevronRight className="icon" /> : ''}</Link>
                                                                                    {
                                                                                        dp_item.dep_dropdown ?
                                                                                            (
                                                                                                <ul className="dropdown-menu-item">
                                                                                                    {dp_item.dep_dropdown.map((dp_item_inr, i2) => {
                                                                                                        return (
                                                                                                            <li key={i2}>
                                                                                                                <Link to={dp_item_inr.path}>{dp_item_inr.title}</Link>
                                                                                                            </li>
                                                                                                        )
                                                                                                    })}
                                                                                                </ul>
                                                                                            ) : ''
                                                                                    }
                                                                                </li>
                                                                            )
                                                                        })
                                                                    }

                                                                </ul>
                                                            ) : ''
                                                    }
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </nav>
                            <div className="header-menu-right-flex">
                                {/*
                                <div className="header-menu-cart">
                                    <ul>
                                        <li>
                                            <div className="cart__icon">
                                                <Link to="#">
                                                    <FiShoppingCart />
                                                </Link>
                                                <span className="cart__item-count">
                                                    <ProductConsumer>
                                                        {(value) => {
                                                            const { cart } = value;
                                                            if (cart.length > 0) {
                                                                return (
                                                                    <span>
                                                                        {cart.length}
                                                                    </span>
                                                                )
                                                            } else {
                                                                return (
                                                                    "0"
                                                                );
                                                            }
                                                        }}
                                                    </ProductConsumer>
                                                </span>
                                            </div>

                                           {/* <MenuCart /> /}
                                        </li>
                                        <li>
                                            <Link to="#" className="search-button" onClick={() => setsearchOpen(!searchOpen)}>
                                                {
                                                    searchOpen ?
                                                        <FaTimes className="search-icon" />
                                                    : <FiSearch className="search-icon" />
                                                }
                                            </Link>
                                        </li> 
                                    </ul>
                                </div>*/}
                                <div className={searchOpen ? 'header-menu-searchform active' : 'header-menu-searchform'}>
                                    <form action="#">
                                        <input className="form-control" type="text" placeholder="Search by keywords..." />
                                        <div className="form-icon">
                                            <FiSearch className="search-icon" />
                                        </div>
                                    </form>
                                </div>
                                <div className="side-menu-open" onClick={() => setnavOpen(!navOpen)}>
                                    <span className="menu__bar"></span>
                                    <span className="menu__bar"></span>
                                    <span className="menu__bar"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={navOpen ? 'side-nav-container active' : 'side-nav-container'}>
                <div className="humburger-menu">
                    <div className="humburger-menu-lines side-menu-close" onClick={() => setnavOpen(!navOpen)}></div>
                </div>
                <div className="side-menu-wrap">
                    <ul className="side-menu-ul">
                        {
                            HeaderMenuBar.menus.map((item, index) => {
                                return (
                                    <li className="sidenav__item" key={index}>
                                        <a href={item.path}>
                                            {item.title}
                                        </a>
                                        {item.dropdown ? <span className="menu-plus-icon"></span> : ''}
                                        {
                                            item.dropdown ?
                                                (
                                                    <ul className="side-sub-menu">
                                                        {
                                                            item.dropdown.map((dp_item, i) => {
                                                                return (
                                                                    <li key={i}>
                                                                        <a href={dp_item.path}>{dp_item.title}</a>
                                                                        {
                                                                            dp_item.dep_dropdown ?
                                                                                (
                                                                                    <ul className="dropdown-menu-item">
                                                                                        {dp_item.dep_dropdown.map((dp_item_inr, i2) => {
                                                                                            return (
                                                                                                <li key={i2}>
                                                                                                    <Link to={dp_item_inr.path}>{dp_item_inr.title}</Link>
                                                                                                </li>
                                                                                            )
                                                                                        })}
                                                                                    </ul>
                                                                                ) : ''
                                                                        }
                                                                    </li>
                                                                )
                                                            })
                                                        }

                                                    </ul>
                                                ) : ''
                                        }
                                    </li>
                                )
                            })
                        }

                        <li className="sidenav__item sidenav__item2 text-center">
                            <Link to="/sign-up">{t('menuRegister')}</Link>
                        </li>

                        <li className="sidenav__item ">

                            {/*<div class="dropdown">
                                <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                    <img src={languageValue === "en" ? flat1 : languageValue === "fr" ? flatfr : flatfr} alt="flag" /> {languageValue === "en" ? "English" : languageValue === "fr" ? "Français" : "Français"}
                                </button>
                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                    {sectiondata.HeaderData.HeaderBar.languages.map((item, index) => {
                                        return (
                                            <li key={index}>
                                                <Link className="dropdown-item" onClick={() => changeLanguageHandler(item.url)} /*to={item.url}/ className="d-flex">
                                                    <img src={item.flag} alt="flag" /> {item.name}
                                                </Link>
                                            </li>
                                        )
                                    })}

                                </ul>
                            </div>*/}

                            <ul >
                                <li>

                                    <a href="/" className="d-flex"><img src={languageValue === "en" ? flat1 : languageValue === "fr" ? flatfr : languageValue === "es" ? flates : languageValue === "de" ? flatde : languageValue === "po" ? flatpo : languageValue === "fi" ? flatfi : languageValue === "ne" ? flatne : languageValue === "it" ? flatit : flatde} alt="flag" /> {languageValue === "en" ? "English" : languageValue === "fr" ? "Français" : languageValue === "es" ? "Spain" : languageValue === "de" ? "Deutsch" : languageValue === "po" ? "Português" : languageValue === "fi" ? "Suomalainen" : languageValue === "ne" ? "Nederlands" : languageValue === "it" ? "Italiano" : "Deutsch"} <FiChevronDown className="icon" /></a>
                                    <ul>
                                        {sectiondata.HeaderData.HeaderBar.languages.map((item, index) => {
                                            return (
                                                <li key={index}>
                                                    <Link onClick={() => changeLanguageHandler(item.url)} /*to={item.url}*/ className="d-flex">
                                                        <img src={item.flag} alt="flag" /> {item.name}
                                                    </Link>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </li>
                            </ul>
                        </li>

                    </ul>
                </div>
            </div>

        </>
    );
}



