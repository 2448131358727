import {FiChevronRight} from "react-icons/fi";
import React, {useState} from "react";

import { useTranslation } from "react-i18next";


import SweetAlert from 'sweetalert2';
import emailjs from 'emailjs-com';

import {Button} from 'reactstrap'

export default function FooterForm() {

    const { t, i18n } = useTranslation()

    const [fullName, setFullName] = useState("")
    const [email, setEmail] = useState("")
    const [subject, setSubject] = useState("")
    const [message, setMessage] = useState("")


    const handleChangeFullname = (e) => {
        setFullName(e.target.value)
      }

      const handleChangeEmail = (e) => {
        setEmail(e.target.value)
      }

      const handleChangeSubject = (e) => {
        setSubject(e.target.value)
      }

      const handleChangeMessage = (e) => {
        setMessage(e.target.value)
      }

      const sendMailValidate = () => {
       

        if (fullName === '') {
            SweetAlert.fire(
                'Oppss.. !',
                `${t('errMiisVar')} ${t('ctaName')}`,
                'error'
              )
            
            return;
        }
        if (email === '') {
            SweetAlert.fire(
                'Oppss.. !',
                `${t('errMiisVar')} ${t('ctaMail')}`,
                'error'
              )
            return;
        }
        if (message === '') {
            SweetAlert.fire(
                'Oppss.. !',
                `${t('errMiisVar')} ${t('ctaMessage')}`,
                'error'
              )
            return;
        }
        

        const templateParams  = {
            fullName: fullName,
            email: email,
            subject: subject,
            message: message,
            
        };

        //console.log('PARAMETRE', templateParams )
        /*this.setState({
            //SpinnetBoutton: true,
            validateButton: false,
        })*/


        emailjs.send('service_px87e1v', 'template_0hqzf2d', templateParams , 'user_UmRev6ISrgsE444vWuWsA')
            .then((result) => {
                //console.log(result.text, result)

                SweetAlert.fire(
                    'Oppss.. !',
                    `${t('successMsgSend')}`,
                    'success'
                  )

                  setEmail("")
                  setFullName("")
                  setMessage("")
                  setSubject("")
                
            }, (error) => {
                //console.log(error.text);
            }); 
            /*setTimeout(() => {
                this.setState({ currentStep: 2, validateButton: true });
            }, 4000);*/
    }

    return (
        <div className="footer-widget footer-get-in-touch">
            <h2>{t('formFootTitle')}</h2>
            <form className="get-in-touch">
                <input value={fullName} onChange={(e) => handleChangeFullname(e)} type="text" name="text" placeholder={t('formFootName')} />
                <input value={email} onChange={(e) => handleChangeEmail(e)}  type="text" name="email" placeholder={t('formFootMail')} />
                <textarea value={message} onChange={(e) => handleChangeMessage(e)} placeholder={t('formFootMessage')}></textarea>
                <Button className="theme-button" onClick={sendMailValidate}> {t('ctaButton')} <FiChevronRight className="icon" /></Button>
            </form>
        </div>
    )
}
