import React, { Component, lazy, Suspense, useMemo } from 'react';
//import { CardImg, Modal, ModalBody, ModalHeader, Input, Button, Label, Form, Alert } from 'reactstrap';
import { services } from '../../services/services';


import { FaEye, FaCheck, FaEdit, FaTrash } from 'react-icons/fa'

import { withTranslation } from 'react-i18next';


import { Alert, Button, Modal, Card, Row, Col, Form, FormGroup, Label, Input, ModalHeader, ModalBody, ModalFooter, CardBody, CardFooter } from 'reactstrap';
import Media from 'react-media';

import Snackbar from '@material-ui/core/Snackbar';

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

import TextField from '@material-ui/core/TextField';


import SweetAlert from 'sweetalert2'

import './Profil.css'
import { CountryDropdown,/* RegionDropdown, CountryRegionData*/ } from 'react-country-region-selector';
import { t } from 'i18next';
import { userInfo } from 'os';


const currentUserLanguage = JSON.parse(localStorage.getItem('languageValue'))

//const options = useMemo(() => countryList().getData(), []) 


class Transfert extends Component {
  constructor(props) {
    super(props);

    this.state = {
      languageValue: currentUserLanguage,
      userInfo: '',
      page: 'DASH',
      password: '',
      newPassword: '',
      users: [],
      isAddModalVisible: false,
      pays: '',
      Banque: '',
      intitule: '',
      compte: '',
      montant: '',
      iban: '',

      successMsg: '',
      warningMsg: '',
      infoMsg: '',
      alertWarningMsg: false,
      alertWarningMsg: false,
      alertSuccessMsg: false,

      alertErrMsg: false,
      errMsg: '',
      alertInfoMsg: false,
      infoMsg: '',

      isAddModalVisible: false,
      openDetailModal: false,
      openUpdateModal: false,
      solde: 0,






    };

  }



  selectCountry(val) {
    //console.log('VAL', val)
    this.setState({ pays: val });
  }

  handleChange = (e) => {
    //console.log('Mise a jour state', this.state)
    this.setState({ [e.target.name]: e.target.value })
  }

  changeHandler = value => {
    this.setState({ pays: value })
  }




  componentDidMount() {


    services.getCurrentUser()
      .then(userInfo => {
        //console.log('CONTAIN Information complete utilisateur', userInfo)

        this.setState({
          userInfo: userInfo,
          soldeTotal: userInfo.montant,

        })

        if (userInfo.role === "CLIENT") {
          this.setState({
            userInfo: userInfo,
            soldeTotal: userInfo.montant,

            typeCompte: userInfo.typeCompte || '',
            civilite: userInfo.civilite || '',
            firstName: userInfo.firstName || '',
            lastName: userInfo.lastName || '',
            adresse: userInfo.adresse || '',
            ville: userInfo.ville || '',
            telephone: userInfo.telephone || '',
            numeroPiece: userInfo.numeroPiece || '',
            devise: userInfo.devise || '€',
            email: userInfo.email || '',

            username: userInfo.username || '',
            bic: userInfo.bic || '',
            cleRib: userInfo.cleRib || '',
            codeBanque: userInfo.codeBanque || '',
            codeGuichet: userInfo.codeGuichet || '',
            compteNumero: userInfo.compteNumero || '',
            iban: userInfo.iban || '',
            codeTransfert1: userInfo.codeTransfert1 || '',
            codeTransfert2: userInfo.codeTransfert2 || '',
            codeTransfert3: userInfo.codeTransfert3 || '',

            userId: userInfo.id,

          })
        }

        if (userInfo.role === "ADMIN") {
          services.getUsers()
            .then(users => {
              this.setState({ users })
            })

        }

      })


  }


  updateUserProfil = () => {
    const {

      userId,
      pays,

      typeCompte,
      civilite,
      firstName,
      lastName,
      adresse,
      ville,
      telephone,
      numeroPiece,
      devise,
      email,

      username,
      bic,
      cleRib,
      codeBanque,
      codeGuichet,
      compteNumero,
      iban,
      codeTransfert1,
      codeTransfert2,
      codeTransfert3,
      solde,

    } = this.state



    if (firstName === '') {
      this.setState({ alertWarningMsg: true, warningMsg: this.props.t('alertUpdateFirstName'), })
      setTimeout(() => this.setState({ warningMsg: '', alertWarningMsg: false }), 6000)
      return;
    }

    if (lastName === '') {
      this.setState({ alertWarningMsg: true, warningMsg: this.props.t('alertUpdateLastName'), })
      setTimeout(() => this.setState({ warningMsg: '', alertWarningMsg: false }), 6000)
      return;
    }
    if (email === '') {
      this.setState({ alertWarningMsg: true, warningMsg: this.props.t('alertUpdateEmail'), })
      setTimeout(() => this.setState({ warningMsg: '', alertWarningMsg: false }), 6000)
      return;
    }
    if (username === '') {
      this.setState({ alertWarningMsg: true, warningMsg: this.props.t('alertUpdateUsername'), })
      setTimeout(() => this.setState({ warningMsg: '', alertWarningMsg: false }), 6000)
      return;
    }
    if (adresse === '') {
      this.setState({ alertWarningMsg: true, warningMsg: this.props.t('alertUpdateAdresse'), })
      setTimeout(() => this.setState({ warningMsg: '', alertWarningMsg: false }), 6000)
      return;
    }


    let req = {



      typeCompte,
      civilite,
      firstName,
      lastName,
      adresse,
      ville,
      telephone,
      numeroPiece,
      devise,
      email,
      solde,
      username,
      bic,
      cleRib,
      codeBanque,
      codeGuichet,
      compteNumero,
      iban,
      codeTransfert1,
      codeTransfert2,
      codeTransfert3,

      pays: pays.toUpperCase(),


    }
    services.updateUser(userId, req)
      .then(result => {
        //console.log(result)
        this.setState({

          alertSuccessMsg: true,
          successMsg: this.props.t('alertSuccessProfil'),
          openUpdateModal: false,


        })

        setTimeout(() => {
          this.setState({ successMsg: '', alertSuccessMsg: false });
        }, 10000)

        if (this.state.userInfo.role === "ADMIN") {
          services.getUsers()
            .then(users => {
              //console.log('USERS', users)
              this.setState({ users })
            })

        }


      })

      .catch(() => {
        this.setState({ errMsg: this.props.t('errMsg.UpdateUser'), alertErrMsg: true })
        setTimeout(() => {
          this.setState({ errMsg: '', alertErrMsg: false });
        }, 6000)
      });

  }


  addUserProfil = () => {
    const {

      userId,
      pays,

      typeCompte,
      civilite,
      firstName,
      lastName,
      adresse,
      ville,
      telephone,
      numeroPiece,
      devise,
      email,

      username,
      bic,
      cleRib,
      codeBanque,
      codeGuichet,
      compteNumero,
      iban,
      codeTransfert1,
      codeTransfert2,
      codeTransfert3,
      solde,

    } = this.state



    if (firstName === '') {
      this.setState({ alertWarningMsg: true, warningMsg: this.props.t('alertUpdateFirstName'), })
      setTimeout(() => this.setState({ warningMsg: '', alertWarningMsg: false }), 6000)
      return;
    }

    if (lastName === '') {
      this.setState({ alertWarningMsg: true, warningMsg: this.props.t('alertUpdateLastName'), })
      setTimeout(() => this.setState({ warningMsg: '', alertWarningMsg: false }), 6000)
      return;
    }
    if (email === '') {
      this.setState({ alertWarningMsg: true, warningMsg: this.props.t('alertUpdateEmail'), })
      setTimeout(() => this.setState({ warningMsg: '', alertWarningMsg: false }), 6000)
      return;
    }
    /*if (username === '') {
      this.setState({ alertWarningMsg: true, warningMsg: this.props.t('alertUpdateUsername'), })
      setTimeout(() => this.setState({ warningMsg: '', alertWarningMsg: false }), 6000)
      return;
    }*/
    if (adresse === '') {
      this.setState({ alertWarningMsg: true, warningMsg: this.props.t('alertUpdateAdresse'), })
      setTimeout(() => this.setState({ warningMsg: '', alertWarningMsg: false }), 6000)
      return;
    }


    let req = {



      typeCompte,
      civilite,
      firstName,
      lastName,
      adresse,
      ville,
      telephone,
      numeroPiece,
      devise,
      email,
      solde,
      username,
      bic,
      cleRib,
      codeBanque,
      codeGuichet,
      compteNumero,
      iban,
      codeTransfert1,
      codeTransfert2,
      codeTransfert3,

      pays: pays.toUpperCase(),


    }
    services.addUser(req)
      .then(result => {
        //console.log(result)
        this.setState({

          alertSuccessMsg: true,
          successMsg: this.props.t('alertSuccessUserAdd'),
          openUpdateModal: false,
          isAddModalVisible: false,

        })

        setTimeout(() => {
          this.setState({ successMsg: '', alertSuccessMsg: false });
        }, 10000)

        if (this.state.userInfo.role === "ADMIN") {
          services.getUsers()
            .then(users => {
              //console.log('USERS', users)
              this.setState({ users })
            })

        }


      })

      .catch(() => {
        this.setState({ errMsg: 'Une erreur est survenue lors de l\'ajout, veuillez réessayer plus tard', alertErrMsg: true })
        setTimeout(() => {
          this.setState({ errMsg: '', alertErrMsg: false });
        }, 6000)
      });

  }

  handleAddOpen = () => {
    this.setState({
      isAddModalVisible: !this.state.isAddModalVisible,
      pays: '',
      typeCompte: '',
      civilite: '',
      firstName: '',
      lastName: '',
      adresse: '',
      ville: '',
      telephone: '',
      numeroPiece: '',
      devise: '',
      email: '',

      username: '',
      bic: '',
      cleRib: '',
      codeBanque: '',
      codeGuichet: '',
      compteNumero: '',
      iban: '',
      userId: '',
      codeTransfert1: '',
      codeTransfert2: '',
      codeTransfert3: '',
      solde: 0,
    })
  };


  handleView = (row) => {
    //console.log('ROW', row)
    this.setState({
      row,
      typeCompte: row.typeCompte || '',
      civilite: row.civilite || '',
      firstName: row.firstName || '',
      lastName: row.lastName || '',
      adresse: row.adresse || '',
      ville: row.ville || '',
      telephone: row.telephone || '',
      numeroPiece: row.numeroPiece || '',
      devise: row.devise || '€',
      email: row.email || '',

      username: row.username || '',
      bic: row.bic || '',
      cleRib: row.cleRib || '',
      codeBanque: row.codeBanque || '',
      codeGuichet: row.codeGuichet || '',
      compteNumero: row.compteNumero || '',
      iban: row.iban || '',
      codeTransfert1: row.codeTransfert1 || '',
      codeTransfert2: row.codeTransfert2 || '',
      codeTransfert3: row.codeTransfert3 || '',
      solde: row.solde,
      pays: row.pays,

      userId: row.id,
      openDetailModal: true,

    })

  };

  handleUpdate = (row) => {
    //console.log('ROW', row)
    this.setState({
      row,
      typeCompte: row.typeCompte || '',
      civilite: row.civilite || '',
      firstName: row.firstName || '',
      lastName: row.lastName || '',
      adresse: row.adresse || '',
      ville: row.ville || '',
      telephone: row.telephone || '',
      numeroPiece: row.numeroPiece || '',
      devise: row.devise || '€',
      email: row.email || '',

      username: row.username || '',
      bic: row.bic || '',
      cleRib: row.cleRib || '',
      codeBanque: row.codeBanque || '',
      codeGuichet: row.codeGuichet || '',
      compteNumero: row.compteNumero || '',
      iban: row.iban || '',
      codeTransfert1: row.codeTransfert1 || '',
      codeTransfert2: row.codeTransfert2 || '',
      codeTransfert3: row.codeTransfert3 || '',
      solde: row.solde,

      pays: row.pays,
      desactiverCompte: row.desactiverCompte,

      userId: row.id,
      openUpdateModal: true,

    })

  };

  handleDetailClose = () => {
    this.setState({
      pays: '',
      typeCompte: '',
      civilite: '',
      firstName: '',
      lastName: '',
      adresse: '',
      ville: '',
      telephone: '',
      numeroPiece: '',
      devise: '',
      email: '',

      username: '',
      bic: '',
      cleRib: '',
      codeBanque: '',
      codeGuichet: '',
      compteNumero: '',
      iban: '',
      userId: '',
      openDetailModal: false,
    })
  }

  handleUpdateClose = () => {
    this.setState({
      pays: '',
      typeCompte: '',
      civilite: '',
      firstName: '',
      lastName: '',
      adresse: '',
      ville: '',
      telephone: '',
      numeroPiece: '',
      devise: '',
      email: '',

      username: '',
      bic: '',
      cleRib: '',
      codeBanque: '',
      codeGuichet: '',
      compteNumero: '',
      iban: '',
      userId: '',
      openUpdateModal: false,
    })
  }


  disableUser = () => {

    const { userId } = this.state

    services.disableUser(userId)
      .then(del => {
        ////console.log('disable user', del)

        this.setState({

          alertSuccessMsg: true,
          successMsg: 'Compte Client Désactiver avec succèss',
          openUpdateModal: false,
          //openUpdateModal: false,

        })
        services.getUsers()
          .then(users => {
            //console.log('USERS', users)
            this.setState({ users })
          })

        setTimeout(() => {
          this.setState({ successMsg: '', alertSuccessMsg: false });
        }, 10000)

      })
      .catch(() => {
        this.setState({ errMsg: 'Une erreur est survenue lors de la Désactivation, veuillez réessayer plus tard', alertErrMsg: true })
        setTimeout(() => {
          this.setState({ errMsg: '', alertErrMsg: false });
        }, 6000)
      });

  }

  enableUser = () => {

    const { userId } = this.state

    services.enableUser(userId)
      .then(del => {
        ////console.log('delete user', del)
        this.setState({

          alertSuccessMsg: true,
          successMsg: 'Compte Client Activer avec succèss',
          openUpdateModal: false,
          //openUpdateModal: false,

        })
        services.getUsers()
          .then(users => {
            //console.log('USERS', users)
            this.setState({ users })
          })

        setTimeout(() => {
          this.setState({ successMsg: '', alertSuccessMsg: false });
        }, 10000)

      })
      .catch(() => {
        this.setState({ errMsg: 'Une erreur est survenue lors de l\'Activation, veuillez réessayer plus tard', alertErrMsg: true })
        setTimeout(() => {
          this.setState({ errMsg: '', alertErrMsg: false });
        }, 6000)
      });

  }


  handleDeleteUserOpen = (row) => {

    SweetAlert.fire({
      title: this.props.t('titleTransfertValider'),
      text: `De vouloir supprimer lr client ${row.firstName} ${row.lastName}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.props.t('table.boutton.enregistrer'),
      cancelButtonText: this.props.t('modalCloseButton'),
      reverseButtons: true
    })
      .then((result) => {

        if (result.value) {

          services.deleteUser(row.id)
            .then(result => {
              ////console.log(result)
              //this.setState({ commande: '', open: false })
              SweetAlert.fire(
                this.props.t('successTransfertTitle'),
                'Client supprimer avec succès',
                'success'
              )
              services.getUsers()
                .then(users => {
                  //console.log('transferts', transferts)
                  this.setState({ users })
                })
            })
            .catch(() => {
              SweetAlert.fire({ title: this.props.t('errorTransfertTitle'), text: 'Erreur lors de la suppression', icon: "error" });
            });

        }
        else {
          SweetAlert.fire(
            'Suppression Annulée!'
          ).then((result) => {
            if (result.value) {
              this.setState({ open: false })
            }

          })
        }
      })
  }



  render() {
    const {
      page,
      userInfo,
      password,
      newPassword,
      pays,

      users,

      typeCompte,
      civilite,
      firstName,
      lastName,
      adresse,
      ville,
      telephone,
      numeroPiece,
      devise,
      email,

      username,
      bic,
      cleRib,
      codeBanque,
      codeGuichet,
      compteNumero,
      iban,
      codeTransfert1,
      codeTransfert2,
      codeTransfert3,
      solde,

      desactiverCompte,
      successMsg,
      warningMsg,
      infoMsg,
      alertWarningMsg,

      alertSuccessMsg,
      alertErrMsg,
      errMsg,
      alertInfoMsg,

      isAddModalVisible,
      openDetailModal,
      openUpdateModal,




    } = this.state

    const { t } = this.props;


    const { SearchBar } = Search;

    const defaultSorted = [{
      dataField: 'id',
      order: 'desc',
      text: 'N°',

    }
    ];

    const columnsUsers = [{
      dataField: 'id',
      text: t('transfTableNumber'),
      hidden: true,
    },
    {
      dataField: 'firstName',
      text: t('formFirstName')
    },
    {
      dataField: 'lastName',
      text: t('formLastName')
    },
    {
      dataField: 'username',
      text: t('menuUser'),
      style: (cell, row, rowIndex, colIndex) => {
        if (row.desactiverCompte === true) {
          return {
            backgroundColor: '#F08080'
          };
        }
      }
    },
    {
      dataField: 'password',
      text: t('formPassword')
    },


    {
      dataField: '',
      text: t('formAction'),
      headerAlign: 'center',
      sort: true,
      headerAttrs: { width: 50 },
      align: 'center',
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          < div
            style={{ textAlign: "center" }} className="d-flex">


            <Button
              type="primary"
              //fullWidth
              variant="contained"
              color="primary"
              size="sm"
              className="mr-2 btn btn-primary btn-sm"
              onClick={() => this.handleView(row)}
            >
              <FaEye className="icon" />
            </Button>

            {userInfo.role === 'ADMIN' &&
              <Button
                type="primary"
                //fullWidth
                variant="contained"
                color="info"
                size="sm"
                //className="btn btn-success btn-sm"
                onClick={() => this.handleUpdate(row)}

              >
                <FaEdit className="icon" />

              </Button>
            }

            {userInfo.role === 'ADMIN' &&
              <Button
                type="primary"
                //fullWidth
                variant="contained"
                color="danger"
                size="sm"
                className="mr-2 btn btn-primary btn-sm"
                //className="btn btn-success btn-sm"
                onClick={() => this.handleDeleteUserOpen(row)}

              >
                <FaTrash className="icon" />

              </Button>
            }

          </div>
        );
      },

    }

    ];


    const columnsUsersPhone = [
      {
        dataField: 'id',
        text: 'N°',
        order: 'desc',
        hidden: true
      },


      {
        dataField: 'username',
        text: t('menuUser'),
        style: (cell, row, rowIndex, colIndex) => {
          if (row.desactiverCompte === true) {
            return {
              backgroundColor: '#F08080'
            };
          }
        }
      },
      {
        dataField: 'password',
        text: t('formPassword')
      },


      {
        dataField: '',
        text: t('formAction'),
        headerAlign: 'center',
        sort: true,
        headerAttrs: { width: 50 },
        align: 'center',
        formatter: (cell, row, rowIndex, formatExtraData) => {
          return (
            < div
              style={{ textAlign: "center" }} className="d-flex">


              <Button
                type="primary"
                //fullWidth
                variant="contained"
                color="primary"
                size="sm"
                className="mr-2 btn btn-primary btn-sm"
                onClick={() => this.handleView(row)}
              >
                <FaEye className="icon" />
              </Button>

              {userInfo.role === 'ADMIN' &&
                <Button
                  type="primary"
                  //fullWidth
                  variant="contained"
                  color="info"
                  size="sm"
                  //className="btn btn-success btn-sm"
                  onClick={() => this.handleUpdate(row)}

                >
                  <FaEdit className="icon" />

                </Button>
              }

            </div>
          );
        },

      }

    ];



    return (
      <>


        {userInfo.role === "ADMIN" &&

          <Card className="card">
            <CardBody class="card-body">
              <h5 className="card-title">{t('userTableTitle')}</h5>

              <Media query="(min-width: 600px)" render={() =>
              (
                <ToolkitProvider
                  keyField="id"
                  data={users}
                  columns={columnsUsers}
                  //cellEdit={cellEditFactory({ mode: 'dbclick' })}
                  search
                  //insertRow={true}
                  exportCSV

                >
                  {
                    props => (
                      <div>
                        <Row >
                          <Col md={6} style={{ display: 'flex', alignItems: 'baseline' }}>
                            {/*<i className="fa fa-search" style={{ margin: 10 }}></i> */}

                            <SearchBar {...props.searchProps}
                              //delay={ 1000 }
                              style={{ maxWidth: 750, width: '180%', margin: 'auto', display: 'flex', borderRadius: 50 }}
                              placeholder={t('searchTitle')}

                            />
                          </Col>
                          {//currentUser && currentUser.slug === 'COMMERCIAL_ROLE' &&
                            <Col md={6} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'baseline', }}>

                              <button style={{ marginLeft: 3 }}
                                className="btn bg-primary text-light rounded"
                                onClick={this.handleAddOpen}>
                                {t('buttonAddUser')} <i className="icon icon-add" />
                              </button>
                            </Col>

                          }
                        </Row>


                        <BootstrapTable
                          {...props.baseProps}
                          //bootstrap4
                          wrapperClasses="table-responsive"
                          //selectRow={ selectRow }
                          //defaultSorted={defaultSorted}
                          pagination={paginationFactory()}
                          defaultSorted={defaultSorted}
                          striped
                          hover
                          condensed
                          headerWrapperClasses="foo"
                          noDataIndication="Vous n'avez aucun client pour l'instant, créer un nouveau client"
                        //filter={filterFactory()}
                        />

                      </div>

                    )
                  }
                </ToolkitProvider>
              )}
              />


              <Media query="(max-width: 599px)" render={() =>
              (

                <ToolkitProvider
                  keyField="id"
                  data={users}
                  columns={columnsUsersPhone}
                  //cellEdit={cellEditFactory({ mode: 'dbclick' })}
                  search
                  //insertRow={true}
                  exportCSV

                >
                  {
                    props => (
                      <div>
                        <Row >
                          <Col md={6} style={{ display: 'flex', alignItems: 'baseline' }}>
                            <i className="fa fa-search" style={{ margin: 10 }}></i>

                            <SearchBar {...props.searchProps}
                              //delay={ 1000 }
                              style={{ maxWidth: 450, width: '125%', margin: 'auto', display: 'flex', borderRadius: 50 }}
                              placeholder={t('searchTitle')}

                            />
                          </Col>
                          {//currentUser && currentUser.slug === 'COMMERCIAL_ROLE' &&
                            <Col md={6} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'baseline', }}>

                              <button style={{ marginLeft: 3 }}
                                className="btn bg-primary text-light rounded"
                                onClick={this.handleAddOpen}>
                                {t('buttonAddTransfert')} <i className="icon icon-add" />
                              </button>
                            </Col>
                          }

                        </Row>

                        <BootstrapTable
                          {...props.baseProps}
                          //bootstrap4
                          wrapperClasses="table-responsive"
                          //selectRow={ selectRow }
                          //defaultSorted={defaultSorted}
                          pagination={paginationFactory()}
                          defaultSorted={defaultSorted}
                          striped
                          hover
                          condensed
                          headerWrapperClasses="foo"
                          noDataIndication="Vous n'avez aucun client pour l'instant, créer un nouveau client"
                        //filter={filterFactory()}
                        />

                      </div>

                    )
                  }
                </ToolkitProvider>
              )}
              />
            </CardBody>
          </Card>
        }

        {userInfo.role === "CLIENT" &&
          < body >
            <div className="container">
              <div className="row gutters">
                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 ">
                  <div className="card h-100">
                    <div className="card-body">
                      <div className="account-settings">
                        <div className="user-profile">
                          <div className="user-avatar">
                            <img src="https://bootdey.com/img/Content/avatar/avatar7.png" alt="Maxwell Admin" />
                          </div>
                          <h5 className="user-name">{userInfo.firstName} {userInfo.lastName}</h5>
                          <h6 className="user-email">{userInfo.username}</h6>

                          <h6 className="user-email">{userInfo.email}</h6>
                        </div>
                        <div className="about">
                          <h5>{t('userSolde')}: {userInfo.solde} {userInfo.devise}</h5>
                          <p>{userInfo.typeCompte}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-9 col-lg-9 col-md-9 col-sm-12 ">
                  <div className="card h-100">
                    <div className="card-body">
                      <div className="row gutters">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                          <h6 className="mb-2 text-primary">{t('profilTitlePersonalInfo')}</h6>
                        </div>

                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                          <div className="form-group">
                            <label htmlFor="fullName">{t('formTypeAccount')}</label>
                            <select name="typeCompte" value={typeCompte} onChange={this.handleChange} placeholder={t('formTypeAccount')} className="form-select form-select-sm w-100" aria-label=".form-select-sm example" >
                              <option selected value={typeCompte}>{t('defaultTypeCompte')}</option>
                            </select>
                          </div>
                        </div>

                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                          <div className="form-group">
                            <label htmlFor="fullName">{t('formGender')}</label>
                            <select name="civilite" value={civilite} onChange={this.handleChange} className="form-select mt-2 mb-2 form-select-sm w-100" aria-label=".form-select-sm example">
                              <option value="" selected>{t('formGender')}</option>
                              <option value="Mr">{t('civiliteMr')}</option>
                              <option value="Mme">{t('civiliteMme')}</option>

                            </select>
                          </div>
                        </div>

                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                          <div className="form-group">
                            <label htmlFor="fullName">{t('formFirstName')}</label>
                            <input name="firstName" className="form-control" value={firstName} onChange={this.handleChange} type="text" placeholder={t('formFirstName')} />

                          </div>
                        </div>

                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                          <div className="form-group">
                            <label htmlFor="fullName">{t('formLastName')}</label>
                            <input name="lastName" className="form-control" value={lastName} onChange={this.handleChange} type="text" placeholder={t('formLastName')} />

                          </div>
                        </div>

                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                          <div className="form-group">
                            <label htmlFor="fullName">{t('formAddress')}</label>
                            <input name="adresse" className="form-control" value={adresse} onChange={this.handleChange} type="text" placeholder={t('formAddress')} />

                          </div>
                        </div>

                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                          <div className="form-group">
                            <label htmlFor="fullName">{t('formCity')}</label>
                            <input name="ville" className="form-control" value={ville} onChange={this.handleChange} type="text" placeholder={t('formCity')} />

                          </div>
                        </div>

                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                          <div className="form-group">
                            <label htmlFor="fullName">{t('formCountry')}</label>
                            <input name="pays" className="form-control" disabled value={pays} type="text" placeholder={t('formCountry')} />

                          </div>
                        </div>

                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                          <div className="form-group">
                            <label htmlFor="fullName">{t('formPhone')}</label>
                            <input name="telephone" className="form-control" value={telephone} onChange={this.handleChange} type="text" placeholder={t('formPhone')} />

                          </div>
                        </div>

                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                          <div className="form-group">
                            <label htmlFor="fullName">{t('formNumberPiec')}</label>
                            <input name="numeroPiece" className="form-control" value={numeroPiece} onChange={this.handleChange} type="text" placeholder={t('formNumberPiec')} />

                          </div>
                        </div>

                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                          <div className="form-group">
                            <label htmlFor="fullName">{t('formDevi')}</label>
                            <input name="devise" className="form-control" disabled value={devise} onChange={this.handleChange} type="text" placeholder={t('formDevi')} />

                          </div>
                        </div>

                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                          <div className="form-group">
                            <label htmlFor="fullName">{t('formEmail')}</label>
                            <input name="email" className="form-control" value={email} onChange={this.handleChange} type="email" placeholder={t('formEmail')} />

                          </div>
                        </div>

                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                          <div className="form-group">
                            <label htmlFor="fullName">{t('menuUser')}</label>
                            <input name="username" className="form-control" disabled value={username} onChange={this.handleChange} type="email" placeholder={t('formEmail')} />

                          </div>
                        </div>

                      </div>

                      <hr />

                      <div className="row gutters">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                          <h6 className="mt-3 mb-2 text-primary">{t('profilTitleBankInfo')}</h6>
                        </div>

                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                          <div className="form-group">
                            <label htmlFor="fullName">{t('formAccountNumber')}</label>
                            <input disabled name="compteNumero" className="form-control" value={compteNumero} onChange={this.handleChange} type="text" placeholder={t('formAccountNumber')} />

                          </div>
                        </div>

                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                          <div className="form-group">
                            <label htmlFor="fullName">{t('formBankCod')}</label>
                            <input disabled name="codeBanque" className="form-control" value={codeBanque} onChange={this.handleChange} type="text" placeholder={t('formBankCod')} />

                          </div>
                        </div>


                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                          <div className="form-group">
                            <label htmlFor="fullName">{t('formIban')}</label>
                            <input disabled name="iban" className="form-control" value={iban} onChange={this.handleChange} type="text" placeholder={t('formIban')} />

                          </div>
                        </div>

                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                          <div className="form-group">
                            <label htmlFor="fullName">{t('formBic')}</label>
                            <input disabled name="bic" className="form-control" value={bic} onChange={this.handleChange} type="text" placeholder={t('formBic')} />

                          </div>
                        </div>

                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                          <div className="form-group">
                            <label htmlFor="fullName">{t('formCleRib')}</label>
                            <input disabled name="cleRib" className="form-control" value={cleRib} onChange={this.handleChange} type="text" placeholder={t('formCleRib')} />

                          </div>
                        </div>

                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                          <div className="form-group">
                            <label htmlFor="fullName">{t('formCodGuich')}</label>
                            <input disabled name="codeGuichet" className="form-control" value={codeGuichet} onChange={this.handleChange} type="text" placeholder={t('formCodGuich')} />

                          </div>
                        </div>


                      </div>
                      <div className="row gutters">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                          <div className="text-right">
                            {/*<button type="button" id="submit" name="submit" className="btn btn-secondary">Cancel</button>*/}
                            <button type="button" onClick={this.updateUserProfil} className="btn btn-primary ml-2">{t('ProfilButtonUpdate')}</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>



            <Snackbar
              open={alertSuccessMsg}
              autoHideDuration={6000}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}

            >
              <Alert severity="success">
                {successMsg}
              </Alert>
            </Snackbar>

            <Snackbar
              open={alertErrMsg}
              autoHideDuration={6000}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}

            >
              <Alert severity="error">
                {errMsg}
              </Alert>
            </Snackbar>

            <Snackbar
              open={alertInfoMsg}
              autoHideDuration={6000}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
              <Alert severity="info">
                {infoMsg}
              </Alert>
            </Snackbar>

            <Snackbar
              open={alertWarningMsg}
              autoHideDuration={6000}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
              <Alert severity="warning">
                {warningMsg}
              </Alert>
            </Snackbar>


          </body>
        }

        <Modal className="modal-dialog modal-lg" centered={true} scrollable={true} isOpen={isAddModalVisible}>

          <ModalHeader toggle={this.handleAddOpen}>

            <Row>
              <Col md={12}>
                <h5 style={{ color: "#1D75BB" }} >Ajouter Client </h5>
              </Col>
            </Row>
          </ModalHeader>

          <ModalBody /*style={{ maxHeight: '800px', maxWidth:'800px', overflow: 'auto' }}*/ toggle={this.handleAddOpen}>


            <Form>

              <Row>
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <h6 className="mb-2 text-primary">{t('profilTitlePersonalInfo')}</h6>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                  <div className="form-group">
                    <label htmlFor="fullName">{t('formTypeAccount')}</label>
                    <select name="typeCompte" value={typeCompte} onChange={this.handleChange} placeholder={t('formTypeAccount')} className="form-select form-select-sm w-100" aria-label=".form-select-sm example" >
                      <option selected value={typeCompte}>{t('defaultTypeCompte')}</option>
                    </select>
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                  <div className="form-group">
                    <label htmlFor="fullName">{t('formGender')}</label>
                    <select name="civilite" value={civilite} onChange={this.handleChange} className="form-select mt-2 mb-2 form-select-sm w-100" aria-label=".form-select-sm example">
                      <option value="" >{t('formGender')}</option>
                      <option value="Mr" selected>{t('civiliteMr')}</option>
                      <option value="Mme">{t('civiliteMme')}</option>

                    </select>
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                  <div className="form-group">
                    <label htmlFor="fullName">{t('formFirstName')}</label>
                    <input name="firstName" className="form-control" value={firstName} onChange={this.handleChange} type="text" placeholder={t('formFirstName')} />

                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                  <div className="form-group">
                    <label htmlFor="fullName">{t('formLastName')}</label>
                    <input name="lastName" className="form-control" value={lastName} onChange={this.handleChange} type="text" placeholder={t('formLastName')} />

                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                  <div className="form-group">
                    <label htmlFor="fullName">{t('formAddress')}</label>
                    <input name="adresse" className="form-control" value={adresse} onChange={this.handleChange} type="text" placeholder={t('formAddress')} />

                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                  <div className="form-group">
                    <label htmlFor="fullName">{t('formCity')}</label>
                    <input name="ville" className="form-control" value={ville} onChange={this.handleChange} type="text" placeholder={t('formCity')} />

                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                  <div className="form-group">
                    <label htmlFor="fullName">{t('formCountry')}</label>
                    <CountryDropdown
                      value={pays}
                      onChange={(val) => this.selectCountry(val)}
                      labelType="full"
                      style={{ width: '100%' }}
                      defaultOptionLabel={t('formCountry')}
                      className="mb-2"

                    />

                    {/*<input name="pays" className="form-control" disabled value={pays} type="text" placeholder={t('formCountry')} />
                      */}
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                  <div className="form-group">
                    <label htmlFor="fullName">{t('formPhone')}</label>
                    <input name="telephone" className="form-control" value={telephone} onChange={this.handleChange} type="text" placeholder={t('formPhone')} />

                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                  <div className="form-group">
                    <label htmlFor="fullName">{t('formNumberPiec')}</label>
                    <input name="numeroPiece" className="form-control" value={numeroPiece} onChange={this.handleChange} type="text" placeholder={t('formNumberPiec')} />

                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                  <div className="form-group">
                    <label htmlFor="fullName">{t('formDevi')}</label>
                    <select name="devise" className="form-select mt-2 mb-2 form-select-sm w-100" aria-label=".form-select-sm example" value={devise} onChange={this.handleChange} >
                      <option value="" >{t('formDevi')}</option>
                      <option value="€" selected>€</option>
                      <option value="$">$</option>

                    </select>
                    {/* <input name="devise" className="form-control" disabled value={devise} onChange={this.handleChange} type="text" placeholder={t('formDevi')} />
*/}
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                  <div className="form-group">
                    <label htmlFor="fullName">{t('formEmail')}</label>
                    <input name="email" className="form-control" value={email} onChange={this.handleChange} type="email" placeholder={t('formEmail')} />

                  </div>
                </div>

                {/*} <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                  <div className="form-group">
                    <label htmlFor="fullName">{t('menuUser')}</label>
                    <input name="username" className="form-control" disabled value={username} onChange={this.handleChange} type="email" placeholder={t('formEmail')} />

                  </div>
                </div>*/}

                <hr />


                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <h6 className="mt-3 mb-2 text-primary">{t('profilTitleBankInfo')}</h6>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                  <div className="form-group">
                    <label htmlFor="fullName">{t('formAccountNumber')}</label>
                    <input name="compteNumero" className="form-control" value={compteNumero} onChange={this.handleChange} type="text" placeholder={t('formAccountNumber')} />

                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                  <div className="form-group">
                    <label htmlFor="fullName">{t('formBankCod')}</label>
                    <input name="codeBanque" className="form-control" value={codeBanque} onChange={this.handleChange} type="text" placeholder={t('formBankCod')} />

                  </div>
                </div>


                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                  <div className="form-group">
                    <label htmlFor="fullName">{t('formIban')}</label>
                    <input name="iban" className="form-control" value={iban} onChange={this.handleChange} type="text" placeholder={t('formIban')} />

                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                  <div className="form-group">
                    <label htmlFor="fullName">{t('formBic')}</label>
                    <input name="bic" className="form-control" value={bic} onChange={this.handleChange} type="text" placeholder={t('formBic')} />

                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                  <div className="form-group">
                    <label htmlFor="fullName">{t('formCleRib')}</label>
                    <input name="cleRib" className="form-control" value={cleRib} onChange={this.handleChange} type="text" placeholder={t('formCleRib')} />

                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                  <div className="form-group">
                    <label htmlFor="fullName">{t('formCodGuich')}</label>
                    <input name="codeGuichet" className="form-control" value={codeGuichet} onChange={this.handleChange} type="text" placeholder={t('formCodGuich')} />

                  </div>
                </div>

                <hr />

                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <h6 className="mt-3 mb-2 text-primary">Codes Transfert</h6>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                  <div className="form-group">
                    <label htmlFor="fullName">{t('formTransfertCode1')}</label>
                    <input name="codeTransfert1" className="form-control" value={codeTransfert1} onChange={this.handleChange} type="text" placeholder={t('formTransfertCode1')} />

                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                  <div className="form-group">
                    <label htmlFor="fullName">{t('formTransfertCode2')}</label>
                    <input name="codeTransfert2" className="form-control" value={codeTransfert2} onChange={this.handleChange} type="text" placeholder={t('formTransfertCode2')} />

                  </div>
                </div>


                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                  <div className="form-group">
                    <label htmlFor="fullName">{t('formTransfertCode3')}</label>
                    <input name="codeTransfert3" className="form-control" value={codeTransfert3} onChange={this.handleChange} type="text" placeholder={t('formTransfertCode3')} />

                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                  <div className="form-group">
                    <label htmlFor="fullName">Solde</label>
                    <input name="solde" className="form-control" value={solde} onChange={this.handleChange} type="number" placeholder="0" />

                  </div>
                </div>

              </Row>

            </Form>

          </ModalBody>
          <ModalFooter>
            <div className="text-right">
              {//commande && commande.etatCmde === "EN COURS" &&
                <Button color="primary" onClick={this.addUserProfil}>Ajouter</Button>
              }
              <Button className="ml-2" color="secondary" onClick={this.handleAddOpen}>{t('modalCloseButton')}</Button>
            </div>


          </ModalFooter>
        </Modal>


        <Modal className="modal-dialog modal-lg" centered={true} scrollable={true} isOpen={openDetailModal}>

          <ModalHeader toggle={this.handleDetailClose}>

            <Row>
              <Col md={12}>
                <h5 style={{ color: "#1D75BB" }} >{t('modalUserDetailsTitle')} </h5>

              </Col>
            </Row>
          </ModalHeader>

          <ModalBody /*style={{ maxHeight: '800px', maxWidth:'800px', overflow: 'auto' }}*/ toggle={this.handleDetailClose}>


            <Row>
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <h6 className="mb-2 text-primary">{t('profilTitlePersonalInfo')}</h6>
              </div>

              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <div className="form-group">
                  <label htmlFor="fullName">{t('formTypeAccount')}</label>
                  <select disabled name="typeCompte" value={typeCompte} onChange={this.handleChange} placeholder={t('formTypeAccount')} className="form-select form-select-sm w-100" aria-label=".form-select-sm example" >
                    <option selected value={typeCompte}>{t('defaultTypeCompte')}</option>
                  </select>
                </div>
              </div>

              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <div className="form-group">
                  <label htmlFor="fullName">{t('formGender')}</label>
                  <select disabled name="civilite" value={civilite} onChange={this.handleChange} className="form-select mt-2 mb-2 form-select-sm w-100" aria-label=".form-select-sm example">
                    <option value="" >{t('formGender')}</option>
                    <option value="Mr" selected>{t('civiliteMr')}</option>
                    <option value="Mme">{t('civiliteMme')}</option>

                  </select>
                </div>
              </div>

              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <div className="form-group">
                  <label htmlFor="fullName">{t('formFirstName')}</label>
                  <input disabled name="firstName" className="form-control" value={firstName} onChange={this.handleChange} type="text" placeholder={t('formFirstName')} />

                </div>
              </div>

              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <div className="form-group">
                  <label htmlFor="fullName">{t('formLastName')}</label>
                  <input disabled name="lastName" className="form-control" value={lastName} onChange={this.handleChange} type="text" placeholder={t('formLastName')} />

                </div>
              </div>

              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <div className="form-group">
                  <label htmlFor="fullName">{t('formAddress')}</label>
                  <input disabled name="adresse" className="form-control" value={adresse} onChange={this.handleChange} type="text" placeholder={t('formAddress')} />

                </div>
              </div>

              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <div className="form-group">
                  <label htmlFor="fullName">{t('formCity')}</label>
                  <input disabled name="ville" className="form-control" value={ville} onChange={this.handleChange} type="text" placeholder={t('formCity')} />

                </div>
              </div>

              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <div className="form-group">
                  <label htmlFor="fullName">{t('formCountry')}</label>
                  <input disabled name="pays" className="form-control" disabled value={pays} type="text" placeholder={t('formCountry')} />

                </div>
              </div>

              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <div className="form-group">
                  <label htmlFor="fullName">{t('formPhone')}</label>
                  <input disabled name="telephone" className="form-control" value={telephone} onChange={this.handleChange} type="text" placeholder={t('formPhone')} />

                </div>
              </div>

              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <div className="form-group">
                  <label htmlFor="fullName">{t('formNumberPiec')}</label>
                  <input disabled name="numeroPiece" className="form-control" value={numeroPiece} onChange={this.handleChange} type="text" placeholder={t('formNumberPiec')} />

                </div>
              </div>

              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <div className="form-group">
                  <label htmlFor="fullName">{t('formDevi')}</label>
                  <input disabled name="devise" className="form-control" disabled value={devise} onChange={this.handleChange} type="text" placeholder={t('formDevi')} />

                </div>
              </div>

              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <div className="form-group">
                  <label htmlFor="fullName">{t('formEmail')}</label>
                  <input disabled name="email" className="form-control" value={email} onChange={this.handleChange} type="email" placeholder={t('formEmail')} />

                </div>
              </div>

              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <div className="form-group">
                  <label htmlFor="fullName">{t('menuUser')}</label>
                  <input disabled name="username" className="form-control" disabled value={username} onChange={this.handleChange} type="email" placeholder={t('formEmail')} />

                </div>
              </div>

              <hr />


              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <h6 className="mt-3 mb-2 text-primary">{t('profilTitleBankInfo')}</h6>
              </div>

              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <div className="form-group">
                  <label htmlFor="fullName">{t('formAccountNumber')}</label>
                  <input disabled name="compteNumero" className="form-control" value={compteNumero} onChange={this.handleChange} type="text" placeholder={t('formAccountNumber')} />

                </div>
              </div>

              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <div className="form-group">
                  <label htmlFor="fullName">{t('formBankCod')}</label>
                  <input disabled name="codeBanque" className="form-control" value={codeBanque} onChange={this.handleChange} type="text" placeholder={t('formBankCod')} />

                </div>
              </div>


              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <div className="form-group">
                  <label htmlFor="fullName">{t('formIban')}</label>
                  <input disabled name="iban" className="form-control" value={iban} onChange={this.handleChange} type="text" placeholder={t('formIban')} />

                </div>
              </div>

              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <div className="form-group">
                  <label htmlFor="fullName">{t('formBic')}</label>
                  <input disabled name="bic" className="form-control" value={bic} onChange={this.handleChange} type="text" placeholder={t('formBic')} />

                </div>
              </div>

              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <div className="form-group">
                  <label htmlFor="fullName">{t('formCleRib')}</label>
                  <input disabled name="cleRib" className="form-control" value={cleRib} onChange={this.handleChange} type="text" placeholder={t('formCleRib')} />

                </div>
              </div>

              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <div className="form-group">
                  <label htmlFor="fullName">{t('formCodGuich')}</label>
                  <input disabled name="codeGuichet" className="form-control" value={codeGuichet} onChange={this.handleChange} type="text" placeholder={t('formCodGuich')} />

                </div>
              </div>

              <hr />

              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <h6 className="mt-3 mb-2 text-primary">Codes Transfert</h6>
              </div>

              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <div className="form-group">
                  <label htmlFor="fullName">{t('formTransfertCode1')}</label>
                  <input disabled name="codeTransfert1" className="form-control" value={codeTransfert1} onChange={this.handleChange} type="text" placeholder={t('formTransfertCode1')} />

                </div>
              </div>

              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <div className="form-group">
                  <label htmlFor="fullName">{t('formTransfertCode2')}</label>
                  <input disabled name="codeTransfert2" className="form-control" value={codeTransfert2} onChange={this.handleChange} type="text" placeholder={t('formTransfertCode2')} />

                </div>
              </div>


              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <div className="form-group">
                  <label htmlFor="fullName">{t('formTransfertCode3')}</label>
                  <input disabled name="codeTransfert3" className="form-control" value={codeTransfert3} onChange={this.handleChange} type="text" placeholder={t('formTransfertCode3')} />

                </div>
              </div>

              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <div className="form-group">
                  <label htmlFor="fullName">Solde</label>
                  <input disabled name="solde" className="form-control" value={solde} onChange={this.handleChange} type="number" placeholder="0" />

                </div>
              </div>

            </Row>

          </ModalBody>
          <ModalFooter>
            <div className="text-right">
              {/*commande && commande.etatCmde === "EN COURS" &&
                <Button color="primary" onClick={this.addTransfert}>{t('modalTransfertButton')}</Button>
            */}
              <Button className="ml-2" color="secondary" onClick={this.handleDetailClose}>{t('modalCloseButton')}</Button>
            </div>


          </ModalFooter>
        </Modal>


        <Modal className="modal-dialog modal-lg" centered={true} scrollable={true} isOpen={openUpdateModal}>

          <ModalHeader toggle={this.handleUpdateClose}>

            <Row>
              <Col md={12}>
                <h5 style={{ color: "#1D75BB" }} >{t('modalUserModifierTitle')} </h5>

              </Col>
            </Row>
          </ModalHeader>

          <ModalBody /*style={{ maxHeight: '800px', maxWidth:'800px', overflow: 'auto' }}*/ toggle={this.handleDetailClose}>


            <Row>
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <h6 className="mb-2 text-primary">{t('profilTitlePersonalInfo')}</h6>
              </div>

              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <div className="form-group">
                  <label htmlFor="fullName">{t('formTypeAccount')}</label>
                  <select name="typeCompte" value={typeCompte} onChange={this.handleChange} placeholder={t('formTypeAccount')} className="form-select form-select-sm w-100" aria-label=".form-select-sm example" >
                    <option selected value={typeCompte}>{t('defaultTypeCompte')}</option>
                  </select>
                </div>
              </div>

              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <div className="form-group">
                  <label htmlFor="fullName">{t('formGender')}</label>
                  <select name="civilite" value={civilite} onChange={this.handleChange} className="form-select mt-2 mb-2 form-select-sm w-100" aria-label=".form-select-sm example">
                    <option value="" selected>{t('formGender')}</option>
                    <option value="Mr">{t('civiliteMr')}</option>
                    <option value="Mme">{t('civiliteMme')}</option>

                  </select>
                </div>
              </div>

              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <div className="form-group">
                  <label htmlFor="fullName">{t('formFirstName')}</label>
                  <input name="firstName" className="form-control" value={firstName} onChange={this.handleChange} type="text" placeholder={t('formFirstName')} />

                </div>
              </div>

              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <div className="form-group">
                  <label htmlFor="fullName">{t('formLastName')}</label>
                  <input name="lastName" className="form-control" value={lastName} onChange={this.handleChange} type="text" placeholder={t('formLastName')} />

                </div>
              </div>

              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <div className="form-group">
                  <label htmlFor="fullName">{t('formAddress')}</label>
                  <input name="adresse" className="form-control" value={adresse} onChange={this.handleChange} type="text" placeholder={t('formAddress')} />

                </div>
              </div>

              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <div className="form-group">
                  <label htmlFor="fullName">{t('formCity')}</label>
                  <input name="ville" className="form-control" value={ville} onChange={this.handleChange} type="text" placeholder={t('formCity')} />

                </div>
              </div>

              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <div className="form-group">
                  <label htmlFor="fullName">{t('formCountry')}</label>
                  <input name="pays" className="form-control" disabled value={pays} type="text" placeholder={t('formCountry')} />

                </div>
              </div>

              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <div className="form-group">
                  <label htmlFor="fullName">{t('formPhone')}</label>
                  <input name="telephone" className="form-control" value={telephone} onChange={this.handleChange} type="text" placeholder={t('formPhone')} />

                </div>
              </div>

              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <div className="form-group">
                  <label htmlFor="fullName">{t('formNumberPiec')}</label>
                  <input name="numeroPiece" className="form-control" value={numeroPiece} onChange={this.handleChange} type="text" placeholder={t('formNumberPiec')} />

                </div>
              </div>

              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <div className="form-group">
                  <label htmlFor="fullName">{t('formDevi')}</label>
                  <input name="devise" className="form-control" disabled value={devise} onChange={this.handleChange} type="text" placeholder={t('formDevi')} />

                </div>
              </div>

              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <div className="form-group">
                  <label htmlFor="fullName">{t('formEmail')}</label>
                  <input name="email" className="form-control" value={email} onChange={this.handleChange} type="email" placeholder={t('formEmail')} />

                </div>
              </div>

              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <div className="form-group">
                  <label htmlFor="fullName">{t('menuUser')}</label>
                  <input name="username" className="form-control" disabled value={username} onChange={this.handleChange} type="email" placeholder={t('formEmail')} />

                </div>
              </div>

              <hr />


              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <h6 className="mt-3 mb-2 text-primary">{t('profilTitleBankInfo')}</h6>
              </div>

              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <div className="form-group">
                  <label htmlFor="fullName">{t('formAccountNumber')}</label>
                  <input name="compteNumero" className="form-control" value={compteNumero} onChange={this.handleChange} type="text" placeholder={t('formAccountNumber')} />

                </div>
              </div>

              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <div className="form-group">
                  <label htmlFor="fullName">{t('formBankCod')}</label>
                  <input name="codeBanque" className="form-control" value={codeBanque} onChange={this.handleChange} type="text" placeholder={t('formBankCod')} />

                </div>
              </div>


              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <div className="form-group">
                  <label htmlFor="fullName">{t('formIban')}</label>
                  <input name="iban" className="form-control" value={iban} onChange={this.handleChange} type="text" placeholder={t('formIban')} />

                </div>
              </div>

              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <div className="form-group">
                  <label htmlFor="fullName">{t('formBic')}</label>
                  <input name="bic" className="form-control" value={bic} onChange={this.handleChange} type="text" placeholder={t('formBic')} />

                </div>
              </div>

              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <div className="form-group">
                  <label htmlFor="fullName">{t('formCleRib')}</label>
                  <input name="cleRib" className="form-control" value={cleRib} onChange={this.handleChange} type="text" placeholder={t('formCleRib')} />

                </div>
              </div>

              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <div className="form-group">
                  <label htmlFor="fullName">{t('formCodGuich')}</label>
                  <input name="codeGuichet" className="form-control" value={codeGuichet} onChange={this.handleChange} type="text" placeholder={t('formCodGuich')} />

                </div>
              </div>

              <hr />

              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <h6 className="mt-3 mb-2 text-primary">Codes Transfert</h6>
              </div>

              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <div className="form-group">
                  <label htmlFor="fullName">{t('formTransfertCode1')}</label>
                  <input name="codeTransfert1" className="form-control" value={codeTransfert1} onChange={this.handleChange} type="text" placeholder={t('formTransfertCode1')} />

                </div>
              </div>

              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <div className="form-group">
                  <label htmlFor="fullName">{t('formTransfertCode2')}</label>
                  <input name="codeTransfert2" className="form-control" value={codeTransfert2} onChange={this.handleChange} type="text" placeholder={t('formTransfertCode2')} />

                </div>
              </div>


              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <div className="form-group">
                  <label htmlFor="fullName">{t('formTransfertCode3')}</label>
                  <input name="codeTransfert3" className="form-control" value={codeTransfert3} onChange={this.handleChange} type="text" placeholder={t('formTransfertCode3')} />

                </div>
              </div>

              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <div className="form-group">
                  <label htmlFor="fullName">Solde</label>
                  <input name="solde" className="form-control" value={solde} onChange={this.handleChange} type="number" placeholder="0" />

                </div>
              </div>
            </Row>

          </ModalBody>
          <ModalFooter>
            <div className="text-right">
              {//commande && commande.etatCmde === "EN COURS" &&
                <Button color="primary" onClick={this.updateUserProfil}>{t('ProfilButtonUpdate')}</Button>
              }
              {desactiverCompte === true &&
                <Button className="btn btn-success ml-2 " onClick={this.enableUser} variant="contained">Activer</Button>
              }
              {
                desactiverCompte === false &&
                <Button className="btn btn-danger ml-2 " onClick={this.disableUser} variant="contained">Désactiver</Button>
              }
              <Button className="ml-2" color="secondary" onClick={this.handleUpdateClose}>{t('modalCloseButton')}</Button>
            </div>


          </ModalFooter>
        </Modal>

        <Snackbar
          open={alertSuccessMsg}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}

        >
          <Alert severity="success">
            {successMsg}
          </Alert>
        </Snackbar>

        <Snackbar
          open={alertErrMsg}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}

        >
          <Alert severity="error">
            {errMsg}
          </Alert>
        </Snackbar>

        <Snackbar
          open={alertInfoMsg}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <Alert severity="info">
            {infoMsg}
          </Alert>
        </Snackbar>

        <Snackbar
          open={alertWarningMsg}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <Alert severity="warning">
            {warningMsg}
          </Alert>
        </Snackbar>
      </>


    );
  }
}

export default withTranslation()(Transfert);
