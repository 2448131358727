
import { handleResponse } from './handle-response';
import { authHeader } from './auth-header'
import { base_url } from '../env'


export const services = {

    getTransfert,
    addTransfert,
    deleteTransfert,
    updateTransfert,
    verify1,
    verify2,
    verify3,
    validerTransfert,


    addUser,
    deleteUser,
    updateUser,
    disableUser,
    enableUser,
    getCurrentUser,
    getUsers,


    updateProfil,
    getProfil,
    updatePassword,


    getDashappro,
    getDashRepart,
    getDashArticleStock,
    getDashArticleStockAll,
    getDashArticleSale,
    getDashFactureAgence,
    getDashFactureAll,

    getStart,
    
}


//TRANSFERT
function addTransfert(req) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(req)
    };
    return fetch(`${base_url}/api/transfert`, requestOptions)
        .then(handleResponse);
}

function getTransfert(search = '') {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${base_url}/api/transfert?search=${search}`, requestOptions)
        .then(handleResponse);
}

function deleteTransfert(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };
    return fetch(`${base_url}/api/transfert/${id}`, requestOptions)
        .then(handleResponse);
}
function updateTransfert(id, req) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(req)
    };
    return fetch(`${base_url}/api/transfert/${id}`, requestOptions)
        .then(handleResponse);
}

function verify1(req) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(req)
    };
    return fetch(`${base_url}/api/transfert/verify1`, requestOptions)
        .then(handleResponse);
}

function verify2(req) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(req)
    };
    return fetch(`${base_url}/api/transfert/verify2`, requestOptions)
        .then(handleResponse);
}

function verify3(req) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(req)
    };
    return fetch(`${base_url}/api/transfert/verify3`, requestOptions)
        .then(handleResponse);
}

function validerTransfert(id, user) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        //body: JSON.stringify(req)
    };
    return fetch(`${base_url}/api/transfert/valider/?id=${id}&user=${user}`, requestOptions)
        .then(handleResponse);
}

// START
function getStart( ) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${base_url}/`, requestOptions)
        .then(handleResponse);
}

// DASHBOARD
function getDashappro( ) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${base_url}/api/dash/appro`, requestOptions)
        .then(handleResponse);
}

function getDashRepart( ) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${base_url}/api/dash/repart`, requestOptions)
        .then(handleResponse);
}

function getDashArticleStock( ) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${base_url}/api/dash/article/stock`, requestOptions)
        .then(handleResponse);
}

function getDashArticleStockAll( ) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${base_url}/api/dash/article/stock/all`, requestOptions)
        .then(handleResponse);
}

function getDashArticleSale( ) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${base_url}/api/dash/article/sale`, requestOptions)
        .then(handleResponse);
}

function getDashFactureAgence( ) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${base_url}/api/dash/facture/agence`, requestOptions)
        .then(handleResponse);
}



function getDashFactureAll( ) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${base_url}/api/dash/facture/all`, requestOptions)
        .then(handleResponse);
}


//USER 2

function getUsers(search = '') {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${base_url}/api/users?search=${search}`, requestOptions)
        .then(handleResponse);
}

function addUser(req) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(req)
    };
    return fetch(`${base_url}/api/users`, requestOptions)
        .then(handleResponse);
}

function disableUser(user) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify()
    };
    return fetch(`${base_url}/api/users/disable/${user}`, requestOptions)
        .then(handleResponse);
}
function enableUser(user) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify()
    };
    return fetch(`${base_url}/api/users/enable/${user}`, requestOptions)
        .then(handleResponse);
}

function getCurrentUser() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${base_url}/api/users/current`, requestOptions)
        .then(handleResponse);
}

//USER
function deleteUser(code) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
    };
    return fetch(`${base_url}/api/users/${code}`, requestOptions)
        .then(handleResponse);
}

function updateUser(idUser, req) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(req)
    };
    return fetch(`${base_url}/api/users/${idUser}`, requestOptions)
        .then(handleResponse);
}



//PROFIL
function updateProfil(req) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(req)
    };
    return fetch(`${base_url}/api/profil`, requestOptions)
        .then(handleResponse);
}

function getProfil() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${base_url}/api/profil`, requestOptions)
        .then(handleResponse);
}

function updatePassword(req) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(req)
    };
    return fetch(`${base_url}/api/profil/password`, requestOptions)
        .then(handleResponse);
}





