import React  from 'react'
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
 
import { useTranslation } from "react-i18next";


export default function Accordions({items}) {

    const { t, i18n } = useTranslation()


    const faqdata= [
        {
            title: t('faqTitle01'),
            content: t('faqDescription01')
        },
        {
            title: t('faqTitle02'),
            content: t('faqDescription02')
        },
        {
            title: t('faqTitle03'),
            content: t('faqDescription03')
        },
        {
            title: t('faqTitle04'),
            content: t('faqDescription04')
        },
        {
            title: t('faqTitle1'),
            content: t('faqDescription1')
        },
        {
            title: t('faqTitle2'),
            content: t('faqDescription2')
        },
        {
            title: t('faqTitle3'),
            content: t('faqDescription3')
        },
        {
            title: t('faqTitle4'),
            content: t('faqDescription4')
        },
        {
            title: t('faqTitle5'),
            content: t('faqDescription5')
        },
         
    ]


    return (
        <div className="accordion-wrapper">
            <Accordion>
                {faqdata.map((item, index) => {
                    return (
                        <AccordionItem key={index}>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    {item.title}
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>{item.content}</p>
                            </AccordionItemPanel>
                        </AccordionItem>
                    )
                })}
            </Accordion>
        </div>
    )
}
