import React  from 'react'
import { FiMapPin, FiPhone, FiChevronRight } from 'react-icons/fi'
import { FaEnvelope } from 'react-icons/fa'
import sectiondata from "../../store/store";

import { useTranslation } from "react-i18next";


export default function ContactSidebar() {

    const { t, i18n } = useTranslation()

    return (
        <aside className="contact-sidebar">
            { 
                <h1>{t('contactSidebarTitle')}</h1>
             }
            { sectiondata.contactdata.sidebar.img ?
                <img src={sectiondata.contactdata.sidebar.img} alt="Contact Sidebar" />
            : '' }
            { 
                <p>{t('descrpSecService')}</p>
             }
            <div className="contact-sidebar-infos">

                { sectiondata.contactdata.sidebar.address ?
                    <div className="item d-flex">
                        <div className="icon">
                            <span><FiMapPin /></span>
                        </div>
                        <div className="details">
                            <h3>{t('contactSidebarAdresse')}</h3>
                            <span>{t('adresse')}</span>
                        </div>
                    </div>
                : '' }

                { sectiondata.contactdata.sidebar.phonenum || sectiondata.contactdata.sidebar.phonenum2 ?
                    <div className="item d-flex">
                        <div className="icon">
                            <span><FiPhone /></span>
                        </div>
                        <div className="details">
                            <h3>{t('contactSidebarPhone')}</h3>
                            { sectiondata.contactdata.sidebar.phonenum ?
                                <span>{t('contactSidebarPhone1')}: {t('phone1')}</span>
                            : '' }
                            { sectiondata.contactdata.sidebar.phonenum2 ?
                                <span>{t('contactSidebarPhone2')}: {t('phone2')}</span>
                            : '' }
                        </div>
                    </div>
                : '' }

                { sectiondata.contactdata.sidebar.email || sectiondata.contactdata.sidebar.email2 ?
                    <div className="item d-flex">
                        <div className="icon">
                            <span><FaEnvelope /></span>
                        </div>
                        <div className="details">
                            <h3>{t('contactSidebarMail')}</h3>
                            { sectiondata.contactdata.sidebar.email ?
                                <span>{t('email1')}</span>
                            : '' }
                            { sectiondata.contactdata.sidebar.email2 ?
                                <span>{t('email2')}</span>
                            : '' }
                        </div>
                    </div>
                : '' }
            </div>

            { sectiondata.contactdata.sidebar.button ?
                <button className="theme-button" type="submit">{t('tabSectionNavButton')} <FiChevronRight className="icon" /></button>
            : '' }
        </aside>
    )
}
