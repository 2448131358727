import React from 'react'
import { Link } from 'react-router-dom'
import OwlCarousel from 'react-owl-carousel';
import '../../assets/css/owl.carousel.css';
import '../../assets/css/owl.theme.default.min.css';
import { FiChevronRight } from 'react-icons/fi';

import { useTranslation } from "react-i18next";

import herosliderbg from '../../assets/images/slider-img1.jpg'; // 1920x1024
import herosliderbg2 from '../../assets/images/slider-img2.jpg'; // 1920x1024
import herosliderbg3 from '../../assets/images/slider-img3.jpg'; // 1920x1024



export default function HeroSlider({ slideItems }) {
    const { t, i18n } = useTranslation();

    const heroSlider1= [
        {
            title: t('slideTitle1'),
            description: t('slideDescription1'),
            slideTitleDescript: t('slideTitleDescript1'),
            button1: t('slideButton1'),
            button2: t('slideButton2'),
            button1url: '/sign-up',
            button2url: '/services',
            bg: herosliderbg,
            col: '8'
        },
        {
            title: t('slideTitle2'),
            description: t('slideDescription2'),
            slideTitleDescript: t('slideTitleDescript2'),
            button1: t('slideButton1'),
            button2: t('slideButton2'),
            button1url: '/sign-up',
            button2url: '/services',
            bg: herosliderbg2,
            col: '8 offset-2 text-center'
        },
        {
            title: t('slideTitle3'),
            description: t('slideDescription3'),
            slideTitleDescript: t('slideTitleDescript3'),
            button1: t('slideButton1'),
            button2: t('slideButton2'),
            button1url: '/sign-up',
            button2url: '/services',
            bg: herosliderbg3,
            col: '8'
        }
    ];


    return (
        <section className="hero-slider-wrapper">
            <OwlCarousel className="owl-theme" loop={true} autoplay={true} margin={10} dots={false} nav items={1} >
                
                {heroSlider1.map((item, index) => {
                    return (
                        <div key={index} className="hero-slider-item" style={{backgroundImage: 'url('+item.bg+')'}}>
                            <div className="container">
                                <div className="row">
                                    <div className={'col-' + item.col}>
                                        <div className="hero-slider-content">
                                            { item.title ? <h1>{item.title}</h1> : ''}
                                            <p> {item.slideTitleDescript}</p>
                                            { item.description ? <p>{item.description}</p> : '' }
                                            <div className="hero-slider-btns">
                                                {
                                                    item.button1?
                                                        <Link to={item.button1url} className="theme-button">{item.button1} <FiChevronRight className="icon" /></Link>
                                                    : ''
                                                }
                                                {
                                                    item.button2?
                                                        <Link to={item.button2url} className="theme-button">{item.button2} <FiChevronRight className="icon" /></Link>
                                                    : ''
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })}

            </OwlCarousel>
        </section >
    )
}
