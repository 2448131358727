import React  from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import SectionsTitle from '../common/SectionsTitle'
import { Link } from 'react-router-dom'
import { FiChevronRight } from 'react-icons/fi'


import img1 from '../../assets/images/service1.jpg'; // 555x354
import img2 from '../../assets/images/service2.jpg'; // 555x354
import img3 from '../../assets/images/service3.jpg'; // 555x354
import img4 from '../../assets/images/service4.jpg'; // 555x354
import img5 from '../../assets/images/service5.jpg'; // 555x354
import img6 from '../../assets/images/service6.jpg'; // 555x354


import { useTranslation } from "react-i18next";


export default function SimpleTab({secTitle, secSubtitle, tabsnav, tabslist}) {

    const { t, i18n } = useTranslation()

    const Tabdata= {
            tab1: {
                secTitle: t('tabSectionTitle'),
                secSubtitle: t('tabSectionSubtile'),
                tabsnav: [
                    {
                        title: t('tabSectionNavTitle1')
                    },
                    {
                        title: t('tabSectionNavTitle2')
                    },
                    {
                        title: t('tabSectionNavTitle3')
                    },
                    {
                        title: t('tabSectionNavTitle4')
                    },
                    {
                        title: t('tabSectionNavTitle5')
                    },
                    {
                        title: t('tabSectionNavTitle6')
                    }
                ],
                items: [
                    {
                        img: img1,
                        desc: t('tabSectionNavDescript1'),
                        button: t('tabSectionNavButton'),
                        buttonurl: '/demande',
                    },
                    {
                        img: img2,
                        desc: t('tabSectionNavDescript2'),
                        button: t('tabSectionNavButton'),
                        img_position: "img_right",
                        buttonurl: '/demande',
                    },
                    {
                        img: img3,
                        desc: t('tabSectionNavDescript3'),
                        button: t('tabSectionNavButton'),
                        buttonurl: '/demande',
                    },
                    {
                        img: img4,
                        desc: t('tabSectionNavDescript4'),
                        button: t('tabSectionNavButton'),
                        img_position: "img_right",
                        buttonurl: '/demande',
                    },
                    {
                        img: img5,
                        desc: t('tabSectionNavDescript5'),
                        button: t('tabSectionNavButton'),
                        //img_position: "img_right",
                        buttonurl: '/demande',
                    },
                    {
                        img: img6,
                        desc: t('tabSectionNavDescript6'),
                        button: t('tabSectionNavButton'),
                        img_position: "img_right",
                        buttonurl: '/demande',
                    }
                ]
            },
            
        }

    return (
        <section className="tabs-wrapper">
            <div className="container">
                <div className="row text-center">
                    <div className="col-12">
                      
                        <div className="sec-heading">
                <p className="sec__meta">{Tabdata.tab1.secSubtitle}</p>
                <h2 className="sec__title">
                    {Tabdata.tab1.secTitle}
                </h2>
            </div>
                    </div>
                </div>


                <div className="tabs-content-wrap">
                    <Tabs>
                        <TabList className="tabs-nav">
                            {Tabdata && Tabdata.tab1 && Tabdata.tab1.tabsnav.map((item, index) => {
                                return <Tab key={index}>{item.title}</Tab>
                            })}
                        </TabList>
                        {Tabdata && Tabdata.tab1 && Tabdata.tab1.items.map((tab, index) => {
                            return (
                                <TabPanel className="tabs-content" key={index}>
                                    <div className={'tabs-content-flex ' + tab.img_position}>
                                        {tab.img?
                                            <div className="tabs-left">
                                                <img src={tab.img} alt="Tabs" />
                                            </div> : ''
                                        }
                                        <div className="tabs-right">
                                            {tab.desc?
                                                <p className="tabs-desc">
                                                    {tab.desc}
                                                </p> : ''
                                            }
                                            {tab.button?
                                                <Link to={tab.buttonurl} className="theme-button">{tab.button} <FiChevronRight className="icon" /></Link>
                                                : ''
                                            }
                                        </div>
                                    </div>
                                </TabPanel>
                            )
                        })}
                    </Tabs>
                </div>
            </div>
        </section>
    )
}
