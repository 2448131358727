import React  from 'react'
import OwlCarousel from 'react-owl-carousel';
import { TiStarFullOutline, TiStarHalfOutline } from 'react-icons/ti'
import { IoIosQuote } from 'react-icons/io'
import '../../assets/css/owl.carousel.css';
import '../../assets/css/owl.theme.default.min.css';

import { useTranslation } from "react-i18next";
import img1 from '../../assets/images/about-img.jpg'; // 458x315

import test1 from '../../assets/images/test-1.jpg'; // 458x315
import test2 from '../../assets/images/test-2.jpg'; // 458x315
import test3 from '../../assets/images/test-3.jpg'; // 458x315
import test4 from '../../assets/images/test-4.jpg'; // 458x315
import test5 from '../../assets/images/test-5.jpg'; // 458x315

import imgUser1 from '../../assets/images/testi-img11.jpg'; // 458x315
import imgUser4 from '../../assets/images/testi-img23.jpg'; // 458x315
import imgUser2 from '../../assets/images/testi-img22.jpg'; // 458x315
import imgUser3 from '../../assets/images/testi-img10.jpg'; // 458x315





const responsive = {
    0: {
        items: 1,
    },
    450: {
        items: 1
    },
    600: {
        items: 1
    },
    1000: {
        items: 2
    }
}
export default function Testimonial({imgshapes, slideitems}) {

    const { t, i18n } = useTranslation()

    const testimonial= [
                {
                    name: "Lara Croft",
                    city: "A leading IT company",
                    img: imgUser4,
                    desc: t('testimonialDescrpt1'),
                },
                {
                    name: "Mark Doe",
                    city: "CEO, Brick Consulting",
                    img: imgUser2,
                    desc: t('testimonialDescrpt2')
                },
                {
                    name: "Rizon Pet",
                    city: "-",
                    img: imgUser3,
                    desc: t('testimonialDescrpt3'),
                }
            ]
    
    const imagesshape= [
        {
            img: test1
        },
        {
            img: test2
        },
        {
            img: test3
        },
        {
            img: test4
        },
        {
            img: test5
        },
        {
            img: imgUser1
        },
        {
            img: imgUser2
        },
        {
            img: imgUser3
        }
    ]

    return (
        <>
            {imagesshape.map((item, index) => {
                return <img key={index} className="shape_img" src={item.img} alt="Testimonial" />
            })}
            <div className="container">
                <OwlCarousel
                    className="owl-theme"
                    loop={true}
                    autoplay={true}
                    margin={10}
                    dots={true}
                    nav={false}
                    responsiveClass={true}
                    responsive={responsive}
                >
                    {testimonial.map((item, index) => {

                        return (
                            <div className="testimonial-items" key={index}>
                                <p className="desc">{item.desc} <IoIosQuote className="quote_icon" /></p>
                                <div className="testimonial-bottom-flex">
                                    <img src={item.img} alt="Testimonial" />
                                    <div className="designation">
                                        <h3 className="name">{item.name}</h3>
                                        <p className="city">{item.city}</p>
                                        <ul className="stars">
                                            <TiStarFullOutline />
                                            <TiStarFullOutline />
                                            <TiStarFullOutline />
                                            <TiStarFullOutline />
                                            <TiStarHalfOutline />
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        )
                    })}

                </OwlCarousel>
            </div>
        </>
    )
}
