import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import SectionsTitle from '../common/SectionsTitle'
import { FiChevronRight } from 'react-icons/fi'
import ModalVideo from 'react-modal-video'
import Progressbar from '../other/Progressbar'
import sectiondata from "../../store/store";

import ContenuAbout from './ContenuAbout'

export default class About extends Component {
    constructor() {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }

    openModal() {
        this.setState({ isOpen: true })
    }
    render() {
        return (
            
            <ContenuAbout/>
        )
    }
}


