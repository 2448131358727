import {Link} from "react-router-dom";
import {FiPhone} from "react-icons/fi";
import React from "react";

import { useTranslation } from "react-i18next";


export default function TeamNeedHelp({title, content, button, buttonurl}) {

    const { t, i18n } = useTranslation()

    return (
        <div className="sidebar-widget sidebar-contact-form">
            {t('faqRightSidebarTitle1')?
                <h4>{t('faqRightSidebarTitle1')}</h4>
            : ''}
            {t('faqRightSidebarDescript')?
                <p>{t('faqRightSidebarDescript')}</p>
            : ''}
            {button?
                <Link to={buttonurl} className="theme-button">{t('btnContactHome')} <FiPhone className="icon" /></Link>
            : ''}
        </div>
    )
}
