import React  from 'react'
import { FiChevronRight } from 'react-icons/fi'
import SectionsTitle from "../components/common/SectionsTitle";
import { Link } from 'react-router-dom';

import {
    FiBarChart,
    FiBarChart2,
    FiBriefcase, FiCheckCircle,
    
    FiLock,
    FiMonitor,
    FiTarget,
    FiUserCheck
} from "react-icons/fi";

import {
    AiOutlineCheckCircle,
    AiOutlineDollar,
    AiOutlineFileSearch,
    AiOutlineNotification, AiOutlineRocket,
    AiOutlineUsergroupAdd
} from "react-icons/ai";

import CountUp from "react-countup";

import {
    
    FaHandshake,
   
} from "react-icons/fa";

import { useTranslation } from "react-i18next";


export default function CounterUp({secTitle, secSubtitle, counteritems, counterbottomtitle, counterbottombtn, counterbottombtnurl}) {

    const { t, i18n } = useTranslation()

    const CounterUp= {
            secTitle: t('countSectTitle'),
            secSubtitle: t('countSectSubTitle'),
            items: [
                {
                    icon: <AiOutlineNotification className="icon" />,
                    number: <CountUp start={0}
                        end={1300}
                        duration={8}
                        separator=" "
                        decimal=","
                        prefix=" "
                        suffix=" "
                    />,
                    numsuffix: '+',
                    title: t('countSectItemTitle1')
                },
                {
                    icon: <AiOutlineUsergroupAdd className="icon" />,
                    number: <CountUp start={0}
                        end={258}
                        duration={8}
                        separator=" "
                        decimal=","
                        prefix=" "
                        suffix=" "
                    />,
                    numsuffix: '+',
                    title: t('countSectItemTitle2')
                },
                {
                    icon: <FaHandshake className="icon" />,
                    number: <CountUp start={0}
                        end={750}
                        duration={8}
                        separator=" "
                        decimal=","
                        prefix=" "
                        suffix=" "
                    />,
                    numsuffix: '+',
                    title: t('countSectItemTitle3')
                },
                {
                    icon: <AiOutlineCheckCircle className="icon" />,
                    number: <CountUp start={0}
                        end={30}
                        duration={8}
                        separator=" "
                        decimal=","
                        prefix=" "
                        suffix=" "
                    />,
                    numsuffix: '+',
                    title: t('countSectItemTitle4')
                }
            ],
            counterbottomtitle: t('countSectDescript'),
            counterbottombtn: t('countSectButton'),
            counterbottombtnurl: '/',
        }

    return (
        <>
            <section className="counterup-wrapper text-center">
                <div className="container"> 
                <div className="sec-heading">
                <p className="sec__meta">{CounterUp && CounterUp.secSubtitle}</p>
                <h2 className="sec__title">
                    {CounterUp && CounterUp.secTitle}
                </h2>
            </div>
                   
                    <div className="counterup-grid-wrap">

                        {CounterUp && CounterUp.items.map((item, index) => {
                            return (
                                <div className="counterup-items" key={index}>
                                    <div className="counterup-iconbox">
                                        {item.icon}
                                    </div>
                                    <h1 className="counterup-number">{item.number}{item.numsuffix}</h1>
                                    <p className="counterup-title">{item.title}</p>
                                </div>
                            )
                        })}

                    </div>
                    {CounterUp.counterbottomtitle || CounterUp.counterbottombtn ?
                        <div className="counterup-bottom-wrap">
                            {CounterUp.counterbottomtitle?
                                <p>{CounterUp.counterbottomtitle}</p> : ''
                            }
                            {CounterUp.counterbottombtn?
                                <Link to="/contact" className="theme-button">{CounterUp.counterbottombtn} <FiChevronRight className="icon" /></Link>
                                : ''
                            }
                        </div> : ''
                    }
                </div>
            </section>
        </>
    )
}
