import React, { Component, lazy, Suspense } from 'react';
//import { CardImg, Modal, ModalBody, ModalHeader, Input, Button, Label, Form, Alert } from 'reactstrap';
import { services } from '../../services/services';


//import { useTranslation } from "react-i18next";
import { FaDollarSign, FaHandHoldingUsd } from "react-icons/fa";
import SweetAlert from 'sweetalert2';
//import { Row, Col } from 'reactstrap';
import Media from 'react-media';

import { Alert, Button, Modal, Card, Row, Col, CardText, CardTitle, CardSubtitle, Form, FormGroup, Label, Input, ModalHeader, ModalBody, ModalFooter, CardBody, CardFooter } from 'reactstrap';

import { withTranslation } from 'react-i18next';

import Snackbar from '@material-ui/core/Snackbar';

//import Select from 'react-select'
import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';

import TextField from '@material-ui/core/TextField';

import ProgressBar from './ProgressBar';
import ProgressBar2 from './ProgressBar2';
import ProgressBar3 from './ProgressBar3';

//import SweetAlert from 'sweetalert2'


import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const currentUserLanguage = JSON.parse(localStorage.getItem('languageValue'))


//const currentUser = authenticationService.currentUserValue;

class Dash extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      languageValue: currentUserLanguage,
      userInfo: '',
      page: 'DASH',
      password: '',
      newPassword: '',

      isAddModalVisible: false,

      password: '',
      newPassword: '',
      transferts: [],
      isAddModalVisible: false,
      pays: '',
      Banque: '',
      intitule: '',
      compte: '',
      montant: '',
      iban: '',

      successMsg: '',
      warningMsg: '',
      infoMsg: '',
      alertWarningMsg: false,
      alertWarningMsg: false,
      alertSuccessMsg: false,

      alertErrMsg: false,
      errMsg: '',
      alertInfoMsg: false,
      infoMsg: '',


      open: false,
      openOperation: false,
      ProgressVisible: false,
      ProgressVisible2: false,
      ProgressVisible3: false,
      echecMessage: false,
      echecMessage2: false,
      echecMessage3: false,
      statutCode: false,
      errorCodeTrasfertInput: false,
      codeTransfert1: '',
      codeTransfert2: '',
      codeTransfert3: '',
      soldeTotal: 0,
      openDetailModal: false,



    };
  }


  handleCloseDialog = () => {
    this.setState({
      open: false,
      pays: '',
      Banque: '',
      intitule: '',
      compte: '',
      montant: '',
      iban: '',

      successMsg: '',
      warningMsg: '',
      infoMsg: '',
      alertWarningMsg: false,
      alertWarningMsg: false,
      alertSuccessMsg: false,

      alertErrMsg: false,
      errMsg: '',
      alertInfoMsg: false,
      infoMsg: '',


      open: false,
      openOperation: false,
      ProgressVisible: false,
      ProgressVisible2: false,
      ProgressVisible3: false,
      echecMessage: false,
      echecMessage2: false,
      echecMessage3: false,
      statutCode: false,
      errorCodeTrasfertInput: false,
      codeTransfert1: '',
      codeTransfert2: '',
      codeTransfert3: '',
      openDetailModal: false,
    })
  }

  selectCountry(val) {
    //console.log('VAL', val)
    this.setState({ pays: val });
  }

  handleChange = (e) => {
    //console.log('Mise a jour state', this.state)
    this.setState({ [e.target.name]: e.target.value })
  }

  changeHandler = value => {
    this.setState({ pays: value })
  }

  handleAddOpen = () => {
    this.setState({ isAddModalVisible: !this.state.isAddModalVisible })
  };

  updatePassword = () => {
    const { password, newPassword } = this.state
    let req = {
      password,
      newPassword
    }
    services.updatePassword(req)
      .then(pass => {
        SweetAlert.fire(
          { title: this.props.t('userSuccessPasswordTilte'), text: this.props.t('userSuccessPassword'), icon: "success" }
        )
        this.setState({
          password: '',
          newPassword: '',
        })
        //setPassword('')
        //setNewPassword('')
      })
      .catch(err => {
        SweetAlert.fire(
          'Oppss.. !',
          this.props.t('errorPasswordUpdate'),
          'error'
        )

      })
  }


  componentDidMount() {

    //const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    ////console.log('currentUser', currentUser);
    // setUserInfo(currentUser);

    //if (currentUser === null || currentUser === undefined || currentUser === '' || currentUser === {}) {
    services.getCurrentUser()
      .then(userInfo => {
        //console.log('CONTAIN Information complete utilisateur', userInfo)
        this.setState({ userInfo: userInfo, soldeTotal: userInfo.solde })

      })

  }

  addTransfert = () => {
    const {
      montant,
      compte,
      intitule,
      iban,
      Banque,
      pays,
      soldeTotal


    } = this.state



    /*if (compte === '') {
      this.setState({ alertWarningMsg: true, warningMsg: this.props.t('alertAccountNumber'), })
      setTimeout(() => this.setState({ warningMsg: '', alertWarningMsg: false }), 6000)
      return;
    }*/

    if (pays === '') {
      this.setState({ alertWarningMsg: true, warningMsg: this.props.t('alertCountrySelect'), })
      setTimeout(() => this.setState({ warningMsg: '', alertWarningMsg: false }), 6000)
      return;
    }
    if (montant === '') {
      this.setState({ alertWarningMsg: true, warningMsg: this.props.t('alertMontantInfo'), })
      setTimeout(() => this.setState({ warningMsg: '', alertWarningMsg: false }), 6000)
      return;
    }

    if (parseInt(montant) > parseInt(soldeTotal)) {
      this.setState({ alertWarningMsg: true, warningMsg: this.props.t('alertErrMontant') })
      setTimeout(() => this.setState({ warningMsg: '', alertWarningMsg: false }), 6000)
      return;
    }

    let req = {
      montant,
      compte,
      intitule,
      iban,
      Banque,
      pays: pays.toUpperCase(),


    }
    services.addTransfert(req)
      .then(result => {
        //console.log(result)
        this.setState({
          montant: '',
          compte: '',
          intitule: '',
          iban: '',
          Banque: '',
          pays: '',
          rib: '',
          bic: '',
          city: '',
          phone: '',
          gender: '',
          banque: '',
          profession: '',
          address: '',
          intitule: '',
          pays: '',
          Banque: '',
          iban: '',
          intitule: '',
          compte: '',
          montant: 0,
          country: '',
          intitule: '',
          statutClient: '',
          commune: '',
          // role: '',
          roleSlug: '',
          //modalAddClient: !this.state.modalAddClient,
          open: !this.state.open,
          ProgressVisible: !this.state.ProgressVisible,
          //alertSuccessMsg: true,
          //successMsg: <IntlMessages id="successMsg.transfert" />

        })

        setTimeout(() => {
          this.setState({ echecMessage: true, ProgressVisible: !this.state.ProgressVisible, successMsg: '', alertSuccessMsg: false });
        }, 10000)
        services.getTransfert()
          .then(transferts => {
            this.setState({ transferts })
          })

      })

      .catch(() => {
        this.setState({ errMsg: this.props.t('errMsg.transfert'), alertErrMsg: true })
        setTimeout(() => {
          this.setState({ errMsg: '', alertErrMsg: false });
        }, 6000)
      });

  }

  verify1 = () => {
    const {
      codeTransfert1,
    } = this.state



    if (codeTransfert1 === '') {
      this.setState({ alertWarningMsg: true, warningMsg: this.props.t('warning.TranfertCode1'), })
      setTimeout(() => this.setState({ warningMsg: '', alertWarningMsg: false }), 6000)
      return;
    }

    let req = {
      codeTransfert1,

    }
    services.verify1(req)
      .then(result => {
        //console.log(result)
        this.setState({
          codeTransfert1: '',

          //alertSuccessMsg: true,
          echecMessage: false,
          ProgressVisible2: true,
          //successMsg: this.props.t('successMsg.transfert')

        })

        setTimeout(() => {
          this.setState({ echecMessage2: true, successMsg: '', alertSuccessMsg: false, ProgressVisible2: false });
        }, 10000)
        /*services.getTransfert()
            .then(transferts => {
                this.setState({ transferts })
            })*/

      })

      .catch(() => {
        this.setState({ errorCodeTrasfertInput: true, errMsg: this.props.t('errMsg.transfert'), echecMessage: true })
        setTimeout(() => {
          this.setState({ errorCodeTrasfertInput: false, errMsg: '' });
        }, 6000)
      });

  }

  verify2 = () => {
    const {
      codeTransfert2,
    } = this.state



    if (codeTransfert2 === '') {
      this.setState({ alertWarningMsg: true, warningMsg: this.props.t('warning.TranfertCode2'), })
      setTimeout(() => this.setState({ warningMsg: '', alertWarningMsg: false }), 6000)
      return;
    }

    let req = {
      codeTransfert2,

    }
    services.verify2(req)
      .then(result => {
        //console.log(result)
        this.setState({
          codeTransfert2: '',
          ProgressVisible2: false,
          //alertSuccessMsg: true,
          echecMessage: false,
          echecMessage2: false,
          ProgressVisible3: true,
          //successMsg: <IntlMessages id="successMsg.transfert" />

        })

        setTimeout(() => {
          this.setState({ echecMessage3: true, successMsg: '', alertSuccessMsg: false, ProgressVisible3: false });
        }, 11000)
        /*services.getTransfert()
            .then(transferts => {
                this.setState({ transferts })
            })*/

      })

      .catch(() => {
        this.setState({ errorCodeTrasfertInput: true, errMsg: this.props.t('errMsg.transfert'), echecMessage2: true })
        setTimeout(() => {
          this.setState({ errorCodeTrasfertInput: false, errMsg: '' });
        }, 6000)
      });

  }

  verify3 = () => {
    const {
      codeTransfert3,
    } = this.state


    if (codeTransfert3 === '') {
      this.setState({ alertWarningMsg: true, warningMsg: this.props.t('warning.TranfertCode3'), })
      setTimeout(() => this.setState({ warningMsg: '', alertWarningMsg: false }), 6000)
      return;
    }

    let req = {
      codeTransfert3,

    }

    services.verify3(req)
      .then(result => {
        //console.log(result)
        this.setState({

          echecMessage: false,
          echecMessage2: false,

          echecMessage3: false,
          codeTransfert3: '',
          ProgressVisible3: true,

        })
        services.getTransfert()
          .then(transferts => {
            this.setState({ transferts })
          })
        setTimeout(() => {
          this.setState({
            codeTransfert3: '',

            alertSuccessMsg: true,
            echecMessage3: false,
            ProgressVisible3: true,
            successMsg: this.props.t('successMsg.transfert'),
            open: false,
            isAddModalVisible: false,
            ProgressVisible3: false,
          })


        }, 10000)

        setTimeout(() => {
          this.setState({ echecMessage3: false, successMsg: '', alertSuccessMsg: false, });
        }, 15000)
        /*services.getTransfert()
            .then(transferts => {
                this.setState({ transferts })
            })*/

      })

      .catch(() => {
        this.setState({ errorCodeTrasfertInput: true, errMsg: this.props.t('errMsg.transfert'), echecMessage3: true })
        setTimeout(() => {
          this.setState({ errorCodeTrasfertInput: false, errMsg: '' });
        }, 6000)
      });

  }

  render() {
    const {
      page,
      userInfo,
      password,
      newPassword,

      isAddModalVisible,

      //    page,
      //userInfo,
      // password,
      //newPassword,
      montant,
      compte,
      intitule,
      iban,
      Banque,
      pays,
      transferts,
      //isAddModalVisible,

      successMsg,
      warningMsg,
      infoMsg,
      alertWarningMsg,

      alertSuccessMsg,
      alertErrMsg,
      errMsg,
      alertInfoMsg,

      open,
      openOperation,
      ProgressVisible,
      ProgressVisible2,
      ProgressVisible3,
      echecMessage,
      echecMessage2,
      echecMessage3,
      statutCode,
      errorCodeTrasfertInput,
      codeTransfert1,
      codeTransfert2,
      codeTransfert3,
      openDetailModal,

      statut,

    } = this.state

    const { t } = this.props;



    return (

      <section>
        <Media query="(min-width: 600px)" render={() =>
        (
          <div >

            {/* LEFT-CONTAINER */}


            <div className="left-container-contain container-contain">
            {userInfo.role !== 'ADMIN' &&
              <div className="weather block clear"> {/* WEATHER (MIDDLE-CONTAINER) <FaDollarSign /> */}
                <h2 className=" h2-contain titular"><span className="span-contain" />  {t('userSolde')}</h2>
                <div className="current-day">
                  <p className="p-contain mt-3" style={{ color: '#fff' }} className="current-day-date"></p>
                  <h3 style={{ color: '#fff' }} /* className="p-contain" className="current-day-temperature"*/>{userInfo.solde}{userInfo.devise}</h3>
                </div>

              </div>
        }


              <div className="account block"> {/* ACCOUNT (RIGHT-CONTAINER) */}
                <h2 className="h2-contain titular">{t('userChangePassword')}</h2>

                <div className="input-container-contain">
                  <input value={password} name="password" onChange={this.handleChange} type="text-contain" placeholder={t('userOldPassword')} className="password text-input" />
                  <div className="input-icon envelope-icon-acount"><span className="span-contain" className="fontawesome-lock scnd-font-color" /></div>
                </div>
                <div className="input-container-contain">
                  <input value={newPassword} name="newPassword" onChange={this.handleChange} type="text-contain" placeholder={t('userNewPassword')} className="password text-input" />
                  <div className="input-icon password-icon"><span className="span-contain" className="fontawesome-lock scnd-font-color" /></div>
                </div>
                <a className="a-contain" style={{ cursor: 'pointer', color: '#fff' }} className="sign-in button" onClick={this.updatePassword}>{t('userEdit')}</a>


              </div>


              <div /*className="line-chart-block block clear"*/> {/* LINE CHART BLOCK (LEFT-CONTAINER) */}
                <div /*className="line-chart"*/>

                  {/* END LINE-CHART by @kseso https://codepen.io/Kseso/pen/phiyL */}
                </div>

              </div>


            </div>

            {/* MIDDLE-CONTAINER */}


            <div className="middle-container-contain container-contain">
              <div className="profile block"> {/* PROFILE (MIDDLE-CONTAINER) */}
                <a className="a-contain" className="add-button" href="#28"><span className="span-contain" className="icon entypo-plus scnd-font-color" /></a>
                <div className="profile-picture big-profile-picture clear">
                  <img width="150px" alt="Anne Hathaway picture" src="https://bootdey.com/img/Content/avatar/avatar7.png" />
                </div>
                <h1 className="h1-contain user-name">{userInfo.firstName} {userInfo.lastName}</h1>
                <div className="profile-description">
                  <p className="p-contain" className="scnd-font-color">{userInfo.telephone}</p>
                  <p className="p-contain" className="scnd-font-color">{userInfo.ville}/{userInfo.pays}</p>

                </div>
                <ul className="ul-contain profile-options horizontal-list">
                  <li className="li-contain" title={t('userTransfertCours')}><a className="a-contain" className="comments" href="#"><p className="p-contain"><span className="span-contain" className="icon fontawesome-comment-alt scnd-font-color" />00</p></a></li><p className="p-contain" />
                  <li className="li-contain" title={t('userTransfertValide')}><a className="a-contain" className="views" href="#"><p className="p-contain"><span className="span-contain" className="icon fontawesome-eye-open scnd-font-color" />00</p></a></li><p className="p-contain" />
                  <li className="li-contain" title={t('userTransfertEchec')}><a className="a-contain" className="likes" href="#"><p className="p-contain"><span className="span-contain" className="icon fontawesome-heart-empty scnd-font-color" />00</p></a></li><p className="p-contain" />

                </ul>
              </div>


            </div>
            {/* RIGHT-CONTAINER */}

          {userInfo.role !== 'ADMIN' &&
            <div className="right-container-contain container-contain" >
              <div className="join-newsletter block" style={{ textAlign: 'left', justifyContent: 'flex-start' }}> {/* JOIN NEWSLETTER (RIGHT-CONTAINER) */}
                <h2 className="h2-contain titular" style={{ textDecoration: 'underline' }}>{t('userInfoAccount')}</h2>
                <a className="a-contain ml-2" style={{ color: '#fff' }} className="subscribe" href="#"><span /*style={{ textDecoration: 'underline' }}*/>{t('formIban')}</span>: {userInfo.iban}</a><br />
                <a className="a-contain ml-2" style={{ color: '#fff' }} className="subscribe" href="#"><span /*style={{ textDecoration: 'underline' }}*/>{t('formBankCod')}:</span> {userInfo.codeBanque}</a><br />

                <a className="a-contain ml-2" style={{ color: '#fff' }} className="subscribe" href="#"><span /*style={{ textDecoration: 'underline' }}*/>{t('formCodGuich')}:</span> {userInfo.codeGuichet}</a><br />

                <a className="a-contain ml-2" style={{ color: '#fff' }} className="subscribe" href="#"><span /*style={{ textDecoration: 'underline' }}*/>{t('formCleRib')}:</span> {userInfo.cleRib}</a><br />

                <a className="a-contain ml-2" style={{ color: '#fff' }} className="subscribe" href="#"><span /*style={{ textDecoration: 'underline' }}*/>{t('formAccountNumber')}:</span> {userInfo.compteNumero}</a><br />

                <a className="a-contain ml-2" style={{ color: '#fff' }} className="subscribe" href="#"><span /*style={{ textDecoration: 'underline' }}*/>{t('formBic')}:</span> {userInfo.bic}</a><br />


              </div>



              <div className="donut-chart-block block" style={{ cursor: 'pointer' }}> {/* DONUT CHART BLOCK (LEFT-CONTAINER) */}

                <h2 className="h2-contain titular"></h2>
                <a className="a-contain" style={{ color: '#fff' }} className="subscribe button" onClick={this.handleAddOpen}>{t('userDoneTransfert')}</a>


              </div>

            </div> } {/* end right-container */}

          </div>
        )}
        />

        <Media query="(max-width: 599px)" render={() =>
        (
          <div>
            <Row>
              <Col md={6}>
                {userInfo.role !== 'ADMIN' &&
                <div className="right-container-contain container-contain">
                  <div className="join-newsletter block"> {/* JOIN NEWSLETTER (RIGHT-CONTAINER) */}
                    <h2 className="h2-contain titular">{t('userInfoAccount')}</h2>
                    <a className="a-contain ml-2" style={{ color: '#fff' }} className="subscribe" href="#"><span style={{ textDecoration: 'underline' }}>{t('formIban')}</span>: {userInfo.iban}</a><br />
                    <a className="a-contain ml-2" style={{ color: '#fff' }} className="subscribe" href="#"><span style={{ textDecoration: 'underline' }}>{t('formBankCod')}:</span> {userInfo.codeBanque}</a><br />

                    <a className="a-contain ml-2" style={{ color: '#fff' }} className="subscribe" href="#"><span style={{ textDecoration: 'underline' }}>{t('formCodGuich')}:</span> {userInfo.codeGuichet}</a><br />

                    <a className="a-contain ml-2" style={{ color: '#fff' }} className="subscribe" href="#"><span style={{ textDecoration: 'underline' }}>{t('formCleRib')}:</span> {userInfo.cleRib}</a><br />

                    <a className="a-contain ml-2" style={{ color: '#fff' }} className="subscribe" href="#"><span style={{ textDecoration: 'underline' }}>{t('formAccountNumber')}:</span> {userInfo.compteNumero}</a><br />

                    <a className="a-contain ml-2" style={{ color: '#fff' }} className="subscribe" href="#"><span style={{ textDecoration: 'underline' }}>{t('formBic')}:</span> {userInfo.bic}</a><br />

                  </div>

                </div>} {/* end right-container */}

              </Col>

              <Col md={6}>
                <div className="left-container-contain container-contain">
                  {userInfo.role !== 'ADMIN' &&
                  <div className="weather block clear"> {/* WEATHER (MIDDLE-CONTAINER) */}
                    <h2 className=" h2-contain titular"><span className="span-contain" />  {t('userSolde')}</h2>
                    <div className="current-day">
                      <p className="p-contain mt-3" style={{ color: '#fff' }} className="current-day-date"></p>
                      <p className="p-contain" style={{ color: '#fff' }} className="current-day-temperature">{userInfo.solde}{userInfo.devise}</p>
                    </div>

                  </div>}





                  <div /*className="line-chart-block block clear"*/> {/* LINE CHART BLOCK (LEFT-CONTAINER) */}
                    <div /*className="line-chart"*/>

                      {/* END LINE-CHART by @kseso https://codepen.io/Kseso/pen/phiyL */}
                    </div>

                  </div>

                </div>

              </Col>
            </Row>

            <Row>
              <Col md={6}>
                {userInfo.role !== 'ADMIN' &&
                <div className="right-container-contain container-contain">

                  <div className="donut-chart-block block" style={{ cursor: 'pointer' }}> {/* DONUT CHART BLOCK (LEFT-CONTAINER) */}

                    <h2 className="h2-contain titular"></h2>
                    <a className="a-contain" style={{ color: '#fff' }} className="subscribe button" onClick={this.handleAddOpen}>{t('userDoneTransfert')}</a>


                  </div>

                </div>} {/* end right-container */}

              </Col>

              <Col md={6}>
                <div className="middle-container-contain container-contain">
                  <div className="profile block"> {/* PROFILE (MIDDLE-CONTAINER) */}
                    <a className="a-contain" className="add-button" href="#28"><span className="span-contain" className="icon entypo-plus scnd-font-color" /></a>
                    <div className="profile-picture big-profile-picture clear">
                      <img width="150px" alt="Anne Hathaway picture" src="https://bootdey.com/img/Content/avatar/avatar7.png" />
                    </div>
                    <h1 className="h1-contain user-name">{userInfo.firstName} {userInfo.lastName}</h1>
                    <div className="profile-description">
                      <p className="p-contain" className="scnd-font-color">{userInfo.telephone}</p>
                      <p className="p-contain" className="scnd-font-color">{userInfo.ville}/{userInfo.pays}</p>

                    </div>
                    <ul className="ul-contain profile-options horizontal-list">
                      <li className="li-contain" title={t('userTransfertCours')}><a className="a-contain" className="comments" href="#"><p className="p-contain"><span className="span-contain" className="icon fontawesome-comment-alt scnd-font-color" />25</p></a></li><p className="p-contain" />
                      <li className="li-contain" title={t('userTransfertValide')}><a className="a-contain" className="views" href="#"><p className="p-contain"><span className="span-contain" className="icon fontawesome-eye-open scnd-font-color" />02</p></a></li><p className="p-contain" />
                      <li className="li-contain" title={t('userTransfertEchec')}><a className="a-contain" className="likes" href="#"><p className="p-contain"><span className="span-contain" className="icon fontawesome-heart-empty scnd-font-color" />49</p></a></li><p className="p-contain" />

                    </ul>
                  </div>

                </div>

              </Col>
            </Row>

            <Row>


              <Col md={6}>
                <div className="left-container-contain container-contain">
                  <div className="account block"> {/* ACCOUNT (RIGHT-CONTAINER) */}
                    <h2 className="h2-contain titular">{t('userChangePassword')}</h2>

                    <div className="input-container-contain">
                      <input value={password} name="password" onChange={this.handleChange} type="text-contain" placeholder={t('userOldPassword')} className="password text-input" />
                      <div className="input-icon envelope-icon-acount"><span className="span-contain" className="fontawesome-lock scnd-font-color" /></div>
                    </div>
                    <div className="input-container-contain">
                      <input value={newPassword} name="newPassword" onChange={this.handleChange} type="text-contain" placeholder={t('userNewPassword')} className="password text-input" />
                      <div className="input-icon password-icon"><span className="span-contain" className="fontawesome-lock scnd-font-color" /></div>
                    </div>
                    <a className="a-contain" style={{ cursor: 'pointer', color: '#fff' }} className="sign-in button" onClick={this.updatePassword}>{t('userEdit')}</a>

                  </div>

                </div>

              </Col>
            </Row>

          </div>
        )}
        />


        <Modal className="modal-dialog modal-lg" centered={true} scrollable={true} isOpen={isAddModalVisible}>

          <ModalHeader toggle={this.handleAddOpen}>

            <Row>
              <Col md={12}>
                <h5 style={{ color: "#1D75BB" }} >{t('modalTransfertTitle')} </h5>
              </Col>
            </Row>
          </ModalHeader>

          <ModalBody /*style={{ maxHeight: '800px', maxWidth:'800px', overflow: 'auto' }}*/ toggle={this.handleAddOpen}>

            <Form>


              <Row form>

                <Col md={12}>
                  <FormGroup>
                    <Label for="codFour">{t('formCountry')}</Label>
                    <CountryDropdown
                      value={pays}
                      onChange={(val) => this.selectCountry(val)}
                      labelType="full"
                      style={{ width: '100%' }}
                      defaultOptionLabel={t('sltCountrPay')}
                      name={t('sltCountrPay')}

                    />
                  </FormGroup>
                </Col>


              </Row>

              <Row form>

                <Col md={6}>
                  <FormGroup>
                    <Label for="Banque">{t('modalBankName')}</Label>
                    <Input type="text" name="Banque" value={Banque} onChange={this.handleChange} class="form-control" placeholder={t('modalBankName')} />
                  </FormGroup>
                </Col>

                <Col md={6}>
                  <FormGroup>
                    <Label for="iban">{t('modalIban')}</Label>
                    <Input type="text" name="iban" value={iban} onChange={this.handleChange} class="form-control" placeholder={t('modalIban')} />
                  </FormGroup>
                </Col>


              </Row>

              <Row form>

                <Col md={6}>
                  <FormGroup>
                    <Label for="intitule">{t('modalAccountTitulair')}</Label>
                    <Input type="text" name="intitule" value={intitule} onChange={this.handleChange} class="form-control" placeholder={t('modalAccountTitulair')} />
                  </FormGroup>
                </Col>

                {/* <Col md={6}>
                                    <FormGroup>
                                        <Label for="compte">{t('modalAccountNumber')}</Label>
                                        <Input type="text" name="compte" value={compte} onChange={this.handleChange} class="form-control" placeholder={t('modalAccountNumber')} />
                                    </FormGroup>
                </Col>*/}
                <Col md={6}>
                  <FormGroup>
                    <Label for="montant">{t('modalPrice')}</Label>
                    <Input type="number" name="montant" value={montant} onChange={this.handleChange} class="form-control" placeholder={t('modalPrice')} />
                  </FormGroup>
                </Col>

              </Row>

            </Form>



          </ModalBody>
          <ModalFooter>
            <div className="text-right">
              {//commande && commande.etatCmde === "EN COURS" &&
                <Button color="primary" onClick={this.addTransfert}>{t('modalTransfertButton')}</Button>
              }
              <Button className="ml-2" color="secondary" onClick={this.handleAddOpen}>{t('modalCloseButton')}</Button>
            </div>
          </ModalFooter>
        </Modal>

        <Dialog
          open={open}
          onClose={this.handleCloseDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title" style={{ color: 'blue' }}>{t('dash.modal.validationOperation')}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">

              {/* <p style={{ display: 'flex', color: 'red' }}>
                NB: Veuillez contactez notre compte Whatsapp +33 7 55 82 79 19 pour finaliser votre processus de Retrait.  Réfférence: REF-{userInfo.username}
              </p>
            */}

              <Row>
                <Col>
                  {ProgressVisible === true &&
                    <Row className='mt-2 mb-2'>
                      <Col md={12}>
                        <ProgressBar />

                      </Col>
                    </Row>
                  }
                  {ProgressVisible2 === true &&
                    <Row className='mt-2 mb-2'>
                      <Col md={12}>
                        <ProgressBar2 />

                      </Col>
                    </Row>
                  }
                  {ProgressVisible3 === true &&
                    <Row className='mt-2 mb-2'>
                      <Col md={12}>
                        <ProgressBar3 />

                      </Col>
                    </Row>
                  }
                </Col>
              </Row>

              {echecMessage === true &&
                <form>
                  <Row className='mb-2'>
                    <Col md={12}>

                      <h6 style={{ display: 'flex', color: 'red' }}>{t('dash.modal.errorMsgTransfert')}</h6>

                    </Col>

                    <Col md={12}>
                      {
                        (errorCodeTrasfertInput === true) &&
                        <Alert color="danger">
                          {t('text.errorCodeTrasfertInput')}

                        </Alert>
                      }
                    </Col>

                    <Col md={12}>
                      <TextField
                        label={t('dash.modal.tranfertCode')}
                        id="codeTransfert1"
                        name="codeTransfert1"
                        value={codeTransfert1}
                        onChange={this.handleChange}
                        className="w-100 mt-2 mb-2"

                      />
                    </Col>
                  </Row>

                  <Row className='mb-2'>
                    <Col>
                      {echecMessage === true &&
                        <h6>{t('dash.modal.errorMsgTransfertNb')}</h6>
                      }
                    </Col>

                  </Row>

                  <Row>
                    <Col md={12}>
                      <ModalFooter>
                        <Button color="primary" variant="contained" onClick={this.verify1}>{t('table.boutton.enregistrer')}</Button>
                        <Button color="secondary" variant="contained" onClick={this.handleCloseDialog}>{t('table.boutton.annuler')}</Button>

                      </ModalFooter>
                    </Col>
                  </Row>

                </form>
              }

              {echecMessage2 === true &&
                <form>
                  <Row className='mb-2'>
                    <Col md={12}>

                      <h6 style={{ display: 'flex', color: 'red' }}>{t('dash.modal.errorMsgTransfert')}</h6>

                    </Col>

                    <Col md={12}>
                      {
                        (errorCodeTrasfertInput === true) &&
                        <Alert color="danger">
                          {t('text.errorCodeTrasfertInput')}

                        </Alert>
                      }
                    </Col>

                    <Col md={12}>
                      <TextField
                        label={t('dash.modal.tranfertCode2')}
                        id="codeTransfert2"
                        name="codeTransfert2"
                        value={codeTransfert2}
                        onChange={this.handleChange}
                        className="w-100 mt-2 mb-2"

                      />
                    </Col>

                  </Row>


                  <Row>
                    <Col>
                      {echecMessage2 === true &&
                        <h6>{t('dash.modal.errorMsgTransfertNb')}</h6>
                      }
                    </Col>

                  </Row>

                  <Row>
                    <Col md={12}>
                      <ModalFooter>
                        <Button color="primary" variant="contained" onClick={this.verify2}>{t('table.boutton.enregistrer')}</Button>
                        <Button color="secondary" variant="contained" onClick={this.handleCloseDialog}>{t('table.boutton.annuler')}</Button>

                      </ModalFooter>
                    </Col>
                  </Row>
                </form>
              }

              {echecMessage3 === true &&
                <form>
                  <Row>
                    <Col md={12}>

                      <h6 style={{ display: 'flex', color: 'red' }}>{t('dash.modal.errorMsgTransfert')}</h6>

                    </Col>

                    <Col md={12}>
                      {
                        (errorCodeTrasfertInput === true) &&
                        <Alert color="danger">
                          {t('text.errorCodeTrasfertInput')}

                        </Alert>
                      }
                    </Col>

                    <Col md={12}>
                      <TextField
                        label={t('dash.modal.tranfertCode3')}
                        id="codeTransfert3"
                        name="codeTransfert3"
                        value={codeTransfert3}
                        onChange={this.handleChange}
                        className="w-100 mt-2 mb-2"

                      />
                    </Col>
                  </Row>


                  <Row>
                    <Col>
                      {echecMessage3 === true &&
                        <h6>{t('dash.modal.errorMsgTransfertNb')}</h6>
                      }
                    </Col>

                  </Row>

                  <Row>
                    <Col md={12}>
                      <ModalFooter>
                        <Button color="primary" variant="contained" onClick={this.verify3}>{t('table.boutton.enregistrer')}</Button>
                        <Button color="secondary" variant="contained" onClick={this.handleCloseDialog}>{t('table.boutton.annuler')}</Button>

                      </ModalFooter>
                    </Col>
                  </Row>
                </form>
              }



            </DialogContentText>
          </DialogContent>
          <DialogActions>

          </DialogActions>
        </Dialog>

        <Snackbar
          open={alertSuccessMsg}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}

        >
          <Alert severity="success">
            {successMsg}
          </Alert>
        </Snackbar>

        <Snackbar
          open={alertErrMsg}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}

        >
          <Alert severity="error">
            {errMsg}
          </Alert>
        </Snackbar>

        <Snackbar
          open={alertInfoMsg}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <Alert severity="info">
            {infoMsg}
          </Alert>
        </Snackbar>

        <Snackbar
          open={alertWarningMsg}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <Alert severity="warning">
            {warningMsg}
          </Alert>
        </Snackbar>

      </section>


    );
  }
}

export default withTranslation()(Dash);
