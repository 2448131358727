import React  from 'react'
import Navbar from '../components/common/Navbar'
import Breadcrumb from '../components/common/Breadcrumb'
import ContactDetails from '../components/contact/ContactDetails'
import Footer from '../components/other/Footer'

import { useTranslation } from "react-i18next";

export default function ContactPage() {

    const { t, i18n } = useTranslation()

    return (
        <>
            {/* Header */}
            <header className="header-area">
                <Navbar />
            </header>

            {/* Breadcrumb */}
            <Breadcrumb title={t('contactBreadcrumb')} />

            {/* Contact Details */}
            <ContactDetails />

            {/* Footer */}
            <Footer />
        </>
    )
}
